import {
    cancel,
    delay,
    fork,
    put,
    race,
    spawn,
    take,
    takeLatest
} from 'redux-saga/effects';
import {
    LoadOrdersActionAndSaga, LoadUserActionAndSaga, LoginActionAndSaga,
    LogoutActionAndSaga,
    RegisterNewsletterActionAndSaga,
    RegisterUserActionAndSaga,
    ResetPasswordActionAndSaga,
    SubscribeNewsletterActionAndSaga,
    UpdatePasswordActionAndSaga,
} from '../reducer/user/types';
import {PayloadAction} from '@reduxjs/toolkit';
import {
    DISABLE_NEWSLETTER_INACTIVITY,
    disableNewsletterInactivity,
    ENABLE_NEWSLETTER_INACTIVITY,
    EnableNewsletterInactivityPayload,
    LoadNewsBarEntriesActionAndSaga,
    REGISTER_USER_ACTIVITY,
    REGISTER_USER_FOCUS_LOSS,
    SearchStoresActionAndSaga,
    SendContactMessageActionAndSaga,
} from '../reducer/application/types';
import {Cookie, CookieSetOptions} from 'universal-cookie';
import {
    COOKIE_MAX_AGE,
    COOKIE_NEWSLETTER_INACTIVITY_KEY,
} from '../constants/constants';
import {
    setShowInactivityNewsletterDialog
} from '../reducer/application/application';


function* newsletterInactivitySaga(setCookie: (name: string, value: Cookie, options?: CookieSetOptions) => void) {
    let iterate = true;
    while (iterate) {
        const {inactivity, focusLoss} = yield race({
            userActivity: take(REGISTER_USER_ACTIVITY),
            inactivity: delay(Number(process.env.REACT_APP_USER_INACTIVITY_DURATION || '30000')),
            focusLoss: take(REGISTER_USER_FOCUS_LOSS)
        });
        if (inactivity || focusLoss) {
            // Set a cookie
            setCookie(COOKIE_NEWSLETTER_INACTIVITY_KEY, true, {
                maxAge: COOKIE_MAX_AGE
            });
            yield put(setShowInactivityNewsletterDialog(true));
            yield put(disableNewsletterInactivity());
            // Stop the inactivity saga
            iterate = false;
        }
    }
}

function* enableNewsletterInactivitySaga() {
    yield takeLatest(ENABLE_NEWSLETTER_INACTIVITY, function* ({payload}: PayloadAction<EnableNewsletterInactivityPayload>) {
        const {cookie, cookieRegistered, setCookie} = payload;
        // Only start if cookie is not set because user has already seen the dialog in the last month
        if (!Boolean(cookie) && !Boolean(cookieRegistered)) {
            // @ts-ignore
            const inactivityRace = yield fork(newsletterInactivitySaga, setCookie);
            // Wait for disabling
            yield take(DISABLE_NEWSLETTER_INACTIVITY);
            yield cancel(inactivityRace);
        }
    });
}

export default function* userSaga() {
    yield spawn(LoginActionAndSaga.saga!);
    yield spawn(LoadUserActionAndSaga.saga!);
    yield spawn(LogoutActionAndSaga.saga!);
    yield spawn(RegisterUserActionAndSaga.saga!);
    yield spawn(ResetPasswordActionAndSaga.saga!);
    yield spawn(UpdatePasswordActionAndSaga.saga!);
    yield spawn(LoadOrdersActionAndSaga.saga!);
    yield spawn(RegisterNewsletterActionAndSaga.saga!);
    yield spawn(SubscribeNewsletterActionAndSaga.saga!);
    yield spawn(enableNewsletterInactivitySaga);
    yield spawn(SendContactMessageActionAndSaga.saga!);
    yield spawn(LoadNewsBarEntriesActionAndSaga.saga!);
    yield spawn(SearchStoresActionAndSaga.saga!);
}