import React, {Suspense, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSnackbar} from 'notistack';
import {AppState} from '../../../types/types';
import {SnackbarVariant} from '../../../reducer/application/types';
import {makeStyles} from 'tss-react/mui';
import {dismissApplicationMessage, removeApplicationMessage} from '../../../reducer/application/application';
const NotificationAction = React.lazy(() => import('./NotificationAction'));

let openNotifications: string[] = [];

const useStyles = makeStyles()((theme) => ({
    closeButton: {
        color: theme.palette.primary.contrastText
    },
    errorButton: {
        color: '#F91C3D'
    },
    successButton: {
        color: theme.palette.primary.dark
    },
    content: {
        backgroundColor: 'white !important'
    },
    error: {
        color: '#F91C3D !important'
    },
    success: {
        color: `${theme.palette.primary.dark} !important`
    }
}));

const Notifier: React.FC = () => {
    const {classes, cx} = useStyles();
    const dispatch = useDispatch();
    const notifications = useSelector((state: AppState) => state.application.messages);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const storeNotification = useCallback((id: string) => {
        openNotifications = [...openNotifications, id];
    }, []);

    const removeDisplayed = useCallback((id: string) => {
        openNotifications = [...openNotifications.filter(it => id !== it)];
    }, []);

    const closeSnackbarItem = useCallback((key: string) => {
        // remove this snackbar from redux store
        dispatch(dismissApplicationMessage(key));
        removeDisplayed(key);
    }, [dispatch, removeDisplayed]);

    useEffect(() => {
        notifications.forEach(it => {
            if (it.dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(it.key);
                dispatch(removeApplicationMessage(it.key));
                return;
            }
            if (openNotifications.includes(it.key)) {
                return;
            }
            enqueueSnackbar(<span dangerouslySetInnerHTML={{__html: it.text}}/>, {
                key: it.key,
                variant: it.variant,
                autoHideDuration: it.duration || 3000,
                className: cx(classes.content, {
                    [classes.error]: it.variant === SnackbarVariant.ERROR,
                    [classes.success]: it.variant === SnackbarVariant.SUCCESS,
                }),
                onExited: (event, key) => {
                    closeSnackbarItem((key as string));
                },
                action: () => (
                    <Suspense>
                        <NotificationAction variant={it.variant} closeSnackbarItem={() => closeSnackbarItem(it.key)}/>
                    </Suspense>
                )
            });
            storeNotification(it.key);
        });
    }, [notifications, dispatch, enqueueSnackbar, closeSnackbar, closeSnackbarItem, classes, cx, storeNotification]);

    return null;

}

export default Notifier;
