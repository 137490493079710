import FetchStatus from '../../api/api';
import {Language} from '../../reducer/application/types';
import {PaymentMethod} from '../../reducer/cart/types';
import {Gender, User} from '../../reducer/user/types';


export enum ImageFormat {
    WEBP = 'webp',
    FALLBACK = 'fallback',
    SVG = 'svg',
    VIDEO_MP4 = 'video/mp4',
    VIDEO_WEBM = 'video/webm',
    VIDEO_QUICKTIME = 'video/quicktime'
}

export enum ImageType {
    MODEL = 'model',
    FITTING = 'fitting',
    THUMBNAIL = 'thumbnail',
    ICON = 'icon',
    COLOR_GLOW = 'color-glow',
    COLOR_PATTERN = 'color-pattern',
    PRODUCT = 'product',
    ILLUSTRATION = 'illustration',
    HIGHLIGHT = 'highlight',
    CARE = 'care',
    PRODUCT_HIGHLIGHT = 'product-highlight',
    PRODUCT_BANNER = 'product-banner'
}

export enum Resolution {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    LG = 'lg'
}

export interface ImagePath {
    id: number;
    path: string;
    type: string;
    resolution: Resolution;
    retina: boolean;
}


export interface ProductImageDTO {
    productID: number;
    imageID: number;
    orderNumber: number;
}

export interface ProductColorImageDTO {
    id?: number;
    productColorID: number;
    imageID: number;
    orderNumber: number;
}

export interface Image {
    id: number;
    type: ImageType;
    productID: number;
    colorID?: number;
    paths: ImagePath[];
    ProductImage?: ProductImageDTO;
    ProductColorImage?: ProductColorImageDTO;
}

export interface ProductColor {
    productID: number;
    colorID: number;
    createdTimestamp?: number;
    updatedTimestamp?: number;
    createdBy?: number;
    deletedTimestamp?: number;
    deletedBy?: number;
    images?: Image[];
    orderNumber: number;
}

export interface Color {
    id: number;
    name: string;
    code: string;
    productColor?: ProductColor;
    fetchStatus?: FetchStatus;
    images: Image[];
    orderNumber: number;
}


export interface Size {
    id: number;
    name: string;
}

export interface Stock {
    id: number;
    productID: number;
    colorID: number;
    sizeID: number;
    stock: number
}

export interface Localization {
    id: number;
    language: Language;
    key: string;
    value: string;
}

export enum LocalizationKey {
    TITLE = 'title',
    SUB_TITLE = 'sub_title',
    DETAILS = 'details',
    CARE_INSTRUCTIONS = 'care_instructions',
    DESCRIPTION = 'description'
}

export interface ProductTypeLocalizationDTO {
    id: number;
    language: string;
    key: string;
    value: string;
}

export interface ProductType {
    id: number;
    name: string;
    translations: ProductTypeLocalizationDTO[];
}

export interface Product {
    id: number;
    name: string;
    type: ProductType;
    price: number;
    orderNumber: number;
    images: Image[];
    colors?: Color[];
    sizes: Size[];
    stock: Stock[];
    translations: Localization[];
    fetchStatus?: FetchStatus;
    saveFetchStatus?: FetchStatus;
}

export enum FindYourStyleType {
    SLIM_PETITE = 'SLIM_PETITE',
    SLIM_BUSTY = 'SLIM_BUSTY',
    CURVY_MODEST = 'CURVY_MODEST',
    CURVY_BUSTY = 'CURVY_BUSTY',
}

export enum FindYourStyleOptionType {
    BEST_STYLE = 'BEST_STYLE',
    OTHER_OPTION = 'OTHER_OPTION'
}

export interface FindYourStyle {
    id: number;
    type: FindYourStyleType;
    optionType: FindYourStyleOptionType;
    productID: number;
    colorID: number;
    imageID: number;
    product?: Product;
    color?: Color;
    image?: Image;
    position: number;
}


export enum CountryLocalizationKey {
    NAME = 'NAME'
}

export interface CountryLocalization {
    id: number;
    language: Language;
    key: CountryLocalizationKey;
    value: string;
}

export interface Country {
    id: number;
    name: string;
    defaultCountry: boolean;
    shippingCosts: number;
    shippingCostLimit: number;
    klarnaAvailable: boolean;
    isoCode: string;
    localizations: CountryLocalization[];
    translations: Localization[];
}

export enum DeliverStatus {
    RECEIVED = 'RECEIVED',
    CANCELLED = 'CANCELLED',
    BLOCKED = 'BLOCKED',
    SHIPPED = 'SHIPPED',
    OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
    DELIVERED = 'DELIVERED',
    COMPLETED = 'COMPLETED',
    REVOKED = 'REVOKED'
}

export interface OrderAddress {
    id: number;
    company?: string;
    firstName?: string;
    lastName?: string;
    street: string;
    amendment?: string;
    zipCode: string;
    city: string;
    countryID: number;
    country: Country;
    phone?: string;
}

export interface OrderUser {
    id: number;
    orderID: number;
    gender: Gender;
    firstName?: string;
    lastName: string;
    email: string;
    billingAddressID: number;
    deliveryAddressID: number;
    billingAddress: OrderAddress;
    deliveryAddress: OrderAddress;
}

export interface OrderProduct {
    id: number;
    quantity: number;
    productID: number;
    colorID: number;
    sizeID: number;
    price: number;
    product: Product;
    color: Color;
    size: Size;
}

export interface Order {
    id: number;
    totalSum: number;
    shippingCosts: number;
    deliverStatus: DeliverStatus;
    paymentMethod: PaymentMethod;
    invoiceNumber: string;
    language: Language;
    paid: boolean;
    cancelled: boolean;
    archived: boolean;
    notes: string;
    userID: number;
    user?: User;
    orderUser: OrderUser;
    products: OrderProduct[];
    createdTimestamp: number;
    setAsPaidFetchStatus?: FetchStatus;
    setAsShippedFetchStatus?: FetchStatus;
    setAsCancelledFetchStatus?: FetchStatus;
    editOrderFetchStatus?: FetchStatus;
}

export interface Store {
    id: number;
    name: string;
    phone: string;
    fax: string;
    street: string;
    zipCode: string;
    city: string;
    country: Country;
    latitude: number;
    longitude: number;
    distance?: number;
}

export enum CouponType {
    ABSOLUTE = 'ABSOLUTE',
    RELATIVE = 'RELATIVE',
    SHIPPING_COSTS = 'SHIPPING_COSTS'
}

export interface CouponUsage {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
}

export interface CouponUser {
    id: number;
    name: string;
    usages: number;
}

export interface CouponEmail {
    id: number;
    email: string;
    usages: number;
}

export enum CouponState {
    ACTIVE = 'ACTIVE',
    DEACTIVATED = 'DEACTIVATED',
    USED = 'USED'
}

export interface CreateOrUpdateCoupon {
    id: number;
    code: string;
    type: CouponType;
    value: number;
    validFrom?: number;
    validUntil?: number;
    minOrderQuantity?: number;
    maxNumberOfUsages?: number;
    restrictions?: string;
    countries: number[];
    users: string[];
    emails: string[];
    translations: CouponLocalization[];
}

export enum CouponLocalizationKey {
    LEGAL_TEXT = 'LEGAL_TEXT'
}

export interface CouponLocalization {
    type?: string
    entityID?: number;
    language: Language;
    key: CouponLocalizationKey;
    value: string;
}

export interface Coupon {
    id: number;
    code: string;
    type: CouponType;
    value: number;
    validFrom?: number;
    validUntil?: number;
    minOrderQuantity?: number;
    maxNumberOfUsages?: number;
    usages: number;
    legalText?: string;
    restrictions?: string;
    state: CouponState;
    creator: User;
    couponUsages: CouponUsage[];
    countries: Country[];
    couponUser: CouponUser[];
    couponEmails: CouponEmail[];
    fetchStatus?: FetchStatus;
    deleteFetchStatus?: FetchStatus;
    duplicateFetchStatus?: FetchStatus;
    stateFetchStatus?: FetchStatus;
    translations: CouponLocalization[];
}