import React, {MutableRefObject} from 'react';
import {makeStyles} from 'tss-react/mui';
import {Product} from '../../../../../types/products/types';
import {getProductName} from '../../../../../helper/helper';
import {Link} from 'react-router-dom';
import {Routes} from '../../../../../routes/routes';
import {Typography} from '@mui/material';
import {Language} from '../../../../../reducer/application/types';
import {Dictionary} from '@reduxjs/toolkit';

interface ProductSubNavItemProps {
    product: Product;
    maxWidthRef: MutableRefObject<Dictionary<number>>;
    setMaxWidth: (maxWidth: Dictionary<number>) => void;
    maxWidth: Dictionary<number>;
    language: Language;
    setActiveProduct: (product: Product) => void;
    index: number;
    closeSubNav: () => void;
}

const useStyles = makeStyles<unknown, 'name'>()((theme, _, classes) => ({
    element: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        textDecoration: 'none',
        color: 'inherit',
        [`&:hover .${classes.name}`]: {
            borderBottom: '1px solid #000000'
        }
    },
    name: {
        letterSpacing: 0.5,
        fontSize: 15,
        lineHeight: '19px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        borderBottom: '1px solid white',
        paddingBottom: theme.spacing(1),
        textTransform: 'uppercase'
    },
}));

const ProductSubNavItem: React.FC<ProductSubNavItemProps> = ({
                                                                 product, setMaxWidth, maxWidthRef,
                                                                 maxWidth, language, setActiveProduct,
                                                                 index, closeSubNav
                                                             }) => {

    const {classes} = useStyles({});

    const productName = getProductName(product.translations, language);
    return (
        <Link to={Routes.Products.Detail(product.type.name, product.id, productName)}
              key={`product-sub-nav-product-${product.id}`}
              className={classes.element} onClick={closeSubNav}
              onMouseEnter={() => setActiveProduct(product)} ref={el => {
            if (el && maxWidthRef.current[el.clientWidth] === undefined) {
                setMaxWidth({
                    ...maxWidth,
                    [index]: Math.max(el.clientWidth, maxWidth[index] || 0)
                });
                maxWidthRef.current[el.clientWidth] = el.clientWidth;
            }
        }}>
            <div>
                <Typography variant={'body1'} className={classes.name}>
                    {productName}
                </Typography>
            </div>
        </Link>
    );
}

export default ProductSubNavItem;