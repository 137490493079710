import React, {FC, Suspense} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {CssBaseline, StyledEngineProvider} from '@mui/material';
import {Helmet} from 'react-helmet';
import appleTouchIcon from '../../images/favicon/apple-touch-icon.png';
import favicon16 from '../../images/favicon/favicon-16x16.png';
import favicon32 from '../../images/favicon/favicon-32x32.png';
import favicon194 from '../../images/favicon/favicon-194x194.png';
import androidChrome from '../../images/favicon/android-chrome-192x192.png';
import manifest from '../../images/favicon/site.webmanifest';
import safariPinnedIcon from '../../images/favicon/safari-pinned-tab.svg';
import favicon from '../../images/favicon/favicon.ico';
import msTile from '../../images/favicon/mstile-144x144.png';
import browserConfig from '../../images/favicon/browserconfig.xml';
import {SnackbarProvider} from 'notistack';
import Notifier from './notifications/Notifier';
import {useSelector} from 'react-redux';
import {AppState, languageSelector} from '../../types/types';
import {CookiesProvider} from 'react-cookie';
import StylesProvider from './StylesProvider';
import App from '../App';


const Skeleton: FC = () => {

    const language = useSelector(languageSelector);
    const checkoutCompleted = useSelector((state: AppState) => state.cart.checkout.completed);

    // Use checkout complete as key to re-render app after checkout is completed to open the trusted shop badge.
    return (
        <Router key={checkoutCompleted ? 'checkout-completed' : 'default'}>
            <CookiesProvider>
                <StyledEngineProvider injectFirst={true}>
                    <StylesProvider>
                        <CssBaseline />
                        <Suspense>
                            <Helmet htmlAttributes={{lang: language}}>
                                <meta charSet={'utf-8'}/>
                                <meta name={'viewport'}
                                      content={'width=device-width, initial-scale=1, shrink-to-fit=yes'}/>
                                <link rel={'apple-touch-icon'} sizes={'180x180'} href={appleTouchIcon}/>
                                <link rel={'icon'} type={'image/png'} sizes={'16x16'} href={favicon16}/>
                                <link rel={'icon'} type={'image/png'} sizes={'32x32'} href={favicon32}/>
                                <link rel={'icon'} type={'image/png'} sizes={'194x194'} href={favicon194}/>
                                <link rel={'icon'} type={'image/png'} sizes={'192x192'} href={androidChrome}/>
                                <link rel={'manifest'} href={manifest}/>
                                <link rel={'mask-icon'} href={safariPinnedIcon} color={'#e66d15'}/>
                                <link rel={'shortcut icon'} href={favicon}/>
                                <meta name={'msapplication-TileColor'} content={'#e66d15'}/>
                                <meta name={'msapplication-TileImage'} content={msTile}/>
                                <meta name={'msapplication-config'} content={browserConfig}/>
                                <meta name={'theme-color'} content={'#ffffff'}/>
                                <meta name={'description'} content={'Willkommen bei Royal Lounge Intimates'}/>
                                <meta name={'keywords'} lang={'de'} content={'Dessous, BH, Bra, Panties'}/>
                            </Helmet>
                            <SnackbarProvider anchorOrigin={{
                                horizontal: 'right',
                                vertical: 'bottom'
                            }} hideIconVariant={true}>
                                <Notifier/>
                                <App/>
                            </SnackbarProvider>
                        </Suspense>
                    </StylesProvider>
                </StyledEngineProvider>
            </CookiesProvider>
        </Router>
    );
}

export default Skeleton;