import React, {useState} from 'react';
import PerfectYourSizeSubNavElement from './PerfectYourSizeSubNavElement';
import {Routes} from '../../../../../routes/routes';
import {
  styleFinderImageSet,
  yourSizeImageSet,
  fittingTipsImageSet,
  sizingTableImageSet,
  optimiseFitImageSet
} from '../../../../sites/perfectYourSize/perfectYourSizeImages';
import {useLocalization} from '../../../../../localization/localization';
import {makeStyles} from 'tss-react/mui';
import {Fade, Grid, Tooltip} from '@mui/material';

const useStyles = makeStyles()((theme) => ({
    root: {
        padding: theme.spacing(4),
        background: 'white',
        width: 1180,
        boxShadow: '0 8px 64px 0 rgba(0,0,0,0.08)'
    },
    subNav: {
        width: 'auto',
        background: 'transparent',
        marginTop: theme.spacing(2)
    },
    tooltip: {
        background: 'transparent',
        width: 'auto',
        maxWidth: '100%',
        margin: 0,
        padding: 0,
        marginLeft: theme.spacing(-20)
    }
}));

const PerfectYourSizeSubNav: React.FC<React.PropsWithChildren> = ({children}) => {

    const {classes} = useStyles();
    const [openSubNav, setOpenSubNav] = useState(false);
    const messages = useLocalization();

    const subNavContent = (
        <div className={classes.root}>
            <Grid container={true} spacing={3}>
                <Grid item={true} xs>
                    <PerfectYourSizeSubNavElement link={Routes.FindYourStyle.Home()}
                                                image={styleFinderImageSet}
                                                title={messages.perfectYourSize.sections.styleFinder}
                                                close={() => setOpenSubNav(false)}/>
                </Grid>
                <Grid item={true} xs>
                    <PerfectYourSizeSubNavElement link={Routes.PerfectYourSize.Home(Routes.PerfectYourSize.GuidingTable)}
                                                image={yourSizeImageSet}
                                                title={messages.perfectYourSize.sections.guidingTable}
                                                close={() => setOpenSubNav(false)}/>
                </Grid>
                <Grid item={true} xs>
                    <PerfectYourSizeSubNavElement link={Routes.PerfectYourSize.Home(Routes.PerfectYourSize.VisitFittingRoom)}
                                                image={fittingTipsImageSet}
                                                title={messages.perfectYourSize.sections.fittingTips}
                                                close={() => setOpenSubNav(false)}/>
                </Grid>
                <Grid item={true} xs>
                    <PerfectYourSizeSubNavElement link={Routes.PerfectYourSize.Home(Routes.PerfectYourSize.MeasureYourSize)}
                                                image={sizingTableImageSet}
                                                title={messages.perfectYourSize.sections.measureYourSize}
                                                close={() => setOpenSubNav(false)}/>
                </Grid>
                <Grid item={true} xs>
                    <PerfectYourSizeSubNavElement link={Routes.PerfectYourSize.Home(Routes.PerfectYourSize.FitOptimization)}
                                                image={optimiseFitImageSet}
                                                title={messages.perfectYourSize.sections.optimiseFit}
                                                close={() => setOpenSubNav(false)}/>
                </Grid>
            </Grid>
        </div>
    )

    return (
        <Tooltip open={openSubNav} title={subNavContent} classes={{
            tooltip: classes.tooltip,
            popper: classes.subNav
        }} TransitionComponent={Fade} onClose={() => {
            setOpenSubNav(false)
        }} onOpen={() => {
            setOpenSubNav(true);
        }}>
            <div>
                {children}
            </div>
        </Tooltip>
    );
}

export default PerfectYourSizeSubNav;