import React, {FC} from 'react';
import logo from '../../images/icons/logo.svg';


const Loader: FC = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            minHeight: '100vh'
        }}>
            <img src={logo} alt={''} title={''} style={{
                maxWidth: '80%',
                width: 400
            }} />
        </div>
    );
}

export default Loader;