import React, {CSSProperties, useEffect, useState} from 'react';
import Image from './Image';
import {makeStyles} from 'tss-react/mui';
import {Skeleton} from '@mui/material';
import {useWindowSize} from './useWindowSize';
import {useBreakpoint} from './useBreakpoint';
import {CoverImageProps} from './CoverImage';

const useStyles = makeStyles()((theme) => ({
    root: {
        height: 402,
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        marginBottom: theme.spacing(1),
        borderRadius: 0
    },
    image: {
        position: 'absolute',
        top: 0,
        left: '50%',
        height: '100%',
        transform: 'translate(-50%, 0)'
    },
    loaderSurround: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        transition: theme.defaultTransition('width', 'left'),
        background: 'white'
    },
    loader: {
        width: '100%',
        height: '100%'
    },
    loaded: {
        width: 0,
        left: '100%'
    }
}));

const CoverImage: React.FC<CoverImageProps> = ({
                                                     imageSet,
                                                     className,
                                                     alt,
                                                     title,
                                                     equalWidthAndHeight,
                                                     placement,
                                                     heightRatio,
                                                     visible = true,
                                                     useLoader = false
                                                 }) => {
    const {classes, cx, theme} = useStyles();

    const [imageSurroundRef, setImageSurroundRef] = useState<HTMLDivElement | null>(null);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageRendered, setImageRendered] = useState(false);
    const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);
    const [imageStyle, setImageStyle] = useState<CSSProperties>({
        height: '100%'
    });
    const [imageSurroundStyle, setImageSurroundStyle] = useState<CSSProperties>({});

    const windowSize = useWindowSize();
    const breakpoint = useBreakpoint(theme);

    useEffect(() => {
        if (imageSurroundRef && imageRef && imageLoaded) {
            if (equalWidthAndHeight) {
                if (Math.round(imageSurroundRef.offsetWidth) !== Math.round(imageSurroundStyle.height as number || 0)) {
                    setImageSurroundStyle({
                        height: imageSurroundRef.offsetWidth
                    });
                }
            } else if (heightRatio) {
                if (Math.round(imageSurroundRef.offsetWidth * heightRatio) !== Math.round(imageSurroundStyle.height as number || 0)) {
                    setImageSurroundStyle({
                        height: imageSurroundRef.offsetWidth * heightRatio
                    });
                }
            }
            const imageRatio = imageRef.clientWidth / imageRef.clientHeight;
            const surroundRatio = equalWidthAndHeight ? 1 : imageSurroundRef.clientWidth / imageSurroundRef.clientHeight;
            if (surroundRatio > imageRatio) {
                let yShift = -50;
                let top = '50%';
                let bottom = 'auto';
                if (placement) {
                    switch (placement) {
                        case 'top':
                            yShift = 0;
                            top = '0';
                            break;
                        case 'bottom':
                            top = 'auto';
                            bottom = '0';
                            yShift = 0;
                            break;
                        default:
                            yShift = -55;
                            top = '50%';
                            bottom = 'auto';
                            break;
                    }
                }
                setImageStyle({
                    width: '100%',
                    height: 'auto',
                    left: 0,
                    top,
                    bottom,
                    transform: `translate(0, ${yShift}%)`
                });
            } else {
                setImageStyle({
                    height: '100%',
                    width: 'auto',
                    top: 0,
                    left: '50%',
                    transform: 'translate(-50%, 0)'
                });
            }
        }
    }, [equalWidthAndHeight, imageLoaded, imageRef, imageSurroundRef, placement, heightRatio, windowSize, imageSurroundStyle, breakpoint]);

    useEffect(() => {
        if (imageLoaded) {
            // Wait 2 times animation frame to assure image is rendered and hide the loader
            window.requestAnimationFrame(() => {
                window.requestAnimationFrame(() => {
                    setImageRendered(true);
                });
            });
        }
    }, [imageLoaded]);

    if (Boolean(imageSet.size)) {
        return (
            <div className={cx(classes.root, className)} ref={(ref) => {
                if (!imageSurroundRef && ref) {
                    setImageSurroundRef(ref);
                }
            }} style={imageSurroundStyle}>
                <Image imageSet={imageSet} alt={alt} imageClass={classes.image} play={visible} title={title}
                       imageRef={(ref) => {
                           if (!imageRef && ref) {
                               setImageRef(ref);
                           }
                       }} imageStyle={imageStyle} onLoad={() => {
                    setImageLoaded(true);
                }}/>
                {useLoader &&
                    <div className={cx(classes.loaderSurround, {
                        [classes.loaded]: imageRendered
                    })}>
                        <Skeleton variant={'rectangular'} animation={'wave'} className={classes.loader}/>
                    </div>}
            </div>
        );
    }
    return null;
}

export default CoverImage;
