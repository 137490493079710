import React, {FC, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {ImageSet, languageSelector} from '../../../../../types/types';
import {Link} from 'react-router-dom';
import {Routes} from '../../../../../routes/routes';
import {
    convertImagePathsToImageSet,
    getImageSet,
    getProductName,
    getProductSubTitle,
} from '../../../../../helper/helper';
import {Image as ImageDTO, ImageType, Product, ProductType} from '../../../../../types/products/types';
import CoverImage from '../../../../helper/CoverImage';
import {Dictionary} from '@reduxjs/toolkit';

import staticImageWebPLG2x from '../../../../../images/dest/navigation/dropdown-lg@2x.webp';
import staticImageWebPLG from '../../../../../images/dest/navigation/dropdown-lg@1x.webp';
import staticImageWebPMD2x from '../../../../../images/dest/navigation/dropdown-md@2x.webp';
import staticImageWebPMD from '../../../../../images/dest/navigation/dropdown-md@1x.webp';
import staticImageWebPSM2x from '../../../../../images/dest/navigation/dropdown-sm@2x.webp';
import staticImageWebPSM from '../../../../../images/dest/navigation/dropdown-sm@1x.webp';
import staticImageWebPXS2x from '../../../../../images/dest/navigation/dropdown-xs@2x.webp';
import staticImageWebPXS from '../../../../../images/dest/navigation/dropdown-xs@1x.webp';
import staticImageFallbackLG2x from '../../../../../images/dest/navigation/dropdown-lg@2x.jpg';
import staticImageFallbackLG from '../../../../../images/dest/navigation/dropdown-lg@1x.jpg';
import staticImageFallbackMD2x from '../../../../../images/dest/navigation/dropdown-md@2x.jpg';
import staticImageFallbackMD from '../../../../../images/dest/navigation/dropdown-md@1x.jpg';
import staticImageFallbackSM2x from '../../../../../images/dest/navigation/dropdown-sm@2x.jpg';
import staticImageFallbackSM from '../../../../../images/dest/navigation/dropdown-sm@1x.jpg';
import staticImageFallbackXS2x from '../../../../../images/dest/navigation/dropdown-xs@2x.jpg';
import staticImageFallbackXS from '../../../../../images/dest/navigation/dropdown-xs@1x.jpg';
import Image from '../../../../helper/Image';
import {useLocalization} from '../../../../../localization/localization';
import {makeStyles} from 'tss-react/mui';
import {Typography} from '@mui/material';
import ProductSubNavItem from './ProductSubNavItem';
import Button from '../../../../forms/buttons/Button';
import badge from '../../../../../images/icons/badge.svg';
import {productSelector} from '../../../../../reducer/products/types';

interface ProductSubNavProps {
    close: () => void;
    type: ProductType;
}

const useStyles = makeStyles<unknown>()((theme) => ({
    root: {
        width: 'auto',
        background: 'white',
        padding: theme.spacing(4),
        boxSizing: 'border-box',
        boxShadow: '0 8px 64px 0 rgba(0,0,0,0.08)'
    },
    contentSurround: {
        display: 'flex',
        padding: theme.spacing(0, 4, 0, 0)
    },
    productSurround: {
        display: 'flex',
        marginRight: 0
    },
    column: {
        marginRight: 60,
        [`& a:last-child`]: {
            marginBottom: 0
        }
    },
    icon: {
        width: 45,
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('xl')]: {
            width: 30
        }
    },
    imageSurround: {
        width: 167,
        height: 203,
        overflow: 'hidden',
        position: 'relative',
        color: theme.palette.secondary.main,
        flexShrink: 0,
        borderRadius: 0,
        display: 'flex',
        alignItems: 'center'
    },
    staticImageSurround: {
        width: 167,
        height: 203,
        overflow: 'hidden',
        position: 'relative',
        flexShrink: 0,
        borderRadius: 0,
        marginLeft: theme.spacing(3)
    },
    link: {
        ...theme.link,
        position: 'absolute',
        left: '50%',
        bottom: theme.spacing(3),
        transform: 'translate(-50%, 0)',
        display: 'block'
    },
    button: {
        minWidth: 'auto !important',
        whiteSpace: 'nowrap',
    },
    productSublineSurround: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        padding: theme.spacing(1),
        textAlign: 'center',
        fontWeight: 600,
        letterSpacing: 0.8,
        fontSize: 15,
        lineHeight: 1.25,
        color: theme.palette.secondary.main,
        background: 'rgba(255, 255, 255, 0.4)',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap'
    },
    badge: {
        width: 163,
        height: 163
    }
}));


const ProductSubNav: FC<ProductSubNavProps> = ({close, type}) => {

    const {classes} = useStyles({});
    const [activeProduct, setActiveProduct] = useState<Product | null>(null);
    const [maxWidth, setMaxWidth] = useState<Dictionary<number>>({});
    const maxWidthRef = useRef<Dictionary<number>>({});
    const messages = useLocalization();

    const staticImageImageSet: ImageSet = useMemo<ImageSet>(() =>
        getImageSet(staticImageWebPXS, staticImageWebPXS2x, staticImageWebPSM, staticImageWebPSM2x, staticImageWebPMD, staticImageWebPMD2x,
            staticImageWebPLG, staticImageWebPLG2x, staticImageFallbackXS, staticImageFallbackXS2x, staticImageFallbackSM, staticImageFallbackSM2x,
            staticImageFallbackMD, staticImageFallbackMD2x, staticImageFallbackLG, staticImageFallbackLG2x), []);


    const products = useSelector(productSelector).filter(it => it.type.id === type.id);
    const language = useSelector(languageSelector);


    // Create arrays containing a maximum of 4 products for the menu
    const productMap: Map<number, Product[]> = new Map<number, Array<Product>>();
    let usedColumn = 0;
    products.sort((p1, p2) => p1.orderNumber - p2.orderNumber).forEach((product, index) => {
        // Maximum of 2 columns per product
        const threshold = products.length < 5 ? 5 : Math.ceil(products.length / 2);
        const column = usedColumn + (index < threshold ? 0 : 1);
        const existingProducts = productMap.get(column) || [];
        existingProducts.push(product);
        productMap.set(column, existingProducts);
    });


    const imageSet: ImageSet | null = useMemo<ImageSet | null>(() => {
        if (activeProduct) {
            const thumbnails: ImageDTO[] = activeProduct.images.filter(it => it.type === ImageType.THUMBNAIL);
            return convertImagePathsToImageSet(thumbnails[0]?.paths || []);
        }
        return null;
    }, [activeProduct]);


    return (
        <div className={classes.root}>
            <div className={classes.contentSurround}>
                <div className={classes.productSurround}>
                    {Array.from(productMap.keys()).map((key, index) => (
                        <div key={`product-sub-nav-column-${index}`} className={classes.column} style={{
                            width: maxWidth[index]
                        }}>
                            {productMap.get(key)?.map(product =>
                                <ProductSubNavItem product={product} maxWidthRef={maxWidthRef} setMaxWidth={setMaxWidth}
                                                   maxWidth={maxWidth} language={language}
                                                   setActiveProduct={setActiveProduct} index={index}
                                                   closeSubNav={close}/>)}
                        </div>
                    ))}
                </div>
                <div className={classes.imageSurround}>
                    {activeProduct && imageSet ?
                        <>
                            <CoverImage title={getProductName(activeProduct.translations, language)}
                                        alt={getProductName(activeProduct.translations, language)} imageSet={imageSet}
                                        equalWidthAndHeight={false} className={classes.imageSurround}/>
                            <Typography variant={'body1'} component={'div'} className={classes.productSublineSurround}>
                                {getProductSubTitle(activeProduct.translations, language)}
                            </Typography>
                        </>
                        : <img src={badge} alt={''} title={''} className={classes.badge}/>}
                </div>
                <div className={classes.staticImageSurround}>
                    <Image imageSet={staticImageImageSet} alt={''} imageClass={''} title={''}/>
                    <Link to={Routes.ColorYourDay('')} className={classes.link} onClick={close}>
                        <Button className={classes.button} color={'white'} variant={'outlined'}>
                            {messages.menu.subNav.colorYourDay}
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    )

}

export default ProductSubNav;
