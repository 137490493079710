import {createAction, createSelector, Dictionary} from '@reduxjs/toolkit';
import {AppState, OverBustValue, PantiesSize, UnderBustValue} from '../../types/types';
import {Color, Country, FindYourStyle, FindYourStyleType, Product, ProductType, Size} from '../../types/products/types';
import FetchStatus, {get, Route} from '../../api/api';
import {createBasicSagaFetchAction, SagaHandling} from '../helper';
import {denormalize} from '../../helper/normalizeHelper';


export const LOAD_PRODUCTS = 'PRODUCTS/LOAD_PRODUCTS';

export enum Unit {
    CM = 'cm',
    INCHES = 'in'
}

export interface FetchProductsSuccessPayload {
    products: Product[];
    replaceExisting: boolean;
    result: number[];
}

export const loadProducts = createAction<{ replaceExisting: boolean; loadColors: boolean; }>(LOAD_PRODUCTS);

export interface PriceFilter {
    min?: number;
    max?: number;
}

export interface FetchLoadFindYourStyleSuccessPayload {
    type: FindYourStyleType
    styles: FindYourStyle[]
}

export const LoadFindYourStyleActionAndSaga = createBasicSagaFetchAction<FindYourStyleType, FetchLoadFindYourStyleSuccessPayload, 'UNKNOWN'>({
    actionGroup: 'products',
    actionName: 'loadFindYourStyle',
    networkCall: requestBody => {
        return () => get(Route.GetFindYourStyle(requestBody!))
    }
})

export interface ProductSelectionPayload {
    id: number;
    productID: number;
}

export interface UserMeasurement {
    overBustValue?: OverBustValue;
    underBustValue?: UnderBustValue;
    pantiesSize?: PantiesSize;
    overBustInput?: string;
    underBustInput?: string;
    pantiesInput?: string;
}

export interface ProductStateRestrictions {
    products: number[];
    colors: number[];
    sizes: number[];
    price: PriceFilter;
}

export const productTypeSelector = createSelector(
    (state: AppState) => state.products?.types,
    (productTypes) => denormalize(productTypes || {})
);

export const colorSelector = createSelector(
    (state: AppState) => state.products?.colors || {},
    (colors) => denormalize(colors)
);

export const productSelector = createSelector(
    (state: AppState) => state.products?.products || {},
    (products) => denormalize(products)
);


export const sizeSelector = createSelector(
    (state: AppState) => state.products?.sizes || {},
    (sizes) => denormalize(sizes)
);

export const LoadCountriesActionAndSaga = createBasicSagaFetchAction<void, Country[], 'UNKNOWN'>({
    actionGroup: 'products',
    actionName: 'loadCountries',
    networkCall: () => {
        return () => get(Route.GetCountries);
    }
});

export const LoadProductTypesActionAndSaga = createBasicSagaFetchAction<void, ProductType[], 'UNKNOWN'>({
    actionGroup: 'products',
    actionName: 'loadProductTypes',
    networkCall: () => {
        return () => get(Route.GetProductTypes);
    }
});

export const LoadColorsActionAndSaga = createBasicSagaFetchAction<void, Color[], 'UNKNOWN'>({
    actionGroup: 'products',
    actionName: 'loadColors',
    sagaHandling: SagaHandling.Every,
    networkCall: () => {
        return () => get(Route.GetColors);
    }
});

export const LoadSizesActionAndSaga = createBasicSagaFetchAction<void, Size[], 'UNKNOWN'>({
    actionGroup: 'products',
    actionName: 'loadSizes',
    sagaHandling: SagaHandling.Every,
    networkCall: () => {
        return () => get(Route.GetSizes);
    }
});

export const LoadProductActionAndSaga = createBasicSagaFetchAction<number, Product, 'UNKNOWN'>({
    actionGroup: 'products',
    actionName: 'loadProduct',
    sagaHandling: SagaHandling.Every,
    networkCall: (requestBody) => {
        return () => get(Route.GetProduct(requestBody!));
    }
});


export interface ProductState {
    products: Dictionary<Product>;
    unit: Unit;
    userMeasurements: UserMeasurement;
    productsToSelectedColor: Dictionary<number>;
    productsToSelectedSize: Dictionary<number>;
    fetchStatus: FetchStatus;
    colorFetchStatus: FetchStatus;
    sizesFetchStatus: FetchStatus;
    typeFetchStatus: FetchStatus;
    findYourStyleFetchStatus: Dictionary<FetchStatus>;
    countriesFetchStatus: FetchStatus;
    colors: Dictionary<Color>;
    sizes: Dictionary<Size>;
    restrictions: ProductStateRestrictions;
    searchResults: number[];
    applyRestrictions: boolean;
    types: Dictionary<ProductType>;
    findYourStyle: Dictionary<FindYourStyle>;
    countries: Dictionary<Country>;
}