let initialized = false;

export const getBingAnalytics = () => {

    if (!initialized) {
        (function (w: any, d: any, t: any, r: any, u: any) {
            let f: any;
            let n: any;
            let i: any;
            // @ts-ignore
            w[u] = w[u] || [];
            f = function () {
                const o: any = {ti: process.env.REACT_APP_MICROSOFT_TRACKING_KEY || ''};
                // @ts-ignore
                o.q = w[u];
                // @ts-ignore
                w[u] = new UET(o);
                w[u].push('pageLoad');
            }
            n = d.createElement(t);
            n.src = r;
            n.async = 1;
            // @ts-ignore
            n.onload = n.onreadystatechange = function () {
                const s = this.readyState;
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                (s && s !== 'loaded' && s !== 'complete') || (f(), n.onload = n.onreadystatechange = null)
            }
            i = d.getElementsByTagName(t)[0];
            i.parentNode.insertBefore(n, i)
        })
        (window, document, 'script', '//bat.bing.com/bat.js', 'uetq');
        initialized = true;
    }
}

