import React, {PropsWithChildren} from 'react';
import createCache from '@emotion/cache';
import theme from '../../theme/Theme';
import {ThemeProvider} from '@mui/material';
import {CacheProvider} from '@emotion/react';
export const muiCache = createCache({
    'key': 'rli',
    'prepend': true
});


const StylesProvider: React.FC<PropsWithChildren> = ({children}) => {

    return (
        <ThemeProvider theme={theme}>
            <CacheProvider value={muiCache}>
                {children}
            </CacheProvider>
        </ThemeProvider>
    )
}

export default StylesProvider;