import {call, select, spawn, takeEvery} from 'redux-saga/effects';
import {PayloadAction} from '@reduxjs/toolkit';
import {AppState} from '../types/types';
import {post, Route} from '../api/api';
import {TrackPageViewBody, TrackPaymentInfoBody, TrackViewContentBody} from '../tracking/actions';

const isTrackingAllowed = (state: AppState) => state.application.enableAnalytics;

function* trackAddToCartSaga() {
    yield takeEvery('tracking/addToCart', function* (action: PayloadAction<number>) {
        yield call(track, Route.Tracking.AddToCart(action.payload))
    });
}

function* trackInitiateCheckoutSaga() {
    yield takeEvery('tracking/trackInitiateCheckout', function* () {
        yield call(track, Route.Tracking.InitiateCheckout);
    });
}

function* trackAddPaymentInfoSaga() {
    yield takeEvery('tracking/trackAddPaymentInfo', function* (action: PayloadAction<TrackPaymentInfoBody>) {
        yield call(track, Route.Tracking.AddPaymentInfo, action.payload);
    });
}

function* trackViewContentSaga() {
    yield takeEvery('tracking/trackViewContent', function* (action: PayloadAction<TrackViewContentBody>) {
        yield call(track, Route.Tracking.ViewContent, action.payload);
    });
}

function* trackPageViewSaga() {
    yield takeEvery('tracking/trackPageView', function* (action: PayloadAction<TrackPageViewBody>) {
        yield call(track, Route.Tracking.PageView, action.payload);
    });
}

function* trackSearchProductsSaga() {
    yield takeEvery('tracking/trackSearchProducts', function* (action) {
        yield call(track, Route.Tracking.SearchProducts);
    });
}

function* track(route: string, body?: any) {
    try {
        const allowed: boolean = yield select(isTrackingAllowed);
        if (allowed) {
            yield call(post, route, body);
        }
    } catch (e) {
        // Ignore
    }
}

export default function* TrackingSaga() {
    yield spawn(trackAddToCartSaga);
    yield spawn(trackInitiateCheckoutSaga);
    yield spawn(trackAddPaymentInfoSaga);
    yield spawn(trackSearchProductsSaga);
    yield spawn(trackViewContentSaga);
    yield spawn(trackPageViewSaga);
}