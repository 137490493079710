import {AnyAction, combineReducers, Reducer, ReducersMapObject} from 'redux';
import {configureStore} from '@reduxjs/toolkit';
import {AppState} from '../types/types';
import createSagaMiddleware, {SagaMiddleware} from 'redux-saga';
import application from './application/application';
import {persistReducer} from 'redux-persist';
import {CartState} from './cart/types';
import cart from './cart/cart';
import localforage from 'localforage';
import {spawn} from 'redux-saga/effects';
import UserSaga from '../sagas/UserSaga';
import CartSaga from '../sagas/CartSaga';
import {GenericSaga} from '../sagas/GenericSaga';
import {ToolkitStore} from '@reduxjs/toolkit/dist/configureStore';
import user from './user/user';


const DATABASE_CONFIG = {
    key: 'royallounge',
    storage: localforage,
    blacklist: ['application', 'products', 'admin', 'user', 'cart', 'social', 'adminBlog', 'blog', 'adminPages', 'pages']
};

export function createReducerManager(initialReducers: ReducersMapObject<AppState, any>) {
    // Create an object which maps keys to reducers
    const reducers = {...initialReducers}

    // Create the initial combinedReducer
    let combinedReducer = persistReducer<AppState>(DATABASE_CONFIG, combineReducers<AppState>(reducers)) as unknown as Reducer<AppState>;

    // An array which is used to delete state keys when reducers are removed
    let keysToRemove: (keyof AppState)[] = []

    return {
        getReducerMap: () => reducers,

        // The root reducer function exposed by this object
        // This will be passed to the store
        reduce: (state: AppState, action: AnyAction): AppState => {
            // If any reducers have been removed, clean up their state first
            if (keysToRemove.length > 0) {
                state = {...state}
                for (let key of keysToRemove) {
                    delete state[key]
                }
                keysToRemove = []
            }

            // Delegate to the combined reducer
            return combinedReducer(state, action)
        },

        // Adds a new reducer with the specified key
        add: (key: keyof AppState, reducer: Reducer) => {
            if (!key || reducers[key]) {
                return;
            }

            // Add the reducer to the reducer mapping
            reducers[key] = reducer

            // Generate a new combined reducer
            combinedReducer = persistReducer(DATABASE_CONFIG, combineReducers<AppState>(reducers)) as unknown as Reducer<AppState>;
        },

        // Removes a reducer with the specified key
        remove: (key: keyof AppState) => {
            if (!key || !reducers[key]) {
                return
            }

            // Remove it from the reducer mapping
            delete reducers[key]

            // Add the key to the list of keys to clean up
            keysToRemove.push(key)

            // Generate a new combined reducer
            combinedReducer = persistReducer(DATABASE_CONFIG, combineReducers(reducers)) as unknown as Reducer<AppState>
        }
    }
}

localforage.config({
    name: 'royallounge',
    version: 1.0,
    size: 4980736,
    storeName: 'royallounge'
});


const CART_CONFIG = {
    key: 'royallounge_cart',
    storage: localforage,
    blacklist: ['fetchStatus', 'paymentResponse', 'confirmPaymentFetchStatus', 'payPalOrderID']
}

const staticReducers = {
    application,
    user,
    cart: persistReducer<CartState>(CART_CONFIG, cart) as any
}


export interface AppStore extends ToolkitStore<AppState, AnyAction, SagaMiddleware<object>[]> {
    reducerManager: {
        getReducerMap: () => ReducersMapObject,
        reduce: (state: AppState, action: AnyAction) => AppState;
        add: (key: keyof AppState, reducer: Reducer) => void;
        remove: (key: keyof AppState) => void;
    };
}

export function configureStoreManager() {
    const reducerManager = createReducerManager(staticReducers as ReducersMapObject<AppState, any>)

    const sagaMiddleware = createSagaMiddleware();
    const store = configureStore({
        reducer: reducerManager.reduce as Reducer<AppState, AnyAction> | ReducersMapObject<AppState, AnyAction>,
        middleware: () => [sagaMiddleware]
    });

    const appStore: AppStore = {
        ...store,
        reducerManager
    }

    function* appSaga() {
        yield spawn(UserSaga);
        yield spawn(CartSaga);
        yield spawn(GenericSaga);
    }

    sagaMiddleware.run(appSaga);

    return appStore;
}

const store = configureStoreManager();


export default store;