import {createTheme, Theme, ThemeOptions} from '@mui/material/styles';

function createMuiTheme(options: ThemeOptions) {
    const theme = createTheme(options);
    return createTheme({
        ...theme,
        spacing: 8,
        palette: {
            primary: {
                light: '#595959', // Content: Light Grey
                main: '#EA732E', // Brand Colour: Orange
                dark: '#193150', // Admin: Deep Blue
                contrastText: '#ffffff',
            },
            secondary: {
                light: '#4A4A4A',
                main: '#444444',
                dark: '#23262F',
                contrastText: '#ffffff',
            }
        },
        typography: {
            fontFamily: 'Inter, Helvetica, Roboto, Arial, sans-serif',
            h1: {
                fontFamily: 'Inter, Helvetica, Roboto, Arial, sans-serif',
                fontSize: 80,
                fontWeight: 400,
                letterSpacing: -2,
                lineHeight: '1.25',
                textTransform: 'inherit',
                color: '#444444',
                [theme.breakpoints.down('sm')]: {
                    fontSize: 60
                }
            },
            h2: {
                fontFamily: 'PlayfairDisplay, Times, Georgia, serif',
                fontSize: 48,
                fontWeight: 500,
                letterSpacing: -0.5,
                lineHeight: '1.35',
                textTransform: 'inherit',
                color: '#444444',
                [theme.breakpoints.down('sm')]: {
                    fontSize: 36
                }
            },
            h3: {
                fontFamily: 'Inter, Helvetica, Roboto, Arial, sans-serif',
                fontSize: 32,
                fontWeight: 600,
                letterSpacing: -0.5,
                lineHeight: '1.2',
                color: '#444444',
                [theme.breakpoints.down('sm')]: {
                    fontSize: 24
                }
            },
            h4: {
                fontFamily: 'PlayfairDisplay, Times, Georgia, serif',
                fontSize: 30,
                fontWeight: 600,
                letterSpacing: -1,
                lineHeight: '1.2',
                textTransform: 'inherit',
                color: '#444444',
                [theme.breakpoints.down('sm')]: {
                    fontSize: 22
                }
            },
            h5: {
                fontFamily: 'PlayfairDisplay, Times, Georgia, serif',
                fontSize: 22,
                fontWeight: 600,
                letterSpacing: 0.15,
                lineHeight: '1.3',
                color: '#444444',
                [theme.breakpoints.down('sm')]: {
                    fontSize: 18
                }
            },
            h6: {
                fontFamily: 'Inter, Helvetica, Roboto, Arial, sans-serif',
                fontSize: 18,
                fontWeight: 500,
                letterSpacing: -0.2,
                lineHeight: '1.25',
                textTransform: 'inherit',
                color: '#444444',
                [theme.breakpoints.down('sm')]: {
                    fontSize: 16,
                    fontWeight: 600,
                }
            },
            subtitle1: {
                fontFamily: 'Inter, Helvetica, Roboto, Arial, sans-serif',
                fontSize: 16,
                fontWeight: 600,
                letterSpacing: 0.15,
                lineHeight: '1.5',
                textTransform: 'inherit',
                color: '#444444',
                [theme.breakpoints.down('sm')]: {
                    fontSize: 15,
                    fontWeight: 500,
                }
            },
            subtitle2: {
                fontFamily: 'PlayfairDisplay, Times, Georgia, serif',
                fontSize: 16,
                fontWeight: 600,
                letterSpacing: 0.15,
                lineHeight: '1.5',
                textTransform: 'inherit',
                color: '#444444',
                [theme.breakpoints.down('sm')]: {
                    fontSize: 15,
                    fontWeight: 500,
                }
            },
            body1: {
                fontFamily: 'Inter, Helvetica, Roboto, Arial, sans-serif',
                fontSize: 16,
                fontWeight: 300,
                letterSpacing: -0.5,
                lineHeight: '1.5',
                textTransform: 'inherit',
                color: '#444444',
                [theme.breakpoints.down('sm')]: {
                    fontSize: 15,
                }
            },
            body2: {
                fontFamily: 'Inter, Helvetica, Roboto, Arial, sans-serif',
                fontSize: 14,
                fontWeight: 400,
                letterSpacing: 0.25,
                lineHeight: '1.6',
                textTransform: 'inherit',
                color: '#444444'
            },
            button: {
                fontFamily: 'Inter, Helvetica, Roboto, Arial, sans-serif',
                fontSize: 14,
                fontWeight: 400,
                letterSpacing: 1,
                lineHeight: '17px',
                textTransform: 'uppercase',
                [theme.breakpoints.down('sm')]: {
                    letterSpacing: 0.5,
                }
            },
            caption: {
                fontFamily: 'PlayfairDisplay, Times, Georgia, serif',
                fontSize: 13,
                fontWeight: 400,
                letterSpacing: 0.4,
                lineHeight: '1.5',
                textTransform: 'inherit',
                [theme.breakpoints.down('sm')]: {
                    fontSize: 14,
                    letterSpacing: 0.6,
                }
            },
            overline: {
                fontFamily: 'Inter, Helvetica, Roboto, Arial, sans-serif',
                fontSize: 10,
                fontWeight: 500,
                letterSpacing: 1.5,
                lineHeight: '1.6',
                textTransform: 'uppercase',
                [theme.breakpoints.down('sm')]: {
                    fontSize: 12,
                    letterSpacing: 2,
                }
            }
        },
        body: {
            width: '95%',
            maxWidth: 1440,
            margin: '0 auto'
        },
        link: {
            textDecoration: 'none',
            color: 'inherit',
            '&:hover': {
                textDecoration: 'none'
            }
        },
        defaultTransition: (property: string = 'all', ...properties: string[]) => {
            if (!Boolean(properties.length)) {
                return `${property} 0.5s cubic-bezier(0.55,0,0.6,1)`;
            }
            return `${[property, ...properties].join(' 0.5s cubic-bezier(0.55,0,0.6,1), ')} 0.5s cubic-bezier(0.55,0,0.6,1)`;
        },
        input: {
            fontFamily: 'Inter, Helvetica, Roboto, Arial, sans-serif',
            resize: 'none',
            outline: 'none',
            WebkitAppearance: 'none',
            borderRadius: 5,
            border: '1px solid #CBCCCD',
            background: 'white',
            color: '#23262F',
            fontSize: 15,
            padding: '12px 16px 12px 16px',
            width: '100%',
            boxSizing: 'border-box',
            margin: 0,
            transition: 'all 0.5s cubic-bezier(0.55, 0, 0.6, 1)',
            '&:focus': {
                color: '#23262F',
                fontWeight: 400
            },
            '&::placeholder': {
                color: '#BFC4CE',
                fontWeight: 400
            },
            [theme.breakpoints.down('md')]: {
                fontSize: 16,
                padding: '20px 24px'
            }

        },
        secondaryFontFamily: 'PlayfairDisplay, Inter, Roboto, Arial, sans-serif',
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        boxShadow: 'none',
                        textAlign: 'center',
                        fontSize: 14,
                        fontFamily: 'Inter, Helvetica, Roboto, Arial, sans-serif',
                        letterSpacing: 1.25,
                        fontWeight: 500,
                        lineHeight: '17px',
                        height: 50,
                        padding: theme.spacing(2),
                        borderRadius: 0,
                        textTransform: 'none',
                        '&:hover': {
                            boxShadow: 'none',
                        }
                    }
                }
            }
        }
    });
}

const theme: Theme = createMuiTheme({});

export const mobileTheme: Theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 50000,
            md: 51000,
            lg: 52000,
            xl: 53000
        }
    }
});

export const tabletTheme: Theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 1,
            md: 51000,
            lg: 52000,
            xl: 53000
        }
    }
});

export default theme;
