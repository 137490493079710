import React, {lazy, Suspense, useEffect, useRef} from 'react';
import {useMediaQuery} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {useSelector} from 'react-redux';
import {AppState} from '../../../types/types';
import Menu from './Menu';
import MobileMenu from './MobileMenu';
import {useLocation} from 'react-router-dom';

const MobileTopBar = lazy(() => import('./MobileTopBar'));
const TopBar = lazy(() => import( './TopBar'));

interface AppTopBarProps {
    preview?: boolean;
}

const AppTopBar: React.FC<AppTopBarProps> = ({preview}) => {
    const isWidthDownSM = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const mobileMenuOpen = useSelector((state: AppState) => state.application.showMobileMenu);
    const wasMobileMenuOpen = useRef(mobileMenuOpen);
    const {pathname} = useLocation();

    useEffect(() => {
        if (mobileMenuOpen) {
            wasMobileMenuOpen.current = true;
        }
    }, [mobileMenuOpen]);

    if (isWidthDownSM) {
        return (
            <Suspense>
                <MobileTopBar preview={preview}/>
                <MobileMenu/>
            </Suspense>
        );
    }

    return (
        <Suspense>
            <TopBar preview={preview}/>
            {!pathname.startsWith('/checkout') && <Menu preview={preview}/>}
        </Suspense>
    );
}

export default AppTopBar;