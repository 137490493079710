import {
    ApplicationMessage,
    ApplicationState, CookieConsent,
    Language, LoadNewsBarEntriesActionAndSaga, MenuColor, SearchStoresActionAndSaga, SendContactMessageActionAndSaga
} from './types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import FetchStatus from '../../api/api';
import {normalize} from '../../helper/normalizeHelper';
import Cookies from 'js-cookie';

const languageCookie = Cookies.get('language');

const navigatorLang = (navigator.language?.substr(0, 2) === 'pt' ? navigator.language?.substr(0, 5) : navigator.language?.substr(0, 2)).toLowerCase() as Language;

const DEFAULT_LANGUAGE = languageCookie ? languageCookie as Language : navigatorLang;

if (!languageCookie) {
    Cookies.set('language', DEFAULT_LANGUAGE);
}


const initialState: ApplicationState = {
    language: DEFAULT_LANGUAGE,
    showCookieNotification: false,
    showCurtain: true,
    showCart: false,
    showMobileMenu: false,
    messages: [],
    contactFetchStatus: FetchStatus.DEFAULT,
    stores: {
        fetchStatus: FetchStatus.DEFAULT,
        elements: {},
        searchResult: []
    },
    selectedColorID: -1,
    selectedSizeID: -1,
    enableAnalytics: false,
    showCookieConsent: false,
    showInactivityNewsletterDialog: false,
    productScrollTop: 0,
    newsBars: {
        fetchStatus: FetchStatus.DEFAULT,
        entries: {},
        visible: false,
        height: 0
    },
    menu: {
    }
}

const application = createSlice({
    name: 'application',
    initialState,
    reducers: {
        setLanguage: (state, action: PayloadAction<Language>) => {
            state.language = action.payload;
        },
        addApplicationMessage: (state, action: PayloadAction<ApplicationMessage>) => {
            state.messages.push(action.payload);
        },
        dismissApplicationMessage: (state, action: PayloadAction<string>) => {
            state.messages = state.messages.map(it => ({
                ...it,
                dismissed: it.key === action.payload ? true : it.dismissed
            }));
        },
        removeApplicationMessage: (state, action: PayloadAction<string>) => {
            state.messages = state.messages.filter(it => it.key !== action.payload);
        },
        setShowCart: (state, action: PayloadAction<boolean>) => {
            state.showCart = action.payload;
        },
        setShowMobileMenu: (state, action: PayloadAction<boolean>) => {
            state.showMobileMenu = action.payload;
        },
        setSelectedColor: (state, action: PayloadAction<number>) => {
            state.selectedColorID = action.payload;
        },
        setSelectedSize: (state, action: PayloadAction<number>) => {
            state.selectedSizeID = action.payload;
        },
        setShowCookieNotification: (state, action: PayloadAction<boolean>) => {
            state.showCookieNotification = action.payload;
        },
        setCookieConsent: (state, action: PayloadAction<CookieConsent>) => {
            state.showCookieNotification = false;
            state.enableAnalytics = action.payload.acceptTracking;
        },
        setShowCookieConsent: (state, action: PayloadAction<boolean>) => {
            state.showCookieConsent = action.payload;
        },
        setShowInactivityNewsletterDialog: (state, action: PayloadAction<boolean>) => {
            state.showInactivityNewsletterDialog = action.payload;
        },
        setNewsBarVisible: (state, action: PayloadAction<boolean>) => {
            state.newsBars.visible = action.payload;
            state.newsBars.height = !action.payload ? 0 : state.newsBars.height;
        },
        setNewsBarHeight: (state, action: PayloadAction<number>) => {
            state.newsBars.height = action.payload;
        },
        setProductScrollTop: (state, action: PayloadAction<number>) => {
            state.productScrollTop = action.payload;
        },
        setMenuColors: (state, action: PayloadAction<MenuColor>) => {
            state.menu = action.payload;
        }
    },
    extraReducers: builder => builder.addCase(LoadNewsBarEntriesActionAndSaga.startAction, (state, action) => {
        state.newsBars.fetchStatus = FetchStatus.ACTIVE;
    }).addCase(LoadNewsBarEntriesActionAndSaga.errorAction, (state, action) => {
        state.newsBars.fetchStatus = FetchStatus.ERROR;
    }).addCase(LoadNewsBarEntriesActionAndSaga.successAction, (state, action) => {
        state.newsBars.entries = normalize(action.payload);
        state.newsBars.fetchStatus = FetchStatus.SUCCESS;
    }).addCase(LoadNewsBarEntriesActionAndSaga.resetAction, (state, action) => {
        state.newsBars.fetchStatus = FetchStatus.DEFAULT;
    }).addCase(SearchStoresActionAndSaga.startAction, (state, action) => {
        state.stores.fetchStatus = FetchStatus.ACTIVE;
    }).addCase(SearchStoresActionAndSaga.errorAction, (state, action) => {
        state.stores.fetchStatus = FetchStatus.ERROR;
    }).addCase(SearchStoresActionAndSaga.successAction, (state, action) => {
        state.stores.fetchStatus = FetchStatus.SUCCESS;
        state.stores.searchResult = action.payload.map(it => it.id);
        state.stores.elements = {...state.stores.elements, ...normalize(action.payload)};
    }).addCase(SearchStoresActionAndSaga.resetAction, (state, action) => {
        state.stores.fetchStatus = FetchStatus.DEFAULT;
    }).addCase(SendContactMessageActionAndSaga.startAction, (state, action) => {
        state.contactFetchStatus = FetchStatus.ACTIVE;
    }).addCase(SendContactMessageActionAndSaga.errorAction, (state, action) => {
        state.contactFetchStatus = FetchStatus.ERROR;
    }).addCase(SendContactMessageActionAndSaga.successAction, (state, action) => {
        state.contactFetchStatus = FetchStatus.SUCCESS;
    }).addCase(SendContactMessageActionAndSaga.resetAction, (state, action) => {
        state.contactFetchStatus = FetchStatus.DEFAULT;
    })
});

export const {
    addApplicationMessage,
    dismissApplicationMessage,
    setNewsBarVisible,
    removeApplicationMessage,
    setNewsBarHeight,
    setCookieConsent,
    setShowCookieConsent,
    setShowCookieNotification,
    setShowInactivityNewsletterDialog,
    setProductScrollTop,
    setSelectedColor,
    setSelectedSize,
    setShowCart,
    setShowMobileMenu,
    setLanguage,
    setMenuColors
} = application.actions;

export default application.reducer;