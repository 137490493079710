import {createAction} from '@reduxjs/toolkit';


export interface TrackPaymentInfoBody {
    paymentMethod: string;
}

export interface TrackPageViewBody {
    page: string;
}

export interface TrackViewContentBody {
    page: string;
    content_name: string;
    content_category: string;
    content_ids: string[];
    content_type: string;
    value: number;
    currency: string;
}

export const trackAddToCart = createAction<number>('tracking/addToCart');
export const trackInitiateCheckout = createAction('tracking/trackInitiateCheckout');
export const trackAddPaymentInfo = createAction<TrackPaymentInfoBody>('tracking/trackAddPaymentInfo');
export const trackViewContent = createAction<TrackViewContentBody>('tracking/trackViewContent');
export const trackPageView = createAction<TrackPageViewBody>('tracking/trackPageView');
export const trackSearchProducts = createAction('tracking/trackSearchProducts');