import {ImagePath} from '../products/types';
import {Language} from '../../reducer/application/types';
import {OverridableStringUnion} from '@mui/types';
import {Variant} from '@mui/material/styles/createTypography';
import {TypographyPropsVariantOverrides} from '@mui/material/Typography/Typography';
import {CSSProperties} from 'react';

export enum PageElementType {
    Slider = 'Slider',
    ImageGrid = 'ImageGrid',
    ImageWithText = 'ImageWithText',
    Text = 'Text',
    TextWithButton = 'TextWithButton',
    ImageWithContentLeft = 'ImageWithContentLeft',
    ImageWithContentRight = 'ImageWithContentRight',
    BackgroundImage = 'BackgroundImage',
    FullImageWithContentLeft = 'FullImageWithContentLeft',
    FullImageWithContentRight = 'FullImageWithContentRight',
    SeoBox = 'SeoBox',
    ProductCarousel = 'ProductCarousel',
    ImageLinks = 'ImageLinks',
    ImageLinksLarge = 'ImageLinksLarge',
    SocialMedia = 'SocialMedia',
    Quote = 'Quote',

    UGC = 'UGC'
}

export interface PageElementImage {
    id: number;
    pageElementID: number;
    paths: ImagePath[];

    identifier: string;
}

export interface PageElementLocalization<KeySet> {
    language: Language;
    key: KeySet;
    value: string;
}

export interface PageElement<ConfigurationType extends PageElementConfiguration> {
    id: number;
    type: PageElementType;
    localizations: PageElementLocalization<any>[];

    images: PageElementImage[];
    order: number;
    config: ConfigurationType;
}

export interface Page {
    id: number;
    elements: PageElement<any>[];
    route: string;
    changed?: boolean;
    publishTimestamp?: number;

}

export interface Margin {
    top?: number | string;
    right?: number | string;
    bottom?: number | string;
    left?: number | string;
}
export interface Padding {
    top?: number | string;
    right?: number | string;
    bottom?: number | string;
    left?: number | string;
}

export interface Spacing {
    margin?: Margin;
    padding?: Padding;
}

export enum DeviceDimension {
    Desktop = 'Desktop',
    Tablet = 'Tablet',
    Mobile = 'Mobile'
}

export type ElementSpacing = Partial<Record<DeviceDimension, Spacing>>;

export interface PageElementConfiguration {
    spacing?: ElementSpacing;
}

export interface PageElementChildConfiguration {
    enabled: boolean;
}

export interface ButtonElementConfiguration extends PageElementChildConfiguration {
    localizationKey?: string;
    link?: string;
    variant?: 'outlined' | 'contained';
    color?: 'primary' | 'charcoal' | 'white'
}

export interface FileElementConfiguration extends PageElementConfiguration {
    identifier: string;
}

export interface TextElementStyles {
    fontSize?: number;
    fontWeight?: number | string;
    letterSpacing?: number | string;
    lineHeight?: number | string;
    textAlign?: CSSProperties['textAlign'];
}

export interface TextElementConfiguration extends PageElementChildConfiguration, TextElementStyles {
    localizationKey?: string;
    variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
    color?: string;
    fontFamily?: 'PlayfairDisplay' | 'Inter';
    styles?: Partial<Record<DeviceDimension, TextElementStyles>>;
}


export interface ImageGridElementConfiguration {
    button?: ButtonElementConfiguration;
    headline?: TextElementConfiguration;
    subline?: TextElementConfiguration;
    file?: FileElementConfiguration;
}

export enum ImageWithContentLocalizationKey {
    Overline = 'Overline',
    Headline = 'Headline',
    Subline = 'Subline',
    Content = 'Content',
    Button = 'Button',
}

export interface ImageWithContentConfiguration extends PageElementConfiguration {
    button?: ButtonElementConfiguration;
    headline?: TextElementConfiguration;
    content?: TextElementConfiguration;
    background?: string;

    overline?: TextElementConfiguration;
    file?: FileElementConfiguration;
}

export interface ImageGridConfiguration extends PageElementConfiguration {
    mainImage?: ImageGridElementConfiguration;
    sideImage1?: ImageGridElementConfiguration;
    sideImage2?: ImageGridElementConfiguration;
}

export interface ImageLinkGridElementConfiguration {
    id: number;
    position: number;
    button?: ButtonElementConfiguration;
    headline?: TextElementConfiguration;
    file?: FileElementConfiguration;
    link?: string;
}

export interface ImageLinkGridConfiguration extends PageElementConfiguration {
    elements?: ImageLinkGridElementConfiguration[];
}

export interface TextConfiguration extends PageElementConfiguration {
    text?: TextElementConfiguration;
    background?: string;
}

export enum BackgroundImageLocalizationKey {
    Headline = 'Headline',
    Subline = 'Subline',
    Button = 'Button',
}

export enum BackgroundImageSize {
    Large = 'Large',
    Medium = 'Medium',
    Small = 'Small'
}

export interface BackgroundImageConfiguration extends PageElementConfiguration {
    headline?: TextElementConfiguration;
    subline?: TextElementConfiguration;
    background?: FileElementConfiguration;
    button?: ButtonElementConfiguration;

    placement?: PlacementConfiguration;
    size?: BackgroundImageSize;
}

export interface SeoTextConfiguration extends PageElementConfiguration {
    overline?: TextElementConfiguration;
    headline?: TextElementConfiguration;
    content?: TextElementConfiguration;
    button?: ButtonElementConfiguration;
    background?: string;
}

export interface TextWithButtonConfiguration extends PageElementConfiguration {
    content?: TextElementConfiguration;
    button?: ButtonElementConfiguration;
    background?: string;
}

export enum ProductCarouselLocalizationKey {
    Title = 'Title'
}

export interface ProductConfiguration {
    id: number;
    productID?: number;
    colorID?: number;
    imageID?: number;
    order: number;
}

export interface ProductCarouselConfiguration extends PageElementConfiguration {
    title?: TextElementConfiguration;
    products?: ProductConfiguration[];
}

export interface QuoteConfiguration extends PageElementConfiguration {
    author?: TextElementConfiguration;
    content?: TextElementConfiguration;
    background?: string;
}

export enum SlideLocalizationBaseKey {
    Headline = 'Headline',
    Subline = 'Subline',
    Button = 'Button'
}

export interface PlacementConfiguration {
    horizontal?: 'left' | 'center' | 'right';
    vertical?: 'top' | 'center' | 'bottom';
}

export interface SlideConfiguration {
    id: number;
    position: number;
    headline?: TextElementConfiguration;
    subline?: TextElementConfiguration;
    background?: {
        desktop?: FileElementConfiguration;
        mobile?: FileElementConfiguration;
    };
    button?: ButtonElementConfiguration;

    placement?: PlacementConfiguration;
    menuColor?: string;
}

export interface SliderConfiguration extends PageElementConfiguration {
    slides: SlideConfiguration[];

}

export interface UGCElementConfiguration {
    id: number;
    position: number;
    file?: FileElementConfiguration;
    tag?: string;
}

export interface UGCConfiguration extends PageElementConfiguration {
    overline: TextElementConfiguration;
    headline: TextElementConfiguration;
    button: ButtonElementConfiguration;
    elements: UGCElementConfiguration[];
}

export enum InstagramVariant {
    Variant1 = 'Variant1',
    Variant2 = 'Variant2',
}

export interface InstagramConfiguration extends PageElementConfiguration {
    overline?: TextElementConfiguration;
    headline?: TextElementConfiguration;
    variant?: InstagramVariant;
    link?: string;
}