import {spawn, takeEvery} from 'redux-saga/effects';
import {PayloadAction} from '@reduxjs/toolkit';

let spawnedSagas: (() => Generator<any, any, any>)[] = [];

export function* GenericSaga() {
    yield takeEvery('application/spawnSaga', function* (action: PayloadAction<(() => Generator<any, any, any>)[]>) {
        if (action.payload) {
            for (const saga of action.payload) {
                if (!spawnedSagas.includes(saga)) {
                    yield spawn(saga);
                    spawnedSagas.push(saga);
                }
            }
        }
    });
}