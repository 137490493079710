import {getImageSet} from '../../../helper/helper';

import findYourStyleWebPXS from '../../../images/dest/perfectYourSize/find_your_style-xs@1x.webp';
import findYourStyleWebPXS2x from '../../../images/dest/perfectYourSize/find_your_style-xs@2x.webp';
import findYourStyleWebPSM from '../../../images/dest/perfectYourSize/find_your_style-sm@1x.webp';
import findYourStyleWebPSM2x from '../../../images/dest/perfectYourSize/find_your_style-sm@2x.webp';
import findYourStyleWebPMD from '../../../images/dest/perfectYourSize/find_your_style-md@1x.webp';
import findYourStyleWebPMD2x from '../../../images/dest/perfectYourSize/find_your_style-md@2x.webp';
import findYourStyleWebPLG from '../../../images/dest/perfectYourSize/find_your_style-lg@1x.webp';
import findYourStyleWebPLG2x from '../../../images/dest/perfectYourSize/find_your_style-lg@2x.webp';
import findYourStyleJPGXS from '../../../images/dest/perfectYourSize/find_your_style-xs@1x.jpg';
import findYourStyleJPGXS2x from '../../../images/dest/perfectYourSize/find_your_style-xs@2x.jpg';
import findYourStyleJPGSM from '../../../images/dest/perfectYourSize/find_your_style-sm@1x.jpg';
import findYourStyleJPGSM2x from '../../../images/dest/perfectYourSize/find_your_style-sm@2x.jpg';
import findYourStyleJPGMD from '../../../images/dest/perfectYourSize/find_your_style-md@1x.jpg';
import findYourStyleJPGMD2x from '../../../images/dest/perfectYourSize/find_your_style-md@2x.jpg';
import findYourStyleJPGLG from '../../../images/dest/perfectYourSize/find_your_style-lg@1x.jpg';
import findYourStyleJPGLG2x from '../../../images/dest/perfectYourSize/find_your_style-lg@2x.jpg';
import yourSizeWebPXS from '../../../images/dest/perfectYourSize/measure_ur_size-xs@1x.webp';
import yourSizeWebPXS2x from '../../../images/dest/perfectYourSize/measure_ur_size-xs@2x.webp';
import yourSizeWebPSM from '../../../images/dest/perfectYourSize/measure_ur_size-sm@1x.webp';
import yourSizeWebPSM2x from '../../../images/dest/perfectYourSize/measure_ur_size-sm@2x.webp';
import yourSizeWebPMD from '../../../images/dest/perfectYourSize/measure_ur_size-md@1x.webp';
import yourSizeWebPMD2x from '../../../images/dest/perfectYourSize/measure_ur_size-md@2x.webp';
import yourSizeWebPLG from '../../../images/dest/perfectYourSize/measure_ur_size-lg@1x.webp';
import yourSizeWebPLG2x from '../../../images/dest/perfectYourSize/measure_ur_size-lg@2x.webp';
import yourSizeJPGXS from '../../../images/dest/perfectYourSize/measure_ur_size-xs@1x.jpg';
import yourSizeJPGXS2x from '../../../images/dest/perfectYourSize/measure_ur_size-xs@2x.jpg';
import yourSizeJPGSM from '../../../images/dest/perfectYourSize/measure_ur_size-sm@1x.jpg';
import yourSizeJPGSM2x from '../../../images/dest/perfectYourSize/measure_ur_size-sm@2x.jpg';
import yourSizeJPGMD from '../../../images/dest/perfectYourSize/measure_ur_size-md@1x.jpg';
import yourSizeJPGMD2x from '../../../images/dest/perfectYourSize/measure_ur_size-md@2x.jpg';
import yourSizeJPGLG from '../../../images/dest/perfectYourSize/measure_ur_size-lg@1x.jpg';
import yourSizeJPGLG2x from '../../../images/dest/perfectYourSize/measure_ur_size-lg@2x.jpg';
import fittingTipsWebPXS from '../../../images/dest/perfectYourSize/fitting_room-xs@1x.webp';
import fittingTipsWebPXS2x from '../../../images/dest/perfectYourSize/fitting_room-xs@2x.webp';
import fittingTipsWebPSM from '../../../images/dest/perfectYourSize/fitting_room-sm@1x.webp';
import fittingTipsWebPSM2x from '../../../images/dest/perfectYourSize/fitting_room-sm@2x.webp';
import fittingTipsWebPMD from '../../../images/dest/perfectYourSize/fitting_room-md@1x.webp';
import fittingTipsWebPMD2x from '../../../images/dest/perfectYourSize/fitting_room-md@2x.webp';
import fittingTipsWebPLG from '../../../images/dest/perfectYourSize/fitting_room-lg@1x.webp';
import fittingTipsWebPLG2x from '../../../images/dest/perfectYourSize/fitting_room-lg@2x.webp';
import fittingTipsJPGXS from '../../../images/dest/perfectYourSize/fitting_room-xs@1x.jpg';
import fittingTipsJPGXS2x from '../../../images/dest/perfectYourSize/fitting_room-xs@2x.jpg';
import fittingTipsJPGSM from '../../../images/dest/perfectYourSize/fitting_room-sm@1x.jpg';
import fittingTipsJPGSM2x from '../../../images/dest/perfectYourSize/fitting_room-sm@2x.jpg';
import fittingTipsJPGMD from '../../../images/dest/perfectYourSize/fitting_room-md@1x.jpg';
import fittingTipsJPGMD2x from '../../../images/dest/perfectYourSize/fitting_room-md@2x.jpg';
import fittingTipsJPGLG from '../../../images/dest/perfectYourSize/fitting_room-lg@1x.jpg';
import fittingTipsJPGLG2x from '../../../images/dest/perfectYourSize/fitting_room-lg@2x.jpg';
import sizeGuideWebPXS from '../../../images/dest/perfectYourSize/sizing_guides-xs@1x.webp';
import sizeGuideWebPXS2x from '../../../images/dest/perfectYourSize/sizing_guides-xs@2x.webp';
import sizeGuideWebPSM from '../../../images/dest/perfectYourSize/sizing_guides-sm@1x.webp';
import sizeGuideWebPSM2x from '../../../images/dest/perfectYourSize/sizing_guides-sm@2x.webp';
import sizeGuideWebPMD from '../../../images/dest/perfectYourSize/sizing_guides-md@1x.webp';
import sizeGuideWebPMD2x from '../../../images/dest/perfectYourSize/sizing_guides-md@2x.webp';
import sizeGuideWebPLG from '../../../images/dest/perfectYourSize/sizing_guides-lg@1x.webp';
import sizeGuideWebPLG2x from '../../../images/dest/perfectYourSize/sizing_guides-lg@2x.webp';
import sizeGuideJPGXS from '../../../images/dest/perfectYourSize/sizing_guides-xs@1x.jpg';
import sizeGuideJPGXS2x from '../../../images/dest/perfectYourSize/sizing_guides-xs@2x.jpg';
import sizeGuideJPGSM from '../../../images/dest/perfectYourSize/sizing_guides-sm@1x.jpg';
import sizeGuideJPGSM2x from '../../../images/dest/perfectYourSize/sizing_guides-sm@2x.jpg';
import sizeGuideJPGMD from '../../../images/dest/perfectYourSize/sizing_guides-md@1x.jpg';
import sizeGuideJPGMD2x from '../../../images/dest/perfectYourSize/sizing_guides-md@2x.jpg';
import sizeGuideJPGLG from '../../../images/dest/perfectYourSize/sizing_guides-lg@1x.jpg';
import sizeGuideJPGLG2x from '../../../images/dest/perfectYourSize/sizing_guides-lg@2x.jpg';
import fitOptimisationWebPXS from '../../../images/dest/perfectYourSize/fit_optimisation-xs@1x.webp';
import fitOptimisationWebPXS2x from '../../../images/dest/perfectYourSize/fit_optimisation-xs@2x.webp';
import fitOptimisationWebPSM from '../../../images/dest/perfectYourSize/fit_optimisation-sm@1x.webp';
import fitOptimisationWebPSM2x from '../../../images/dest/perfectYourSize/fit_optimisation-sm@2x.webp';
import fitOptimisationWebPMD from '../../../images/dest/perfectYourSize/fit_optimisation-md@1x.webp';
import fitOptimisationWebPMD2x from '../../../images/dest/perfectYourSize/fit_optimisation-md@2x.webp';
import fitOptimisationWebPLG from '../../../images/dest/perfectYourSize/fit_optimisation-lg@1x.webp';
import fitOptimisationWebPLG2x from '../../../images/dest/perfectYourSize/fit_optimisation-lg@2x.webp';
import fitOptimisationJPGXS from '../../../images/dest/perfectYourSize/fit_optimisation-xs@1x.jpg';
import fitOptimisationJPGXS2x from '../../../images/dest/perfectYourSize/fit_optimisation-xs@2x.jpg';
import fitOptimisationJPGSM from '../../../images/dest/perfectYourSize/fit_optimisation-sm@1x.jpg';
import fitOptimisationJPGSM2x from '../../../images/dest/perfectYourSize/fit_optimisation-sm@2x.jpg';
import fitOptimisationJPGMD from '../../../images/dest/perfectYourSize/fit_optimisation-md@1x.jpg';
import fitOptimisationJPGMD2x from '../../../images/dest/perfectYourSize/fit_optimisation-md@2x.jpg';
import fitOptimisationJPGLG from '../../../images/dest/perfectYourSize/fit_optimisation-lg@1x.jpg';
import fitOptimisationJPGLG2x from '../../../images/dest/perfectYourSize/fit_optimisation-lg@2x.jpg';

export const styleFinderImageSet = getImageSet(findYourStyleWebPXS, findYourStyleWebPXS2x, findYourStyleWebPSM, findYourStyleWebPSM2x, findYourStyleWebPMD,
        findYourStyleWebPMD2x, findYourStyleWebPLG, findYourStyleWebPLG2x, findYourStyleJPGXS, findYourStyleJPGXS2x,
        findYourStyleJPGSM, findYourStyleJPGSM2x, findYourStyleJPGMD, findYourStyleJPGMD2x, findYourStyleJPGLG,
        findYourStyleJPGLG2x);


export const yourSizeImageSet = getImageSet(yourSizeWebPXS, yourSizeWebPXS2x, yourSizeWebPSM, yourSizeWebPSM2x, yourSizeWebPMD,
        yourSizeWebPMD2x, yourSizeWebPLG, yourSizeWebPLG2x, yourSizeJPGXS, yourSizeJPGXS2x,
        yourSizeJPGSM, yourSizeJPGSM2x, yourSizeJPGMD, yourSizeJPGMD2x, yourSizeJPGLG,
        yourSizeJPGLG2x);

export const fittingTipsImageSet = getImageSet(fittingTipsWebPXS, fittingTipsWebPXS2x, fittingTipsWebPSM, fittingTipsWebPSM2x, fittingTipsWebPMD,
        fittingTipsWebPMD2x, fittingTipsWebPLG, fittingTipsWebPLG2x, fittingTipsJPGXS, fittingTipsJPGXS2x,
        fittingTipsJPGSM, fittingTipsJPGSM2x, fittingTipsJPGMD, fittingTipsJPGMD2x, fittingTipsJPGLG,
        fittingTipsJPGLG2x);


export const sizingTableImageSet = getImageSet(sizeGuideWebPXS, sizeGuideWebPXS2x, sizeGuideWebPSM, sizeGuideWebPSM2x, sizeGuideWebPMD,
        sizeGuideWebPMD2x, sizeGuideWebPLG, sizeGuideWebPLG2x, sizeGuideJPGXS, sizeGuideJPGXS2x,
        sizeGuideJPGSM, sizeGuideJPGSM2x, sizeGuideJPGMD, sizeGuideJPGMD2x, sizeGuideJPGLG,
        sizeGuideJPGLG2x);

export const optimiseFitImageSet = getImageSet(fitOptimisationWebPXS, fitOptimisationWebPXS2x, fitOptimisationWebPSM, fitOptimisationWebPSM2x, fitOptimisationWebPMD,
        fitOptimisationWebPMD2x, fitOptimisationWebPLG, fitOptimisationWebPLG2x, fitOptimisationJPGXS, fitOptimisationJPGXS2x,
        fitOptimisationJPGSM, fitOptimisationJPGSM2x, fitOptimisationJPGMD, fitOptimisationJPGMD2x, fitOptimisationJPGLG,
        fitOptimisationJPGLG2x);