import {Breakpoint, useMediaQuery} from '@mui/material';
import {Theme} from '@mui/material/styles';


type BreakpointOrNull = Breakpoint | null

export const useBreakpoint = (theme: Theme): Breakpoint => {
    const keys: readonly Breakpoint[] = [...theme.breakpoints.keys].reverse()
    const breakPoint = keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const matches = useMediaQuery(theme.breakpoints.up(key))
        return output == null && matches ? key : output
    }, null) ?? 'xs';

    return breakPoint !== 'xl' ? breakPoint : 'lg';
}