import React from 'react';
import {Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import {ImageSet} from '../../../../../types/types';
import Image from '../../../../helper/Image';
import {makeStyles} from 'tss-react/mui';

interface FindYourStyleSubNavElementProps {
    link: string;
    image: ImageSet;
    title: string;
    close: () => void;
}

const useStyles = makeStyles()((theme) => ({
    root: {
        position: 'relative',
        display: 'block'
    },
    image: {
        width: '100%'
    },
    caption: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        textAlign: 'center',
        display: 'block',
        padding: theme.spacing(1),
        boxSizing: 'border-box',
        background: 'rgba(255, 255, 255, 0.4)'
    }
}));

const FindYourStyleSubNavElement: React.FC<FindYourStyleSubNavElementProps> = ({link, image, title, close}) => {
    const {classes} = useStyles();

    return (
        <Link to={link} className={classes.root} onClick={close}>
            <Image imageSet={image} alt={title} imageClass={classes.image} title={title} />
            <Typography variant={'subtitle1'} className={classes.caption}>
                {title}
            </Typography>
        </Link>
    );
}

export default FindYourStyleSubNavElement;