import React, {useEffect} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {SCROLL_TOP_SEARCH_PARAM} from '../../constants/constants';


const ScrollToTop: React.FC<React.PropsWithChildren> = ({children}) => {
    const {pathname} = useLocation();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (!searchParams.has(SCROLL_TOP_SEARCH_PARAM)) {
            window.scrollTo(0, 0);
        }
    }, [pathname, searchParams]);

    return <>{children}</>;
}

export default ScrollToTop;