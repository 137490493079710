import {createSlice} from '@reduxjs/toolkit';
import FetchStatus from '../../api/api';
import {normalize} from '../../helper/normalizeHelper';
import {
    LoadOrdersActionAndSaga,
    LoadUserActionAndSaga,
    LoginActionAndSaga, LogoutActionAndSaga,
    RegisterNewsletterActionAndSaga,
    RegisterUserActionAndSaga,
    ResetPasswordActionAndSaga,
    SubscribeNewsletterActionAndSaga,
    UpdatePasswordActionAndSaga,
    UserState
} from './types';

const initialState: UserState = {
    firstName: '',
    lastName: '',
    email: '',
    admin: false,
    loggedIn: false,
    fetchStatus: FetchStatus.DEFAULT,
    loginFetchStatus: FetchStatus.DEFAULT,
    registerFetchStatus: FetchStatus.DEFAULT,
    resetPasswordFetchStatus: FetchStatus.DEFAULT,
    updatePasswordFetchStatus: FetchStatus.DEFAULT,
    orderFetchStatus: FetchStatus.DEFAULT,
    registerNewsletterFetchStatus: FetchStatus.DEFAULT,
    subscribeNewsletterFetchStatus: FetchStatus.DEFAULT,
    orders: {},
    roles: []
}


const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
    },
    extraReducers: builder => builder.addCase(LoginActionAndSaga.startAction, (state) => {
        state.loginFetchStatus = FetchStatus.ACTIVE;
    }).addCase(LoginActionAndSaga.errorAction, state => {
        return {
            ...initialState,
            loginFetchStatus: FetchStatus.ERROR
        }
    }).addCase(LoginActionAndSaga.successAction, (state, action) => {
        return {
            ...state,
            loginFetchStatus: FetchStatus.SUCCESS,
            fetchStatus: FetchStatus.SUCCESS,
            loggedIn: true,
            ...action.payload
        }
    }).addCase(LoginActionAndSaga.resetAction, (state) => {
        state.loginFetchStatus = FetchStatus.DEFAULT;
    }).addCase(LoadUserActionAndSaga.startAction, (state) => {
        state.fetchStatus = FetchStatus.ACTIVE;
    }).addCase(LoadUserActionAndSaga.errorAction, state => {
        return {
            ...initialState,
            fetchStatus: FetchStatus.ERROR
        }
    }).addCase(LoadUserActionAndSaga.successAction, (state, action) => {
        return {
            ...state,
            fetchStatus: FetchStatus.SUCCESS,
            loggedIn: true,
            ...action.payload
        }
    }).addCase(RegisterUserActionAndSaga.startAction, (state) => {
        state.registerFetchStatus = FetchStatus.ACTIVE;
    }).addCase(RegisterUserActionAndSaga.errorAction, state => {
        state.registerFetchStatus = FetchStatus.ERROR;
    }).addCase(RegisterUserActionAndSaga.successAction, (state, action) => {
        return {
            ...state,
            registerFetchStatus: FetchStatus.SUCCESS,
            fetchStatus: FetchStatus.SUCCESS,
            loggedIn: true,
            ...action.payload
        }
    }).addCase(RegisterUserActionAndSaga.resetAction, state => {
        state.registerFetchStatus = FetchStatus.DEFAULT;
    }).addCase(ResetPasswordActionAndSaga.startAction, (state) => {
        state.resetPasswordFetchStatus = FetchStatus.ACTIVE;
    }).addCase(ResetPasswordActionAndSaga.errorAction, state => {
        state.resetPasswordFetchStatus = FetchStatus.ERROR;
    }).addCase(ResetPasswordActionAndSaga.successAction, (state, action) => {
        state.resetPasswordFetchStatus = FetchStatus.SUCCESS;
    }).addCase(ResetPasswordActionAndSaga.resetAction, state => {
        state.resetPasswordFetchStatus = FetchStatus.DEFAULT;
    }).addCase(SubscribeNewsletterActionAndSaga.startAction, (state) => {
        state.subscribeNewsletterFetchStatus = FetchStatus.ACTIVE;
    }).addCase(SubscribeNewsletterActionAndSaga.errorAction, state => {
        state.subscribeNewsletterFetchStatus = FetchStatus.ERROR;
    }).addCase(SubscribeNewsletterActionAndSaga.successAction, (state, action) => {
        state.subscribeNewsletterFetchStatus = FetchStatus.SUCCESS;
    }).addCase(SubscribeNewsletterActionAndSaga.resetAction, state => {
        state.subscribeNewsletterFetchStatus = FetchStatus.DEFAULT;
    }).addCase(RegisterNewsletterActionAndSaga.startAction, (state) => {
        state.registerNewsletterFetchStatus = FetchStatus.ACTIVE;
    }).addCase(RegisterNewsletterActionAndSaga.errorAction, state => {
        state.registerNewsletterFetchStatus = FetchStatus.ERROR;
    }).addCase(RegisterNewsletterActionAndSaga.successAction, (state, action) => {
        state.registerNewsletterFetchStatus = FetchStatus.SUCCESS;
    }).addCase(RegisterNewsletterActionAndSaga.resetAction, state => {
        state.registerNewsletterFetchStatus = FetchStatus.DEFAULT;
    }).addCase(LoadOrdersActionAndSaga.startAction, (state) => {
        state.orderFetchStatus = FetchStatus.ACTIVE;
    }).addCase(LoadOrdersActionAndSaga.errorAction, state => {
        state.orderFetchStatus = FetchStatus.ERROR;
    }).addCase(LoadOrdersActionAndSaga.successAction, (state, action) => {
        state.orderFetchStatus = FetchStatus.SUCCESS;
        state.orders = normalize(action.payload, undefined, 'invoiceNumber');
    }).addCase(LoadOrdersActionAndSaga.resetAction, state => {
        state.orderFetchStatus = FetchStatus.DEFAULT;
    }).addCase(UpdatePasswordActionAndSaga.startAction, (state) => {
        state.updatePasswordFetchStatus = FetchStatus.ACTIVE;
    }).addCase(UpdatePasswordActionAndSaga.errorAction, state => {
        state.updatePasswordFetchStatus = FetchStatus.ERROR;
    }).addCase(UpdatePasswordActionAndSaga.successAction, (state, action) => {
        state.updatePasswordFetchStatus = FetchStatus.SUCCESS;
    }).addCase(UpdatePasswordActionAndSaga.resetAction, state => {
        state.updatePasswordFetchStatus = FetchStatus.DEFAULT;
    }).addCase(LogoutActionAndSaga.successAction, (state, action) => {
        return {...initialState};
    }).addCase(LogoutActionAndSaga.errorAction, state => {
        return {...initialState};
    })
});

export default user.reducer;