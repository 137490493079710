import {useQuery} from '@tanstack/react-query';
import {get} from '../api/api';


export const getLocalization = async (language: string): Promise<Record<string, string>> => {
    try {
        const response = await get(`/api/v1/translation/localization/${language}`);

        return response as Record<string, string>;
    } catch (error) {
        throw error;
    }
};

export const useRemoteLocalization = (
    language: string,
    options?: {
        enabled?: boolean;
    },
) => {
    return useQuery(
        ['localization', language],
        () => getLocalization(language),
        options,
    );
};