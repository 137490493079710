import {getImageSet} from '../../../helper/helper';
import smallPetiteWebPXS from '../../../images/dest/findYourStyle/small_and_petite-xs@1x.webp';
import smallPetiteWebPXS2x from '../../../images/dest/findYourStyle/small_and_petite-xs@2x.webp';
import smallPetiteWebPSM from '../../../images/dest/findYourStyle/small_and_petite-sm@1x.webp';
import smallPetiteWebPSM2x from '../../../images/dest/findYourStyle/small_and_petite-sm@2x.webp';
import smallPetiteWebPMD from '../../../images/dest/findYourStyle/small_and_petite-md@1x.webp';
import smallPetiteWebPMD2x from '../../../images/dest/findYourStyle/small_and_petite-md@2x.webp';
import smallPetiteWebPLG from '../../../images/dest/findYourStyle/small_and_petite-lg@1x.webp';
import smallPetiteWebPLG2x from '../../../images/dest/findYourStyle/small_and_petite-lg@2x.webp';
import smallPetiteJPGXS from '../../../images/dest/findYourStyle/small_and_petite-xs@1x.png';
import smallPetiteJPGXS2x from '../../../images/dest/findYourStyle/small_and_petite-xs@2x.png';
import smallPetiteJPGSM from '../../../images/dest/findYourStyle/small_and_petite-sm@1x.png';
import smallPetiteJPGSM2x from '../../../images/dest/findYourStyle/small_and_petite-sm@2x.png';
import smallPetiteJPGMD from '../../../images/dest/findYourStyle/small_and_petite-md@1x.png';
import smallPetiteJPGMD2x from '../../../images/dest/findYourStyle/small_and_petite-md@2x.png';
import smallPetiteJPGLG from '../../../images/dest/findYourStyle/small_and_petite-lg@1x.png';
import smallPetiteJPGLG2x from '../../../images/dest/findYourStyle/small_and_petite-lg@2x.png';
import slimBustyWebPXS from '../../../images/dest/findYourStyle/slim_busty-xs@1x.webp';
import slimBustyWebPXS2x from '../../../images/dest/findYourStyle/slim_busty-xs@2x.webp';
import slimBustyWebPSM from '../../../images/dest/findYourStyle/slim_busty-sm@1x.webp';
import slimBustyWebPSM2x from '../../../images/dest/findYourStyle/slim_busty-sm@2x.webp';
import slimBustyWebPMD from '../../../images/dest/findYourStyle/slim_busty-md@1x.webp';
import slimBustyWebPMD2x from '../../../images/dest/findYourStyle/slim_busty-md@2x.webp';
import slimBustyWebPLG from '../../../images/dest/findYourStyle/slim_busty-lg@1x.webp';
import slimBustyWebPLG2x from '../../../images/dest/findYourStyle/slim_busty-lg@2x.webp';
import slimBustyJPGXS from '../../../images/dest/findYourStyle/slim_busty-xs@1x.png';
import slimBustyJPGXS2x from '../../../images/dest/findYourStyle/slim_busty-xs@2x.png';
import slimBustyJPGSM from '../../../images/dest/findYourStyle/slim_busty-sm@1x.png';
import slimBustyJPGSM2x from '../../../images/dest/findYourStyle/slim_busty-sm@2x.png';
import slimBustyJPGMD from '../../../images/dest/findYourStyle/slim_busty-md@1x.png';
import slimBustyJPGMD2x from '../../../images/dest/findYourStyle/slim_busty-md@2x.png';
import slimBustyJPGLG from '../../../images/dest/findYourStyle/slim_busty-lg@1x.png';
import slimBustyJPGLG2x from '../../../images/dest/findYourStyle/slim_busty-lg@2x.png';
import curvyModestWebPXS from '../../../images/dest/findYourStyle/curvy_and_modest-xs@1x.webp';
import curvyModestWebPXS2x from '../../../images/dest/findYourStyle/curvy_and_modest-xs@2x.webp';
import curvyModestWebPSM from '../../../images/dest/findYourStyle/curvy_and_modest-sm@1x.webp';
import curvyModestWebPSM2x from '../../../images/dest/findYourStyle/curvy_and_modest-sm@2x.webp';
import curvyModestWebPMD from '../../../images/dest/findYourStyle/curvy_and_modest-md@1x.webp';
import curvyModestWebPMD2x from '../../../images/dest/findYourStyle/curvy_and_modest-md@2x.webp';
import curvyModestWebPLG from '../../../images/dest/findYourStyle/curvy_and_modest-lg@1x.webp';
import curvyModestWebPLG2x from '../../../images/dest/findYourStyle/curvy_and_modest-lg@2x.webp';
import curvyModestJPGXS from '../../../images/dest/findYourStyle/curvy_and_modest-xs@1x.png';
import curvyModestJPGXS2x from '../../../images/dest/findYourStyle/curvy_and_modest-xs@2x.png';
import curvyModestJPGSM from '../../../images/dest/findYourStyle/curvy_and_modest-sm@1x.png';
import curvyModestJPGSM2x from '../../../images/dest/findYourStyle/curvy_and_modest-sm@2x.png';
import curvyModestJPGMD from '../../../images/dest/findYourStyle/curvy_and_modest-md@1x.png';
import curvyModestJPGMD2x from '../../../images/dest/findYourStyle/curvy_and_modest-md@2x.png';
import curvyModestJPGLG from '../../../images/dest/findYourStyle/curvy_and_modest-lg@1x.png';
import curvyModestJPGLG2x from '../../../images/dest/findYourStyle/curvy_and_modest-lg@2x.png';
import curvyBustyWebPXS from '../../../images/dest/findYourStyle/curvy_and_busty-xs@1x.webp';
import curvyBustyWebPXS2x from '../../../images/dest/findYourStyle/curvy_and_busty-xs@2x.webp';
import curvyBustyWebPSM from '../../../images/dest/findYourStyle/curvy_and_busty-sm@1x.webp';
import curvyBustyWebPSM2x from '../../../images/dest/findYourStyle/curvy_and_busty-sm@2x.webp';
import curvyBustyWebPMD from '../../../images/dest/findYourStyle/curvy_and_busty-md@1x.webp';
import curvyBustyWebPMD2x from '../../../images/dest/findYourStyle/curvy_and_busty-md@2x.webp';
import curvyBustyWebPLG from '../../../images/dest/findYourStyle/curvy_and_busty-lg@1x.webp';
import curvyBustyWebPLG2x from '../../../images/dest/findYourStyle/curvy_and_busty-lg@2x.webp';
import curvyBustyJPGXS from '../../../images/dest/findYourStyle/curvy_and_busty-xs@1x.png';
import curvyBustyJPGXS2x from '../../../images/dest/findYourStyle/curvy_and_busty-xs@2x.png';
import curvyBustyJPGSM from '../../../images/dest/findYourStyle/curvy_and_busty-sm@1x.png';
import curvyBustyJPGSM2x from '../../../images/dest/findYourStyle/curvy_and_busty-sm@2x.png';
import curvyBustyJPGMD from '../../../images/dest/findYourStyle/curvy_and_busty-md@1x.png';
import curvyBustyJPGMD2x from '../../../images/dest/findYourStyle/curvy_and_busty-md@2x.png';
import curvyBustyJPGLG from '../../../images/dest/findYourStyle/curvy_and_busty-lg@1x.png';
import curvyBustyJPGLG2x from '../../../images/dest/findYourStyle/curvy_and_busty-lg@2x.png';

export const smallPetiteImageSet = getImageSet(smallPetiteWebPXS, smallPetiteWebPXS2x, smallPetiteWebPSM, smallPetiteWebPSM2x, smallPetiteWebPMD,
        smallPetiteWebPMD2x, smallPetiteWebPLG, smallPetiteWebPLG2x, smallPetiteJPGXS, smallPetiteJPGXS2x,
        smallPetiteJPGSM, smallPetiteJPGSM2x, smallPetiteJPGMD, smallPetiteJPGMD2x, smallPetiteJPGLG,
        smallPetiteJPGLG2x);


export const slimBustyImageSet = getImageSet(slimBustyWebPXS, slimBustyWebPXS2x, slimBustyWebPSM, slimBustyWebPSM2x, slimBustyWebPMD,
        slimBustyWebPMD2x, slimBustyWebPLG, slimBustyWebPLG2x, slimBustyJPGXS, slimBustyJPGXS2x,
        slimBustyJPGSM, slimBustyJPGSM2x, slimBustyJPGMD, slimBustyJPGMD2x, slimBustyJPGLG,
        slimBustyJPGLG2x);

export const curvyModestImageSet = getImageSet(curvyModestWebPXS, curvyModestWebPXS2x, curvyModestWebPSM, curvyModestWebPSM2x, curvyModestWebPMD,
        curvyModestWebPMD2x, curvyModestWebPLG, curvyModestWebPLG2x, curvyModestJPGXS, curvyModestJPGXS2x,
        curvyModestJPGSM, curvyModestJPGSM2x, curvyModestJPGMD, curvyModestJPGMD2x, curvyModestJPGLG,
        curvyModestJPGLG2x);


export const curvyBustyImageSet = getImageSet(curvyBustyWebPXS, curvyBustyWebPXS2x, curvyBustyWebPSM, curvyBustyWebPSM2x, curvyBustyWebPMD,
        curvyBustyWebPMD2x, curvyBustyWebPLG, curvyBustyWebPLG2x, curvyBustyJPGXS, curvyBustyJPGXS2x,
        curvyBustyJPGSM, curvyBustyJPGSM2x, curvyBustyJPGMD, curvyBustyJPGMD2x, curvyBustyJPGLG,
        curvyBustyJPGLG2x);