import {spawn, takeLatest, put, call, select, all} from 'redux-saga/effects';
import {
    LOAD_PRODUCTS,
    LoadColorsActionAndSaga,
    LoadCountriesActionAndSaga,
    LoadFindYourStyleActionAndSaga, LoadProductActionAndSaga,
    LoadProductTypesActionAndSaga, LoadSizesActionAndSaga,
    ProductStateRestrictions
} from '../reducer/products/types';
import {get, Route} from '../api/api';
import {
    Product,
    ProductColor,
} from '../types/products/types';
import {PayloadAction} from '@reduxjs/toolkit';
import {AppState, languageSelector} from '../types/types';
import {
    fetchLoadProductColorsSuccess,
    fetchProducts,
    fetchProductsError,
    fetchProductsSuccess,
    finishProductLoading
} from '../reducer/products/products';
import {denormalize} from '../helper/normalizeHelper';
import {Language} from '../reducer/application/types';

function* loadProductsSaga() {
    yield takeLatest(LOAD_PRODUCTS, function* (action: PayloadAction<{
        replaceExisting: boolean;
        loadColors: boolean;
    }>) {
        try {
            yield put(fetchProducts());
            const search: ProductStateRestrictions = yield select((state: AppState) => state.products!.restrictions);
            const language: Language = yield select(languageSelector);
            const productIds: number[] = yield call(get, Route.GetProducts, {search});
            const existingProducts: Product[] = yield select((state: AppState) => denormalize(state.products?.products || {}).filter(it => it.translations.some(localization => localization.language === language)));
            const existingIds = existingProducts.map(it => it.id);
            // Now filter only not loaded products
            const productIdsToLoad: number[] = productIds.filter(it => !existingIds.includes(it));
            const products: Product[] = yield all(productIdsToLoad.map(it => call(get, Route.GetProduct(it))));
            yield put(fetchProductsSuccess({products: [...products, ...existingProducts], replaceExisting: true, result: productIds}));
            if (action.payload.loadColors) {
                // Check if color are already part of products
                const colorIdsToLoad = [...products, ...existingProducts].filter(it => !it.colors).map(it => it.id);
                const productColors: ProductColor[] = ((yield all(colorIdsToLoad.map(it => call(get, Route.GetProductColors([it]))))) as ProductColor[][]).flatMap(it => it);
                yield put(fetchLoadProductColorsSuccess(productColors));
            }
            yield put(finishProductLoading());
        } catch (e) {
            console.error(e);
            yield put(fetchProductsError());
        }
    });
}

export default function* productSaga() {
    yield spawn(loadProductsSaga);
    yield spawn(LoadProductActionAndSaga.saga!);
    yield spawn(LoadSizesActionAndSaga.saga!);
    yield spawn(LoadColorsActionAndSaga.saga!);
    yield spawn(LoadProductTypesActionAndSaga.saga!);
    yield spawn(LoadFindYourStyleActionAndSaga.saga!);
    yield spawn(LoadCountriesActionAndSaga.saga!);
}