import FetchStatus, {get, post, Route} from '../../api/api';
import {createAction, Dictionary} from '@reduxjs/toolkit';
import {Order} from '../../types/products/types';
import {Language} from '../application/types';
import {Cookie, CookieSetOptions} from 'universal-cookie';
import {BaseErrorCode, createBasicSagaFetchAction, SagaHandling} from '../helper';
import {call, put} from 'redux-saga/effects';
import {COOKIE_NEWSLETTER_REGISTERED_KEY, NEWSLETTER_COOKIE_MAX_AGE} from '../../constants/constants';


export enum Gender {
    MALE = 'male',
    FEMALE = 'female',
    OTHER = 'other'
}

export interface User {
    gender?: Gender;
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
    admin: boolean;
    roles: UserRole[];
}


export interface LoginPayload {
    email: string
    password: string
}

export interface RegisterPayload {
    email: string
    password: string
    firstName?: string
    lastName: string;
    language: Language;
}

export interface UpdatePasswordPayload {
    password: string
    passwordConfirm: string
}
export interface RegisterNewsletterPayload {
    email: string;
    language: Language;
    setCookie: (name: string, value: Cookie, options?: CookieSetOptions) => void;
}


export interface SubscribeNewsletterPayload {
    token: string;
    language: Language;
    email: string;
    coupon: string;
    firstName?: string;
}

export enum Role {
    ADMIN = 'ADMIN',
    USER = 'USER',
    TRANSLATOR = 'TRANSLATOR',
}

export interface UserRole {
    name: Role;
}


export function* startAdmin(): Generator<any, any, any> {

    const importStore = () => import('../reducerManager');
    const importAdmin = () => import('../../reducer/admin/admin');
    const importAdminPages = () => import('../../reducer/admin/pages/adminPages');
    const importAdminBlog = () => import('../../reducer/admin/blog/adminBlog');
    const importAdminSaga = () => import('../../sagas/AdminSaga');
    const importAdminBlogSaga = () => import('../../sagas/AdminBlogSaga');
    const importAdminPagesSaga = () => import('../../sagas/AdminPagesSaga');

    const store = yield call(importStore);
    const adminReducer = yield call(importAdmin);
    const adminBlogReducer = yield call(importAdminBlog);
    const adminPagesReducer = yield call(importAdminPages);
    const adminSaga = yield call(importAdminSaga);
    const adminBlogSaga = yield call(importAdminBlogSaga);
    const adminPagesSaga = yield call(importAdminPagesSaga);
    store.default.reducerManager.add('admin', adminReducer.default);
    store.default.reducerManager.add('adminPages', adminPagesReducer.default);
    store.default.reducerManager.add('adminBlog', adminBlogReducer.default);
    yield put(createAction<(() => Generator<any, any, any>)[]>('application/spawnSaga')([adminSaga.default, adminBlogSaga.default, adminPagesSaga.default]));
}


export const LoginActionAndSaga = createBasicSagaFetchAction<LoginPayload, User, BaseErrorCode>({
    actionGroup: 'user',
    actionName: 'login',
    networkCall: requestBody => {
        return () => post(Route.Login, requestBody);
    },
    successGenerator: [function* (request, response) {
        if (response.admin || response.roles.map(it => it.name).includes(Role.TRANSLATOR)) {
            yield call(startAdmin);
        }
    }]
});

export const LoadUserActionAndSaga = createBasicSagaFetchAction<void, User, BaseErrorCode>({
    actionGroup: 'user',
    actionName: 'loadUser',
    networkCall: () => {
        return () => get(Route.UserInfo);
    },
    successGenerator: [function* (request, response) {
        if (response.admin || response.roles.map(it => it.name).includes(Role.TRANSLATOR)) {
            yield call(startAdmin);
        }
    }]
});


export const RegisterUserActionAndSaga = createBasicSagaFetchAction<RegisterPayload, User, BaseErrorCode>({
    actionGroup: 'user',
    actionName: 'registerUser',
    networkCall: requestBody => {
        return () => post(Route.Register, requestBody!);
    }
});

interface ResetPasswordPayload {
    email: string;
    language: Language;
}

export const ResetPasswordActionAndSaga = createBasicSagaFetchAction<ResetPasswordPayload, void, BaseErrorCode>({
    actionGroup: 'user',
    actionName: 'resetPassword',
    networkCall: requestBody => {
        return () => post(Route.ResetPassword, {email: requestBody!.email, language: requestBody!.language || Language.GERMAN});
    }
});

export const RegisterNewsletterActionAndSaga = createBasicSagaFetchAction<RegisterNewsletterPayload, void, BaseErrorCode>({
    actionGroup: 'user',
    actionName: 'registerNewsletter',
    sagaHandling: SagaHandling.Latest,
    networkCall: requestBody => {
        return () => post(Route.RegisterNewsletter, {email: requestBody!.email.trim(), language: requestBody!.language});
    },
    // eslint-disable-next-line require-yield
    successGenerator: [function* (request) {
        request!.setCookie(COOKIE_NEWSLETTER_REGISTERED_KEY, 'true', {
            maxAge: NEWSLETTER_COOKIE_MAX_AGE
        });
    }]
});

export const SubscribeNewsletterActionAndSaga = createBasicSagaFetchAction<SubscribeNewsletterPayload, void, BaseErrorCode>({
    actionGroup: 'user',
    actionName: 'subscribeNewsletter',
    sagaHandling: SagaHandling.Every,
    networkCall: requestBody => {
        return () => post(Route.SubscribeNewsletter, requestBody);
    }
});

export const LoadOrdersActionAndSaga = createBasicSagaFetchAction<void, Order[], BaseErrorCode>({
    actionGroup: 'user',
    actionName: 'loadOrders',
    sagaHandling: SagaHandling.Latest,
    networkCall: () => {
        return () => get(Route.LoadOrders);
    }
});

export const UpdatePasswordActionAndSaga = createBasicSagaFetchAction<UpdatePasswordPayload, void, BaseErrorCode>({
    actionGroup: 'user',
    actionName: 'updatePassword',
    sagaHandling: SagaHandling.Latest,
    networkCall: (requestBody) => {
        return () => post(Route.UpdatePassword, requestBody);
    }
});

export const LogoutActionAndSaga = createBasicSagaFetchAction<void, void, BaseErrorCode>({
    actionGroup: 'user',
    actionName: 'logout',
    sagaHandling: SagaHandling.Every,
    networkCall: (requestBody) => {
        return () => post(Route.Logout);
    }
});

export interface UserState extends User {
    loggedIn: boolean;
    fetchStatus: FetchStatus;
    loginFetchStatus: FetchStatus;
    registerFetchStatus: FetchStatus;
    resetPasswordFetchStatus: FetchStatus;
    updatePasswordFetchStatus: FetchStatus;
    orderFetchStatus: FetchStatus;
    registerNewsletterFetchStatus: FetchStatus;
    subscribeNewsletterFetchStatus: FetchStatus;
    orders: Dictionary<Order>;
    roles: UserRole[];
}