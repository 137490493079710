import {createAction, PayloadAction, PayloadActionCreator} from '@reduxjs/toolkit';
import {call, delay, put, race, takeLatest, takeLeading, ForkEffect, takeEvery} from 'redux-saga/effects';

export enum SagaHandling {
    Every, Latest, Leading
}

export interface ErrorResponse<ErrorCode extends BaseErrorCode> {
    message: string;
    errorCode?: ErrorCode;
    statusCode?: number;
}

export type BaseErrorCode = 'UNKNOWN';


export interface FetchAction<RequestBody, ResponseBody, ErrorCode extends BaseErrorCode, ResetActionPayload extends RequestBody> {
    actionKey: string;
    startKey: string;
    successKey: string;
    errorKey: string;
    resetStatusKey: string;
    action: PayloadActionCreator<RequestBody>;
    startAction: PayloadActionCreator<RequestBody>;
    successAction: PayloadActionCreator<ResponseBody>;
    errorAction: PayloadActionCreator<ErrorResponse<ErrorCode> & RequestBody>;
    resetAction: PayloadActionCreator<ResetActionPayload>;
    saga?: () => Generator<ForkEffect, void, void>
}

export interface BasicSagaFetchActionParameter<RequestBody, ResponseBody> {
    actionGroup: string;
    actionName: string;
    networkCall: (requestBody?: RequestBody) => () => Promise<ResponseBody>;
    timeoutMillis?: number;
    sagaHandling?: SagaHandling;
    successGenerator?: ((request: RequestBody, response: ResponseBody) => Generator<any, any, any>)[];
    errorGenerator?: ((request: RequestBody, error: any) => Generator<any, any, any>)[];
    debounceDelay?: number;
}

export const DefaultHttpTimeout = 30000;




export function* delayedSaga<Payload>(actionKey: string, saga: (action: PayloadAction<Payload>) => Generator<any, any, any>, delayInMilliSeconds: number = 300) {
    yield takeLatest(actionKey, function* (action: PayloadAction<Payload>): Generator<any, any, any> {
        yield delay(delayInMilliSeconds);
        yield call(saga, action);
    });
}

export function createBasicSagaFetchAction<RequestBody, ResponseBody, ErrorCode extends BaseErrorCode>({
                                                                                                           actionGroup,
                                                                                                           actionName,
                                                                                                           networkCall,
                                                                                                           timeoutMillis = DefaultHttpTimeout,
                                                                                                           sagaHandling = SagaHandling.Latest,
                                                                                                           successGenerator = [],
                                                                                                           errorGenerator = [],
                                                                                                           debounceDelay = 0
                                                                                                       }: BasicSagaFetchActionParameter<RequestBody, ResponseBody>): FetchAction<RequestBody, ResponseBody, ErrorCode, RequestBody> {
    const modifiedActionName = actionName.toUpperCase();
    const modifiedActionGroup = actionGroup.toUpperCase();
    const action: FetchAction<RequestBody, ResponseBody, ErrorCode, RequestBody> = {
        actionKey: `${modifiedActionGroup}/${modifiedActionName}`,
        startKey: `${modifiedActionGroup}/fetch${modifiedActionName}`,
        successKey: `${modifiedActionGroup}/fetch${modifiedActionName}Success`,
        errorKey: `${modifiedActionGroup}/fetch${modifiedActionName}Error`,
        resetStatusKey: `${modifiedActionGroup}/reset${modifiedActionName}FetchStatus`,
        action: createAction<RequestBody>(`${modifiedActionGroup}/${modifiedActionName}`),
        startAction: createAction<RequestBody>(`${modifiedActionGroup}/fetch${modifiedActionName}`),
        successAction: createAction<ResponseBody>(`${modifiedActionGroup}/fetch${modifiedActionName}Success`),
        errorAction: createAction<ErrorResponse<ErrorCode> & RequestBody>(`${modifiedActionGroup}/fetch${modifiedActionName}Error`),
        resetAction: createAction<RequestBody>(`${modifiedActionGroup}/reset${modifiedActionName}FetchStatus`)
    }

    function* worker(calledAction: PayloadAction<RequestBody>): Generator<any, any, any> {
        let called: (() => Promise<ResponseBody>) | undefined = undefined;
        try {
            yield put(action.startAction(calledAction.payload));
            called = networkCall(calledAction.payload);
            const {networkAction} = yield race({
                networkAction: call(called),
                timeout: delay(timeoutMillis)
            });
            if (networkAction) {
                yield put(action.successAction(networkAction));
                for (const generator of successGenerator) {
                    yield call(generator, calledAction.payload, networkAction);
                }
            } else {
                console.error('Timeout for network action');
                yield put(action.errorAction({
                    ...calledAction.payload,
                    message: ''
                }));
                for (const generator of errorGenerator) {
                    yield call(generator, calledAction.payload, new Error('Network timeout'));
                }
            }
        } catch (e) {
            console.error('Unexpected error', e);
            yield put(action.errorAction({
                ...calledAction.payload,
                message: ''
            }))
            for (const generator of errorGenerator) {
                yield call(generator, calledAction.payload, e);
            }
        }
    }

    function* sagaGenerator(): Generator<any, void, void> {
        if (sagaHandling === SagaHandling.Every) {
            yield takeEvery(action.actionKey, worker);
        } else if (sagaHandling === SagaHandling.Leading) {
            yield takeLeading(action.actionKey, worker);
        } else {
            if (debounceDelay <= 0) {
                yield takeLatest(action.actionKey, worker);
            } else {
                yield delayedSaga(action.actionKey, worker, debounceDelay);
            }
        }
    }

    return {
        ...action,
        saga: sagaGenerator
    }
}