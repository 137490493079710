import React, {FC, lazy, Suspense, useEffect} from 'react';
import {
    Route, Routes as Switch, useLocation
} from 'react-router-dom';
import ScrollToTop from './helper/ScrollToTop';
import {useDispatch, useSelector} from 'react-redux';
import {Routes} from '../routes/routes';
import Loader from './skeleton/Loader';
import {LoadUserActionAndSaga} from '../reducer/user/types';
import {AppState} from '../types/types';
import {Theme, useMediaQuery} from '@mui/material';
import Analytics from './analytics/Analytics';
import {
    enableNewsletterInactivity,
    registerFocusLoss,
    registerUserActivity
} from '../reducer/application/types';
import {useCookies} from 'react-cookie';
import {COOKIE_NEWSLETTER_INACTIVITY_KEY} from '../constants/constants';
import AppTopBar from './skeleton/navigation/AppTopBar';
import {scroller} from 'react-scroll';
import {useLocalization} from '../localization/localization';


const TrustedShopsBadge = lazy(() => import('./sites/trustedShops/TrustedShopsBadge'));
const Footer = lazy(() => import('./skeleton/navigation/footer/Footer'));
const CookieNotification = lazy(() => import('./cookies/CookieNotification'));
const CookieConsent = lazy(() => import('./cookies/CookieConsent'));
const StockDialog = lazy(() => import('./sites/admin/stock/StockDialog'));
const UpdatePasswordDialog = lazy(() => import('./sites/admin/password/UpdatePasswordDialog'));
const Home = lazy(() => import('./sites/home/Home'));
const Admin = lazy(() => import('./sites/admin/Admin'));
const Contact = lazy(() => import('./sites/contact/Contact'));
const RoyalKingdom = lazy(() => import('./sites/royalKingdom/RoyalKingdom'));
const ColorYourDay = lazy(() => import('./sites/colorYourDay/ColorYourDay'));
const FindYourStyle = lazy(() => import('./sites/findYourStyle/FindYourStyleBase'));
const NewsletterDialogWrapper = lazy(() => import('./newsletter/NewsletterDialogWrapper'));
const Blog = lazy(() => import('./sites/blog/Blog'));
const PerfectYourSizeBase = lazy(() => import('./sites/perfectYourSize/PerfectYourSizeBase'));
const ProductBase = lazy(() => import('./sites/products/ProductBase'));
const LegalBase = lazy(() => import( './sites/legal/LegalBase'));
const CompanyBase = lazy(() => import( './sites/company/CompanyBase'));
const UserBase = lazy(() => import( './sites/user/UserBase'));
const CheckoutBase = lazy(() => import( './sites/checkout/CheckoutBase'));
const Cart = lazy(() => import('./sites/cart/Cart'));

const App: FC = () => {
    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies([COOKIE_NEWSLETTER_INACTIVITY_KEY]);
    const {hash} = useLocation();

    const admin = useSelector((state: AppState) => state.user.admin);

    const language = useSelector((state: AppState) => state.application.language);
    const localization = useLocalization();

    const isWidthDownSM = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

    useEffect(() => {
        dispatch(LoadUserActionAndSaga.action());
    }, [dispatch, language]);


    useEffect(() => {
        window.setTimeout(() => {
            dispatch(enableNewsletterInactivity({cookie: cookies[COOKIE_NEWSLETTER_INACTIVITY_KEY], setCookie}));
        }, 0)
    }, [dispatch, setCookie, cookies]);

    useEffect(() => {
        let timeout: number | undefined;
        if (hash) {
            timeout = window.setTimeout(() => {
                scroller.scrollTo(hash.replace('#', ''), {
                    duration: 0,
                    delay: 0,
                    smooth: true,
                    offset: isWidthDownSM ? -90 : -16
                });
                timeout = undefined;
            }, 100);
        }
        return () => {
            if (timeout) {
                window.clearTimeout(timeout);
            }
        }
    }, [isWidthDownSM, hash]);


    useEffect(() => {
        const handler = () => {
            dispatch(registerUserActivity());
        }
        const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
        events.forEach(it => document.addEventListener(it, handler, true));
        const focusLossHandler = () => {
            if (document.visibilityState === 'hidden') {
                dispatch(registerFocusLoss());
            }
        }
        document.addEventListener('visibilitychange', focusLossHandler, true);
        return () => {
            // Deregister event handler
            events.forEach(it => document.removeEventListener(it, handler, true));
            document.removeEventListener('visibilitychange', focusLossHandler, true);
        }
    }, [dispatch]);


    return (
        <ScrollToTop>
            <div>
                <Suspense fallback={<Loader/>}>
                    {localization?.isLoadingLanguage === true ? <></> : <>
                        <AppTopBar/>
                        <Cart/>
                        <Switch>
                            <Route path={Routes.FindYourStyle.Home('/*')} element={<FindYourStyle/>}/>
                            <Route path={Routes.FindYourStyleOld.Home('/*')} element={<FindYourStyle/>}/>
                            <Route path={Routes.PerfectYourSize.Home('/*')} element={<PerfectYourSizeBase/>}/>
                            <Route path={Routes.ColorYourDay(':color')} element={<ColorYourDay/>}/>
                            <Route path={Routes.ColorYourDay()} element={<ColorYourDay/>}/>
                            <Route path={Routes.ColorYourDayOld(':color')} element={<ColorYourDay/>}/>
                            <Route path={Routes.ColorYourDayOld()} element={<ColorYourDay/>}/>
                            <Route path={Routes.Admin.Base('/*')} element={<Admin/>}/>
                            <Route path={Routes.Contact} element={<Contact/>}/>
                            <Route path={Routes.RoyalKingdom} element={<RoyalKingdom/>}/>
                            <Route path={Routes.Blog.Base('/*')} element={<Blog/>}/>
                            <Route path={Routes.Home} element={<Home/>}/>
                        </Switch>
                        <ProductBase/>
                        <LegalBase/>
                        <CompanyBase/>
                        <CheckoutBase/>
                        <UserBase/>
                        <Footer />
                        <NewsletterDialogWrapper/>
                        <CookieNotification/>
                        <CookieConsent/>
                    </>}
                    {admin && <StockDialog/>}
                    {admin && <UpdatePasswordDialog/>}
                </Suspense>
                <Analytics/>
                <TrustedShopsBadge />
            </div>
        </ScrollToTop>
    );
}

export default App;
