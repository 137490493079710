export const SERVER: string = process.env.REACT_APP_BACKEND ? process.env.REACT_APP_BACKEND : '';

export const PROTOCOL: string = `${process.env.REACT_APP_PROTOCOL ? process.env.REACT_APP_PROTOCOL : 'https'}://`;

export const BASE_URL: string = PROTOCOL + SERVER;

export const IMAGE_BASE_URL: string = PROTOCOL + (process.env.REACT_APP_BACKEND_IMAGES || SERVER);
export const BLOG_IMAGE_BASE_URL: string = PROTOCOL + (process.env.REACT_APP_BACKEND_BLOG_IMAGES || SERVER);

export const PAGE_FILE_BASE_URL: string = PROTOCOL + (process.env.REACT_APP_BACKEND_FILE_IMAGES || SERVER);


export const COOKIE_KEY = process.env.REACT_APP_COOKIE_CONSENT_KEY || '';
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN || '';
// Cookie has maximum of 7 days
export const COOKIE_MAX_AGE = 7 * 24 * 60 * 60;
export const NEWSLETTER_COOKIE_MAX_AGE = 365 * 24 * 60 * 60;

export const COOKIE_NEWSLETTER_INACTIVITY_KEY = process.env.REACT_APP_COOKIE_NEWSLETTER_INACTIVITY_KEY || '';
export const COOKIE_NEWSLETTER_REGISTERED_KEY = process.env.REACT_APP_COOKIE_NEWSLETTER_REGISTERED_KEY || '';

export const BASE_ROUTE = process.env.REACT_APP_ROUTE_BASE || '';

export const IS_IOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

export const ADMIN_FONT = 'Inter, sans-serif';

export const NUMBER_OF_MODEL_IMAGES = 6;

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const DESKTOP_SLIDESHOW_HEIGHT = '50vw';

export const MOBILE_TOP_BAR_HEIGHT = 70;

export const SCROLL_TOP_SEARCH_PARAM = '__sT';