import {
    FetchProductsSuccessPayload,
    ProductSelectionPayload,
    ProductState,
    PriceFilter,
    Unit,
    UserMeasurement,
    LoadCountriesActionAndSaga,
    LoadFindYourStyleActionAndSaga,
    LoadProductTypesActionAndSaga,
    LoadColorsActionAndSaga, LoadSizesActionAndSaga, LoadProductActionAndSaga
} from './types';
import {createSlice, Dictionary, PayloadAction} from '@reduxjs/toolkit';
import {denormalize, normalize} from '../../helper/normalizeHelper';
import {Product, ProductColor} from '../../types/products/types';
import FetchStatus from '../../api/api';
import {OverBustValue, UnderBustValue} from '../../types/types';

const initialState: ProductState = {
    products: {},
    productsToSelectedColor: {},
    productsToSelectedSize: {},
    unit: Unit.CM,
    userMeasurements: {},
    fetchStatus: FetchStatus.DEFAULT,
    colorFetchStatus: FetchStatus.DEFAULT,
    sizesFetchStatus: FetchStatus.DEFAULT,
    typeFetchStatus: FetchStatus.DEFAULT,
    countriesFetchStatus: FetchStatus.DEFAULT,
    findYourStyleFetchStatus: {},
    colors: {},
    sizes: {},
    restrictions: {
        products: [],
        colors: [],
        sizes: [],
        price: {}
    },
    searchResults: [],
    applyRestrictions: true,
    types: {},
    findYourStyle: {},
    countries: {}
}

const products = createSlice({
    name: 'products',
    initialState,
    reducers: {
        fetchProducts: state => {
            state.fetchStatus = FetchStatus.ACTIVE;
        },
        fetchProductsSuccess: (state, action: PayloadAction<FetchProductsSuccessPayload>) => {
            state.searchResults = action.payload.result;
            if (action.payload.replaceExisting) {
                state.products = {...state.products, ...normalize(action.payload.products)};
            } else {
                // Only add new products into the state
                state.products = {...state.products, ...normalize(action.payload.products.filter(it => !Boolean(state.products[it.id])))};
            }
        },
        fetchProductsError: state => {
            state.fetchStatus = FetchStatus.ERROR;
        },
        finishProductLoading: state => {
            state.fetchStatus = FetchStatus.SUCCESS;
            state.applyRestrictions = true;
        },
        setUnit: (state, action: PayloadAction<Unit>) => {
            state.unit = action.payload;
        },
        setUserMeasurement: (state, action: PayloadAction<UserMeasurement>) => {
            state.userMeasurements = {...state.userMeasurements, ...action.payload};
        },
        setUnderBustInput: (state, action: PayloadAction<string>) => {
            state.userMeasurements.underBustInput = action.payload;
        },
        setPantiesInput: (state, action: PayloadAction<string>) => {
            state.userMeasurements.pantiesInput = action.payload;
        },
        setOverBustInput: (state, action: PayloadAction<string>) => {
            state.userMeasurements.overBustInput = action.payload;
        },
        setUserUnderBustValue: (state, action: PayloadAction<UnderBustValue>) => {
            state.userMeasurements.underBustValue = action.payload;
        },
        setUserOverBustValue: (state, action: PayloadAction<OverBustValue>) => {
            state.userMeasurements.overBustValue = action.payload;
        },
        setSelectedProductColor: (state, action: PayloadAction<ProductSelectionPayload>) => {
            state.productsToSelectedColor[action.payload.productID] = action.payload.id;
        },
        setSelectedProductSize: (state, action: PayloadAction<ProductSelectionPayload>) => {
            state.productsToSelectedSize[action.payload.productID] = action.payload.id;
        },
        fetchLoadProductColorsSuccess: (state, action: PayloadAction<ProductColor[]>) => {
            state.applyRestrictions = true;
            const handledProducts: Dictionary<Product> = {};
            action.payload.forEach(it => {
                let product = handledProducts[it.productID];
                if (!product) {
                    product = state.products[it.productID]!;
                }
                let handledColor = product.colors?.find(color => color.id === it.colorID);
                if (handledColor) {
                    handledColor.productColor = it;
                    product.colors = (product.colors || []).filter(color => color.id !== it.colorID);
                    product.colors.push(handledColor);
                }
                handledProducts[it.productID] = product;
            });
        },
        addProductFilter: (state, action: PayloadAction<number[]>) => {
            state.restrictions.products.push(...action.payload);
            state.applyRestrictions = false;
        },
        setProductFilter: (state, action: PayloadAction<number[]>) => {
            state.restrictions.products = action.payload;
            state.applyRestrictions = true;
        },
        removeProductFilter: (state, action: PayloadAction<number[]>) => {
            state.restrictions.products = state.restrictions.products.filter(it => !action.payload.includes(it));
            state.applyRestrictions = false;
        },
        addColorFilter: (state, action: PayloadAction<number[]>) => {
            state.restrictions.colors.push(...action.payload);
            state.applyRestrictions = false;
        },
        setColorFilter: (state, action: PayloadAction<number[]>) => {
            state.restrictions.colors = action.payload;
            state.applyRestrictions = false;
        },
        removeColorFilter: (state, action: PayloadAction<number[]>) => {
            state.restrictions.colors = state.restrictions.colors.filter(it => !action.payload.includes(it));
            state.applyRestrictions = false;
        },
        addSizeFilter: (state, action: PayloadAction<number[]>) => {
            state.restrictions.sizes.push(...action.payload);
            state.applyRestrictions = false;
        },
        setSizeFilter: (state, action: PayloadAction<number[]>) => {
            state.restrictions.sizes = action.payload;
            state.applyRestrictions = false;
        },
        removeSizeFilter: (state, action: PayloadAction<number[]>) => {
            state.restrictions.sizes = state.restrictions.sizes.filter(it => !action.payload.includes(it));
            state.applyRestrictions = false;
        },
        setPriceFilter: (state, action: PayloadAction<PriceFilter>) => {
            state.restrictions.price = action.payload;
            state.applyRestrictions = false;
        },
        resetProductFilter: state => {
            state.restrictions.products = [];
            state.applyRestrictions = false;
        },
        resetColorFilter: state => {
            state.restrictions.colors = [];
            state.applyRestrictions = false;
        },
        resetSizeFilter: state => {
            state.restrictions.sizes = [];
            state.applyRestrictions = false;
        },
        resetPriceFilter: state => {
            state.restrictions.price = {};
            state.applyRestrictions = false;
        },
    },
    extraReducers: builder => builder.addCase(LoadCountriesActionAndSaga.startAction, (state) => {
        state.countriesFetchStatus = FetchStatus.ACTIVE;
    }).addCase(LoadCountriesActionAndSaga.errorAction, (state) => {
        state.countriesFetchStatus = FetchStatus.ERROR;
    }).addCase(LoadCountriesActionAndSaga.successAction, (state, action) => {
        state.countriesFetchStatus = FetchStatus.SUCCESS;
        state.countries = normalize(action.payload);
    }).addCase(LoadFindYourStyleActionAndSaga.startAction, (state, action) => {
        state.findYourStyleFetchStatus[action.payload] = FetchStatus.ACTIVE;
    }).addCase(LoadFindYourStyleActionAndSaga.errorAction, (state, action) => {
        state.findYourStyleFetchStatus[action.payload] = FetchStatus.ERROR;
    }).addCase(LoadFindYourStyleActionAndSaga.successAction, (state, action) => {
        let elements = denormalize(state.findYourStyle);
        // Remove all existing elements of the given type from the state and append the new loaded
        elements = [...elements.filter(it => it.type !== action.payload.type), ...action.payload.styles];
        state.findYourStyleFetchStatus[action.payload.type] = FetchStatus.SUCCESS;
        state.findYourStyle = normalize(elements);
    }).addCase(LoadProductTypesActionAndSaga.startAction, (state) => {
        state.typeFetchStatus = FetchStatus.ACTIVE;
    }).addCase(LoadProductTypesActionAndSaga.errorAction, state => {
        state.typeFetchStatus = FetchStatus.ERROR;
    }).addCase(LoadProductTypesActionAndSaga.successAction, (state, action) => {
        state.typeFetchStatus = FetchStatus.SUCCESS;
        state.types = normalize(action.payload);
    }).addCase(LoadColorsActionAndSaga.startAction, state =>  {
        state.colorFetchStatus = FetchStatus.ACTIVE;
    }).addCase(LoadColorsActionAndSaga.errorAction, state =>  {
        state.colorFetchStatus = FetchStatus.ERROR;
    }).addCase(LoadColorsActionAndSaga.successAction, (state, action) =>  {
        state.colorFetchStatus = FetchStatus.SUCCESS;
        state.colors = normalize(action.payload);
    }).addCase(LoadSizesActionAndSaga.startAction, state => {
        state.sizesFetchStatus = FetchStatus.ACTIVE;
    }).addCase(LoadSizesActionAndSaga.errorAction, state => {
        state.sizesFetchStatus = FetchStatus.ERROR;
    }).addCase(LoadSizesActionAndSaga.successAction, (state, action) => {
        state.sizesFetchStatus = FetchStatus.SUCCESS;
        state.sizes = normalize(action.payload);
    }).addCase(LoadProductActionAndSaga.startAction, (state, action) => {
        // Check if product already exists in state, if user directly loads product page, the product is probably not
        // in the state yet
        const product = state.products[action.payload];
        if (product) {
            product.fetchStatus = FetchStatus.ACTIVE;
        }
    }).addCase(LoadProductActionAndSaga.errorAction, (state, action) => {
        const product = state.products[action.payload];
        if (product) {
            product.fetchStatus = FetchStatus.ERROR;
        }
    }).addCase(LoadProductActionAndSaga.successAction, (state, action) => {
        state.products[action.payload.id] = {
            ...action.payload,
            fetchStatus: FetchStatus.SUCCESS
        };
    })
});

export const {
    addSizeFilter,
    addProductFilter,
    setSizeFilter,
    addColorFilter,
    resetColorFilter,
    resetPriceFilter,
    resetProductFilter,
    removeSizeFilter,
    fetchLoadProductColorsSuccess,
    finishProductLoading,
    setColorFilter,
    removeColorFilter,
    removeProductFilter,
    setPriceFilter,
    setProductFilter,
    setSelectedProductColor,
    setSelectedProductSize,
    fetchProducts,
    fetchProductsError,
    fetchProductsSuccess,
    resetSizeFilter,
    setOverBustInput,
    setUnderBustInput,
    setUnit,
    setUserOverBustValue,
    setUserUnderBustValue,
    setUserMeasurement,
    setPantiesInput
} = products.actions;

export default products.reducer;