import {createAction, Dictionary} from '@reduxjs/toolkit';
import FetchStatus, {get, post, Route} from '../../api/api';
import {Store} from '../../types/products/types';
import {Cookie, CookieSetOptions} from 'universal-cookie';
import {NewsBarEntry} from '../admin/types';
import {BaseErrorCode, createBasicSagaFetchAction} from '../helper';

export enum Language {
    GERMAN = 'de',
    ENGLISH = 'en',
    ITALIAN = 'it',
    DUTCH = 'nl',
    SPANISH = 'es',
    FRENCH = 'fr',
    POLISH = 'pl',
    CZECH = 'cs',
    PORTUGUESE = 'pt-pt'
}

export interface CookieConsent {
    acceptTracking: boolean
}

export const ENABLE_NEWSLETTER_INACTIVITY = 'APPLICATION/ENABLE_NEWSLETTER_INACTIVITY';
export const DISABLE_NEWSLETTER_INACTIVITY = 'APPLICATION/DISABLE_NEWSLETTER_INACTIVITY';
export const REGISTER_USER_ACTIVITY = 'APPLICATION/REGISTER_USER_ACTIVITY';
export const REGISTER_USER_FOCUS_LOSS = 'APPLICATION/REGISTER_USER_FOCUS_LOSS';

export interface ContactMessagePayload {
    name: string
    email: string
    subject: string
    phone?: string
    invoiceNumber?: string
    message: string
    acceptPrivacy: boolean
}

export interface EnableNewsletterInactivityPayload {
    cookie?: string;
    cookieRegistered?: string;
    setCookie: (name: string, value: Cookie, options?: CookieSetOptions) => void;
}

export const enableNewsletterInactivity = createAction<EnableNewsletterInactivityPayload>(ENABLE_NEWSLETTER_INACTIVITY);
export const disableNewsletterInactivity = createAction(DISABLE_NEWSLETTER_INACTIVITY);
export const registerUserActivity = createAction(REGISTER_USER_ACTIVITY);
export const registerFocusLoss = createAction(REGISTER_USER_FOCUS_LOSS);

export const LoadNewsBarEntriesActionAndSaga = createBasicSagaFetchAction<void, NewsBarEntry[], BaseErrorCode>({
    actionGroup: 'application',
    actionName: 'loadNewsBar',
    networkCall: requestBody => {
        return () => get(Route.LoadNewsBar);
    }
});

export const SearchStoresActionAndSaga = createBasicSagaFetchAction<string, Store[], BaseErrorCode>({
    actionGroup: 'application',
    actionName: 'searchStores',
    networkCall: requestBody => {
        return () => post(Route.SearchStores, {
            search: requestBody?.trim()
        });
    }
});

export const SendContactMessageActionAndSaga = createBasicSagaFetchAction<ContactMessagePayload, void, BaseErrorCode>({
    actionGroup: 'application',
    actionName: 'sendContactMessage',
    networkCall: requestBody => {
        return () => post(Route.Contact, requestBody)
    }
});

export enum SnackbarVariant {
    ERROR = 'error',
    SUCCESS = 'success',
    INFO = 'info',
    WARNING = 'warning'
}

export interface ApplicationMessage {
    text: string;
    variant: SnackbarVariant;
    key: string;
    dismissed?: boolean;
    duration?: number;
}

export interface MenuColor {
    color?: string;
    hoverColor?: string;
    logoPath?: string;

    preview?: boolean;
}


export interface ApplicationState {
    showCurtain: boolean;
    language: Language;
    showCookieNotification: boolean;
    messages: ApplicationMessage[];
    showCart: boolean;
    showMobileMenu: boolean;
    contactFetchStatus: FetchStatus;
    stores: {
        fetchStatus: FetchStatus;
        elements: Dictionary<Store>;
        searchResult: number[];
    };
    selectedColorID: number;
    selectedSizeID: number;
    enableAnalytics: boolean;
    showCookieConsent: boolean;
    showInactivityNewsletterDialog: boolean;
    productScrollTop: number;
    newsBars: {
        fetchStatus: FetchStatus;
        entries: Dictionary<NewsBarEntry>;
        visible: boolean;
        height: number;
    };
    menu: MenuColor;
}