import {all, call, delay, put, select, spawn, takeLatest} from 'redux-saga/effects';
import {
    Address,
    CartElement,
    Checkout,
    CHECKOUT,
    CheckoutPayload,
    OrderResponse,
    PaymentMethod,
    SetAddressPropertyPayload,
    VALIDATE_COUPON,
    CartCoupon, KlarnaAuthorizePayload, AUTHORIZE_KLARNA_ORDER, checkKlarnaOrderAction,
} from '../reducer/cart/types';
import {AppState} from '../types/types';
import {denormalize} from '../helper/normalizeHelper';
import {Dictionary, PayloadAction} from '@reduxjs/toolkit';
import {Language} from '../reducer/application/types';
import {post, put as putRequest, Route} from '../api/api';
import {Country, Product} from '../types/products/types';
import {
    fetchCheckout,
    fetchCheckoutError,
    fetchCheckoutSuccess,
    fetchValidateCoupon,
    fetchValidateCouponError,
    fetchValidateCouponSuccess,
    resetValidateCouponFetchStatus,
    setCheckoutCompleted, setCheckoutHasInvalidCoupons,
    setUpdatedBillingAddressProperties,
    updateBillingAddress,
    updateDeliveryAddress
} from '../reducer/cart/cart';
import {LoadUserActionAndSaga} from '../reducer/user/types';

const generateAddress = (address: Address) => {
    return {
        company: address.company?.trim(),
        firstName: address.firstName?.trim(),
        lastName: address.lastName?.trim(),
        street: address.street?.trim(),
        amendment: address.amendment?.trim(),
        zipCode: address.zipCode?.trim(),
        city: address.city?.trim(),
        countryID: address.country,
        phone: address.phone?.trim()
    }
}

function* checkout(action: PayloadAction<CheckoutPayload>) {
    // Check if any coupons are used, if so, validate if the coupon is still valid
    const coupons: string[] = yield select((state: AppState) => state.cart.coupons?.used?.map(it => it.code) || []);
    if (coupons.length) {
        const success: boolean[] = yield all(coupons.map(it => validateCoupon(it, false)));
        if (success.some(it => !it)) {
            // Some coupons did not validate, put action in the store and return because checkout is not possible
            yield put(setCheckoutHasInvalidCoupons(true));
            return;
        }
    }

    // Only continue if no login should be created or the passwords do match
    if (!action.payload.createLogin || (action.payload.password?.trim() && action.payload.password?.trim() === action.payload.passwordConfirm?.trim())) {
        const {
            elements,
            checkout,
            language,
            payPalOrderID
        }: {
            payPalOrderID?: string,
            elements: Dictionary<CartElement>,
            checkout: Checkout,
            language: Language
        } = yield select((state: AppState) => ({
            ...state.cart,
            language: state.application.language
        }))
        const checkoutElements = denormalize(elements);
        if (checkoutElements.length) {
            const products = checkoutElements.map(it => ({
                productID: it.productID,
                colorID: it.colorID,
                sizeID: it.sizeID,
                quantity: it.amount
            }));
            const orderUser = {
                gender: checkout.billingAddress.gender,
                firstName: checkout.billingAddress.firstName?.trim(),
                lastName: checkout.billingAddress.lastName?.trim(),
                email: checkout.billingAddress.email?.trim()
            };
            const billingAddress = generateAddress(checkout.billingAddress);
            const deliveryAddress = generateAddress(checkout.deliveryAddress);
            const body = {
                orderUser,
                billingAddress,
                deliveryAddress,
                paymentMethod: checkout.paymentMethod,
                products,
                language,
                payPalOrderID,
                password: action.payload.password,
                passwordConfirm: action.payload.passwordConfirm,
                createAccount: action.payload.createLogin,
                registerNewsletter: action.payload.registerNewsletter,
                coupons
            }
            try {
                yield put(fetchCheckout());
                const response: OrderResponse = yield call(post, Route.CreateOrder, body);
                yield put(fetchCheckoutSuccess(response.order));
                // Check if user was created successfully, then log the user in
                if (action.payload.createLogin && response.user) {
                    yield put(LoadUserActionAndSaga.successAction(response.user));
                }
                if (checkout.paymentMethod === PaymentMethod.PAYMENT_IN_ADVANCE || checkout.paymentMethod === PaymentMethod.PAYPAL) {
                    // Since user does not have any more steps to do, complete the checkout.
                    yield put(setCheckoutCompleted());
                }
                
                if (response.order.paymentUrl) {
                    window.location.href = response.order.paymentUrl;
                }
            } catch (e) {
                console.error(e);
                yield put(fetchCheckoutError());
            }
        }
    }
}

function* checkoutSaga() {
    yield takeLatest(CHECKOUT, checkout);
}

interface ValidateAddressResponse {
    countryCode?: string;
    street?: string;
    city?: string;
    zipCode?: string;
}

function* checkDeliveryAddressSaga() {
    yield takeLatest('cart/setDeliveryAddressProperty', function* (action: PayloadAction<SetAddressPropertyPayload<string | number>>) {
        // Wait 500ms to avoid massive server calling during user input
        yield delay(500);
        const {validate} = action.payload;
        if (validate) {
            try {
                // On country change, check if address is valid
                const {
                    deliveryAddress,
                    countries,
                    useDeliveryAddressAsBillingAddress
                }: {
                    deliveryAddress: Address;
                    countries: Country[];
                    useDeliveryAddressAsBillingAddress: boolean;
                } = yield select((state: AppState) => ({
                    deliveryAddress: state.cart.checkout.deliveryAddress,
                    countries: denormalize(state.products?.countries || {}),
                    useDeliveryAddressAsBillingAddress: state.cart.checkout.useDeliveryAddressAsBillingAddress
                }));
                const response: ValidateAddressResponse = yield call(post, Route.ValidateAddress, deliveryAddress);
                if (response.countryCode) {
                    const country = countries.find(it => it.isoCode === response.countryCode);
                    if (country) {
                        // Also update delivery address if needed
                        yield put(updateDeliveryAddress({
                            country: country.id,
                            zipCode: deliveryAddress.zipCode,
                            city: deliveryAddress.city
                        }));

                        // Also update delivery address if needed
                        if (useDeliveryAddressAsBillingAddress) {
                            yield put(updateBillingAddress({
                                country: country.id,
                                zipCode: deliveryAddress.zipCode,
                                city: deliveryAddress.city
                            }));
                        }
                    }
                }
            } catch (e) {
                console.error(e);
            }
        }
    });
}


function* checkBillingAddressSaga() {
    yield takeLatest('cart/setBillingAddressProperty', function* (action: PayloadAction<SetAddressPropertyPayload<string | number>>) {
        // Wait 500ms to avoid massive server calling during user input
        yield delay(500);
        const {validate} = action.payload;
        if (validate) {
            try {
                // On country change, check if address is valid
                const {
                    billingAddress,
                    countries,
                }: {
                    billingAddress: Address,
                    countries: Country[],
                } = yield select((state: AppState) => ({
                    billingAddress: state.cart.checkout.billingAddress,
                    countries: denormalize(state.products?.countries || {})
                }));
                const response: ValidateAddressResponse = yield call(post, Route.ValidateAddress, billingAddress);

                if (response.countryCode) {
                    const country = countries.find(it => it.isoCode === response.countryCode);
                    if (country) {
                        const updatedBillingAddressProperties: string[] = [];
                        if (country.id !== billingAddress.country) {
                            updatedBillingAddressProperties.push('country');
                        }
                        yield put(updateBillingAddress({
                            country: country.id,
                            zipCode: billingAddress.zipCode,
                            city: billingAddress.city
                        }));
                        yield put(setUpdatedBillingAddressProperties(updatedBillingAddressProperties));
                    }
                }
            } catch (e) {
                console.error(e);
            }
        }
    });
}

function* validateCouponSaga() {
    yield takeLatest(VALIDATE_COUPON, validateCouponViaAction);
}

function* validateCouponViaAction({payload}: PayloadAction<string>) {
    yield validateCoupon(payload, true)
}

function* validateCoupon(coupon: string, addToCart: boolean) {
    try {
        const {
            elements,
            checkout,
            language,
            stateProducts
        }: {
            elements: Dictionary<CartElement>,
            checkout: Checkout,
            language: Language,
            stateProducts: Dictionary<Product>
        } = yield select((state: AppState) => ({
            ...state.cart,
            language: state.application.language,
            stateProducts: state.products?.products || {}
        }))
        const checkoutElements = denormalize(elements);
        if (checkoutElements.length) {
            const products = checkoutElements.map(it => ({
                productID: it.productID,
                colorID: it.colorID,
                sizeID: it.sizeID,
                quantity: it.amount,
                price: stateProducts[it.productID]?.price
            }));
            const orderUser = {
                gender: checkout.billingAddress.gender,
                firstName: checkout.billingAddress.firstName?.trim(),
                lastName: checkout.billingAddress.lastName?.trim(),
                email: checkout.billingAddress.email?.trim()
            };
            const billingAddress = generateAddress(checkout.billingAddress);
            const deliveryAddress = generateAddress(checkout.deliveryAddress);
            const cart = {
                orderUser,
                billingAddress,
                deliveryAddress,
                paymentMethod: checkout.paymentMethod,
                products,
                language
            }
            yield put(fetchValidateCoupon());
            const response: CartCoupon = yield call(post, Route.ValidateCoupon, {code: coupon, cart});
            yield put(fetchValidateCouponSuccess({...response, addToCart}));
            yield put(resetValidateCouponFetchStatus());
            return true;
        }
    } catch (e) {
        console.error(e);
        yield put(fetchValidateCouponError({coupon: coupon, message: (e as Error).message}));
    }
    return false;
}

function* checkKlarnaOrder({payload}: PayloadAction<KlarnaAuthorizePayload>) {
    if (payload?.authorization_token?.trim()) {
        try {
            yield put(checkKlarnaOrderAction.startAction());
            yield call(putRequest, Route.AuthorizeKlarna, payload);
            yield put(checkKlarnaOrderAction.successAction());
            yield put(setCheckoutCompleted());
        } catch (e) {
            console.error(e);
            yield put(checkKlarnaOrderAction.errorAction(0));
        }
    }
}

function* checkKlarnaOrderSaga() {
    yield takeLatest(AUTHORIZE_KLARNA_ORDER, checkKlarnaOrder);
}


export default function* cartSaga() {
    yield spawn(checkoutSaga);
    yield spawn(checkKlarnaOrderSaga);
    yield spawn(checkBillingAddressSaga);
    yield spawn(checkDeliveryAddressSaga);
    yield spawn(validateCouponSaga);
}