import React from 'react';
import { createRoot } from 'react-dom/client';
import {Provider} from 'react-redux';
import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';
import Skeleton from './components/skeleton/Skeleton';
import './index.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import LocalizationProvider from './localization/localization';
import store from './reducer/reducerManager';

const container = document.getElementById('root');
const root = createRoot(container!);

const queryClient = new QueryClient();

root.render(<QueryClientProvider client={queryClient}>
    <Provider store={store}>
        <PersistGate persistor={persistStore(store, null)}>
            <LocalizationProvider>
                <Skeleton/>
            </LocalizationProvider>
        </PersistGate>
    </Provider>
</QueryClientProvider>);