import {Dictionary} from '@reduxjs/toolkit';

export const notUndefined = <T>(x: T | undefined): x is T => {
    return x !== undefined;
}

export function normalize<T>(array: Array<T>, injected: Object = {}, normalizeKey: string = 'id'): Dictionary<T> {
    const normalized: Dictionary<T> = {};
    array.forEach((element: T) => normalized[(element as any)[normalizeKey]] = {...element, ...injected});
    return normalized;
}

export function denormalize<T>(dictionary: Dictionary<T>): Array<T> {
    return dictionary ? Object.keys(dictionary).map(key => dictionary[key]).filter(notUndefined) : [];
}