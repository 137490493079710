
export const Routes = {
    Products: {
        Overview: (type: number | string) => `/products/${type.toString().toLowerCase()}`,
        Detail: (type: string, id: number | string, name: string, colorID?: number | string, color?: string) => `/products/${type.toLowerCase()}/${id}/${name.replace(/ /g, '-')}/${colorID ? `${colorID}/${color ? color.replace(/ /g, '-') : ''}` : ''}`
    },
    FindYourStyle: {
        Home: (path: string = '') => `/styleFinder${path}`,
        SlimPetite: '/slimAndPetite',
        SlimBusty: '/slimAndBusty',
        CurvyModest: '/curvyModest',
        CurvyBusty: '/curvyAndBusty',
    },
    FindYourStyleOld: {
        Home: (path: string = '') => `/findYourStyle${path}`,
        SlimPetite: '/slimAndPetite',
        SlimBusty: '/slimAndBusty',
        CurvyModest: '/curvyModest',
        CurvyBusty: '/curvyAndBusty',
    },
    ColorYourDay: (color?: string) => `/colourWorld${color ? `/${color.replace(/ /g, '-')}` : ''}`,
    ColorYourDayOld: (color?: string) => `/colourYourDay${color ? `/${color.replace(/ /g, '-')}` : ''}`,
    PerfectYourSize: {
        Home: (path: string) => `/fitting${path}`,
        MeasureYourSize: '/measureYourSize',
        VisitFittingRoom: '/fitAdvise',
        GuidingTable: '/guidingTable',
        FitOptimization: '/fitOptimization',
    },
    Home: '/',
    Legal: {
        LegalNotice: '/legalNotice',
        Disclaimer: '/disclaimer',
        PrivacyPolicy: '/privacyPolicy',
        TermsConditions: '/termsConditions'
    },
    Blog: {
        Base: (path: string = '') => `/blog${path}`,
        Overview: (path: string = '') => `/categories${path}`,
        Category: (id: number | string, name: string) => `/${id}/${encodeURI(name.replaceAll(' ', '-'))}`,
        Post: (id: number | string, name: string = '') => `/posts/${id}/${encodeURI(name.replaceAll(' ', '-'))}`,
    },
    Admin: {
        Base: (path: string = '') => `/admin${path}`,
        Products: '/products',
        Product: (id: number | string) => `/products/${id}`,
        Colors: '/colors',
        FindYourStyle: '/styleFinder',
        History: '/history',
        Stock: '/stock',
        Password: '/password',
        Orders: '/orders',
        Changelog: '/changelog',
        Coupons: '/coupons',
        NewsBar: '/newsBar',
        Translation: '/translation',
        Language: (id: number | string) => `/language/${id}`,
        Blog: {
            Base: (path: string = '') => `/blog${path}`,
            Categories: '/categories',
            Post: (categoryID: string | number, postID: string | number) => `/posts/${categoryID}/${postID}`
        },
        ActiveHomePage: '/activeHomePage'
    },
    User: {
        Login: '/login',
        Overview: '/user',
        Register: '/register',
        UpdatePassword: '/updatePassword',
        Orders: '/orders'
    },
    Checkout: {
        Index: (id?: string) => `/checkout${id ? `/${id}` : ''}`,
        Success: '/checkout/success'
    },
    Contact: '/contact',
    Story: '/story',
    Sustainability: '/sustainability',
    BrandWorld: '/brandWorld',
    RoyalKingdom: '/royalKingdom',
    FAQ: '/faq',
    Payments: '/payments',
    Returns: '/returns',
    Washing: '/washing',
    Newsletter: {
        Subscribe: (param?: string) => `/newsletter/subscribe/:language/:token/:email/:coupon/${param}`,
        Unsubscribe: '/newsletter/unsubscribe'
    }
}