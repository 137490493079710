import React from 'react';
import {ButtonBaseProps} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import {LoadingButton} from '@mui/lab';
import {Check, Close} from '@mui/icons-material';
import {alpha} from '@mui/material/styles';

export enum ButtonState {
    Default = 'Default',
    Loading = 'loading',
    Disabled = 'disabled',
    Success = 'success',
    Error = 'error'
}

interface ButtonProps extends ButtonBaseProps {
    color?: 'primary' | 'charcoal' | 'white';
    variant?: 'outlined' | 'contained';
    state?: ButtonState;
}

const useStyles = makeStyles<unknown, 'icon'>()((theme, _, classes) => ({
    root: {
        textTransform: 'uppercase',
        minWidth: 320,
        border: 'none',
        [theme.breakpoints.down('sm')]: {
            minWidth: 186
        },
        '&>.MuiLoadingButton-loadingIndicator>.MuiCircularProgress-root': {
            width: '32px !important',
            height: '32px !important'
        }
    },
    primary: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFFFFF',
        ':hover': {
            backgroundColor: '#FF9D64'
        },
        ':active': {
            border: '1px solid white',
            boxShadow: '0 2px 4px 0 rgba(68,68,68,0.2)'
        },
        '&>.MuiLoadingButton-loadingIndicator': {
            color: 'white'
        },
        [`& .${classes.icon}`]: {
            color: 'white'
        },
        '&.Mui-disabled': {
            backgroundColor: '#FED6BF',
            color: alpha('#EC732C', 0.5)
        },
        '&.MuiButton-outlined': {
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.main,
            ':hover': {
                backgroundColor: 'rgba(236,115,44,0.2)'
            },
            '&>.MuiLoadingButton-loadingIndicator': {
                color: theme.palette.primary.main
            },
            [`& .${classes.icon}`]: {
                color: theme.palette.primary.main
            },
            '&.Mui-disabled': {
                borderColor: '#FED6BF',
                color: alpha(theme.palette.primary.main, 0.5)
            }
        }
    },
    charcoal: {
        backgroundColor: theme.palette.secondary.main,
        color: '#FFFFFF',
        ':hover': {
            backgroundColor: '#666666'
        },
        ':active': {
            boxShadow: 'inset 0 0 5px 0 #2A2A2A'
        },
        '&>.MuiLoadingButton-loadingIndicator': {
            color: 'white'
        },
        [`& .${classes.icon}`]: {
            color: 'white'
        },
        '&.Mui-disabled': {
            backgroundColor: '#CBCCCD',
            color: '#FFFFFF'
        },
        '&.MuiButton-outlined': {
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.secondary.main}`,
            color: theme.palette.secondary.main,
            ':hover': {
                backgroundColor: 'rgba(68,68,68,0.1)'
            },
            ':active': {
                boxShadow: 'inset 0 0 5px 0 #979797'
            },
            '&>.MuiLoadingButton-loadingIndicator': {
                color: theme.palette.secondary.main
            },
            [`& .${classes.icon}`]: {
                color: theme.palette.secondary.main
            },
            '&.Mui-disabled': {
                borderColor: '#CBCCCD',
                color: alpha(theme.palette.secondary.main, 0.5)
            }
        }
    },
    transparentContent: {
        color: 'transparent !important'
    },
    white: {
        backgroundColor: '#FFFFFF',
        color: theme.palette.secondary.main,
        ':hover': {
            backgroundColor: '#EBEBEB'
        },
        ':active': {
            boxShadow: `inset 0 0 5px 0 ${theme.palette.secondary.main}`
        },
        '&>.MuiLoadingButton-loadingIndicator': {
            color: theme.palette.secondary.main
        },
        [`& .${classes.icon}`]: {
            color: theme.palette.secondary.main
        },
        '&.Mui-disabled': {
            backgroundColor: '#CBCCCD',
            color: '#FFFFFF'
        },
        '&.MuiButton-outlined': {
            backgroundColor: 'transparent',
            border: `1px solid #FFFFFF`,
            color: '#FFFFFF',
            ':hover': {
                backgroundColor: 'rgba(255,255,255,0.2)'
            },
            ':active': {
                boxShadow: 'inset 0 0 5px 0 rgba(46,46,46,0.5)'
            },
            '&>.MuiLoadingButton-loadingIndicator': {
                color: '#FFFFFF'
            },
            [`& .${classes.icon}`]: {
                color: '#FFFFFF'
            },
            '&.Mui-disabled': {
                borderColor: '#666666',
                color: alpha('#FFFFFF', 0.5)
            }
        }
    },
    icon: {
        width: 40,
        height: 'auto',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    }
}));

const StatesWithoutChildren = [ButtonState.Loading, ButtonState.Error, ButtonState.Success];

const Button = React.forwardRef<HTMLButtonElement, React.PropsWithChildren<ButtonProps>>(({
                                                                    children,
                                                                    color = 'white',
                                                                    variant = 'contained',
                                                                    state = ButtonState.Default,
                                                                    classes = {},
                                                                    ...rest
                                                                }, ref) => {
    const {classes: styles, cx} = useStyles({});

    return (
        <LoadingButton {...rest} variant={variant} loading={state === ButtonState.Loading}
                       ref={ref}
                       disabled={rest.disabled || state === ButtonState.Disabled}
                       classes={{
                           ...classes,
                           root: cx(styles.root, classes.root, {
                               [styles.primary]: color === 'primary',
                               [styles.charcoal]: color === 'charcoal',
                               [styles.white]: color === 'white',
                               [styles.transparentContent]: StatesWithoutChildren.includes(state)
                           })
                       }}>
            {children}
            {state === ButtonState.Success && <Check className={styles.icon}/>}
            {state === ButtonState.Error && <Close className={styles.icon}/>}
        </LoadingButton>
    );
})

export default Button;