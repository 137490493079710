
import { useQuery } from '@tanstack/react-query';
import { get } from '../api/api';


export interface ActiveLanguageEntry {
    id: number;
    name: string;
    language: string;
    isActive: boolean;
    isStandard: boolean;
}

export const getActiveLanguages = async (isAdmin: boolean): Promise<ActiveLanguageEntry[]> => {
    try {
        const response = await get(`/api/v1/translation/${isAdmin ? 'authActiveLanguages' : 'activeLanguages'}`);
    
        return response as ActiveLanguageEntry[];
    } catch (error) {
        throw error;
    }
};

export const useActiveLanguages = (
    isAdmin: boolean,
    options?: {
        enabled?: boolean;
    },
) => {
    return useQuery(
        ['active-languages', isAdmin],
        () => getActiveLanguages(isAdmin),
        options,
    );
};