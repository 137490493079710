import React, {PropsWithChildren, ReactNode, useState} from 'react';
import {Collapse, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import mobileMenuPlusIcon from '../../../../images/icons/mobileMenuPlusIcon.svg';
import mobileMenuMinusIcon from '../../../../images/icons/mobileMenuMinusIcon.svg';


interface MobileMenuCollapseProps {
    title: string;
    icon: ReactNode;
}

const useStyles = makeStyles()(theme => ({
    root: {
        padding: theme.spacing(1, 0),
        borderBottom: 'solid 1px #979797'
    },
    button: {
        ...theme.link,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#FCFBF8',
        padding: theme.spacing(1, 0),
        border: 0,
        width: '100%',
        cursor: 'pointer'
    },
    label: {
        flex: 1,
        textAlign: 'left',
        marginLeft: '10px'
    },
    collapse: {
        paddingLeft: 34
    }
}))

const MobileMenuCollapse: React.FC<PropsWithChildren<MobileMenuCollapseProps>> = ({title, icon, children}) => {

    const {classes} = useStyles();
    const [open, setOpen] = useState(false);

    return (
        <div className={classes.root}>
            <button className={classes.button} onClick={() => {
                setOpen(!open);
            }}>
                {icon}
                <Typography variant={'body1'}
                            className={classes.label}>{title}</Typography>
                {!open ? (<img src={mobileMenuPlusIcon} alt={''} title={''}/>) : (<img src={mobileMenuMinusIcon} alt={''} title={''}/>)}
            </button>
            <Collapse in={open} className={classes.collapse}>
                {children}
            </Collapse>
        </div>
    )
}

export default MobileMenuCollapse;