import {
    GB,
    DE,
    BR,
    PT,
    NL,
    ES,
    BG,
    CZ,
    DK,
    GR,
    ET,
    FI,
    FR,
    HU,
    ID,
    IT,
    JP,
    KR,
    LT,
    LV,
    NO,
    PL,
    RO,
    RU,
    SK,
    SL,
    SV,
    TR,
    UA,
    CN,
} from "country-flag-icons/react/3x2";

export const supportedLanguages = [
    {
        language: "BG",
        name: "Bulgarian",
        supports_formality: false,
        icon: BG,
    },
    {
        language: "CS",
        name: "Czech",
        supports_formality: false,
        icon: CZ,
    },
    {
        language: "DA",
        name: "Danish",
        supports_formality: false,
        icon: DK,
    },
    {
        language: "DE",
        name: "German",
        supports_formality: true,
        icon: DE,
    },
    {
        language: "EL",
        name: "Greek",
        supports_formality: false,
        icon: GR,
    },

    {
        language: "EN",
        name: "English",
        supports_formality: false,
        icon: GB,
    },
    // {
    //   "language": "EN-GB",
    //   "name": "English (British)",
    //   "supports_formality": false
    // },
    // {
    //   "language": "EN-US",
    //   "name": "English (American)",
    //   "supports_formality": false
    // },
    {
        language: "ES",
        name: "Spanish",
        supports_formality: true,
        icon: ES,
    },
    {
        language: "ET",
        name: "Estonian",
        supports_formality: false,
        icon: ET,
    },
    {
        language: "FI",
        name: "Finnish",
        supports_formality: false,
        icon: FI,
    },
    {
        language: "FR",
        name: "French",
        supports_formality: true,
        icon: FR,
    },
    {
        language: "HU",
        name: "Hungarian",
        supports_formality: false,
        icon: HU,
    },
    {
        language: "ID",
        name: "Indonesian",
        supports_formality: false,
        icon: ID,
    },
    {
        language: "IT",
        name: "Italian",
        supports_formality: true,
        icon: IT,
    },
    {
        language: "JA",
        name: "Japanese",
        supports_formality: false,
        icon: JP,
    },
    {
        language: "KO",
        name: "Korean",
        supports_formality: false,
        icon: KR,
    },
    {
        language: "LT",
        name: "Lithuanian",
        supports_formality: false,
        icon: LT,
    },
    {
        language: "LV",
        name: "Latvian",
        supports_formality: false,
        icon: LV,
    },
    {
        language: "NB",
        name: "Norwegian (Bokmål)",
        supports_formality: false,
        icon: NO,
    },
    {
        language: "NL",
        name: "Dutch",
        supports_formality: true,
        icon: NL,
    },
    {
        language: "PL",
        name: "Polish",
        supports_formality: true,
        icon: PL,
    },
    {
        language: "PT-BR",
        name: "Portuguese (Brazilian)",
        supports_formality: true,
        icon: BR,
    },
    {
        language: "PT-PT",
        name: "Portuguese (European)",
        supports_formality: true,
        icon: PT,
    },
    {
        language: "RO",
        name: "Romanian",
        supports_formality: false,
        icon: RO,
    },
    {
        language: "RU",
        name: "Russian",
        supports_formality: true,
        icon: RU,
    },
    {
        language: "SK",
        name: "Slovak",
        supports_formality: false,
        icon: SK,
    },
    {
        language: "SL",
        name: "Slovenian",
        supports_formality: false,
        icon: SL,
    },
    {
        language: "SV",
        name: "Swedish",
        supports_formality: false,
        icon: SV,
    },
    {
        language: "TR",
        name: "Turkish",
        supports_formality: false,
        icon: TR,
    },
    {
        language: "UK",
        name: "Ukrainian",
        supports_formality: false,
        icon: UA,
    },
    {
        language: "ZH",
        name: "Chinese (simplified)",
        supports_formality: false,
        icon: CN,
    },
];
