import React, {useState} from 'react';
import FindYourStyleSubNavElement from './FindYourStyleSubNavElement';
import {Routes} from '../../../../../routes/routes';
import {
    curvyBustyImageSet,
    curvyModestImageSet,
    slimBustyImageSet,
    smallPetiteImageSet
} from '../../../../sites/findYourStyle/findYourStyleImages';
import {useLocalization} from '../../../../../localization/localization';
import {makeStyles} from 'tss-react/mui';
import {Fade, Grid, Tooltip} from '@mui/material';

const useStyles = makeStyles()((theme) => ({
    root: {
        padding: theme.spacing(4),
        background: 'white',
        width: 800,
        boxShadow: '0 8px 64px 0 rgba(0,0,0,0.08)'
    },
    subNav: {
        width: 'auto',
        background: 'transparent',
        marginTop: theme.spacing(2)
    },
    tooltip: {
        background: 'transparent',
        width: 'auto',
        maxWidth: '100%',
        margin: 0,
        padding: 0,
        marginLeft: theme.spacing(-5)
    }
}));

const FindYourStyleSubNav: React.FC<React.PropsWithChildren> = ({children}) => {

    const {classes} = useStyles();
    const [openSubNav, setOpenSubNav] = useState(false);
    const messages = useLocalization();

    const subNavContent = (
        <div className={classes.root}>
            <Grid container={true} spacing={3}>
                <Grid item={true} xs={3}>
                    <FindYourStyleSubNavElement link={Routes.FindYourStyle.Home(Routes.FindYourStyle.SlimPetite)}
                                                image={smallPetiteImageSet}
                                                title={messages.findYourStyle.smallPetite.title}
                                                close={() => setOpenSubNav(false)}/>
                </Grid>
                <Grid item={true} xs={3}>
                    <FindYourStyleSubNavElement link={Routes.FindYourStyle.Home(Routes.FindYourStyle.SlimBusty)}
                                                image={slimBustyImageSet}
                                                title={messages.findYourStyle.slimBusty.title}
                                                close={() => setOpenSubNav(false)}/>
                </Grid>
                <Grid item={true} xs={3}>
                    <FindYourStyleSubNavElement link={Routes.FindYourStyle.Home(Routes.FindYourStyle.CurvyModest)}
                                                image={curvyModestImageSet}
                                                title={messages.findYourStyle.curvyModest.title}
                                                close={() => setOpenSubNav(false)}/>
                </Grid>
                <Grid item={true} xs={3}>
                    <FindYourStyleSubNavElement link={Routes.FindYourStyle.Home(Routes.FindYourStyle.CurvyBusty)}
                                                image={curvyBustyImageSet}
                                                title={messages.findYourStyle.curvyBusty.title}
                                                close={() => setOpenSubNav(false)}/>
                </Grid>
            </Grid>
        </div>
    )

    return (
        <Tooltip open={openSubNav} title={subNavContent} placement={'bottom-start'} classes={{
            tooltip: classes.tooltip,
            popper: classes.subNav
        }} TransitionComponent={Fade} onClose={() => {
            setOpenSubNav(false)
        }} onOpen={() => {
            setOpenSubNav(true);
        }}>
            <div>
                {children}
            </div>
        </Tooltip>
    );
}

export default FindYourStyleSubNav;