import React, {CSSProperties, useEffect, useMemo, useState} from 'react';
import {ImageSet} from '../../types/types';
import {makeStyles} from 'tss-react/mui';
import {useBreakpoint} from './useBreakpoint';
import {ImageFormat} from '../../types/products/types';
import {isVideo} from './Image';
import CoverVideo from './CoverVideo';

export interface CoverImageProps {
    imageSet: ImageSet;
    className?: string;
    alt: string;
    title: string;
    equalWidthAndHeight?: boolean;
    placement?: 'top' | 'bottom' | 'center';
    heightRatio?: number;
    visible?: boolean;
    useLoader?: boolean;
}

const useStyles = makeStyles()((theme) => ({
    root: {
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        marginBottom: theme.spacing(1),
        borderRadius: 0
    },
    image: {
        position: 'absolute',
        top: 0,
        left: '50%',
        height: '100%',
        transform: 'translate(-50%, 0)'
    },
    loaderSurround: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        transition: theme.defaultTransition('width', 'left'),
        background: 'white'
    },
    loader: {
        width: '100%',
        height: '100%'
    },
    loaded: {
        width: 0,
        left: '100%'
    }
}));

const convertImagesToImageSet = (webP1x?: string, webP2x?: string, fallback1x?: string, fallback2x?: string) => {

    let src = 'image-set(';
    if (webP1x?.trim()) {
        src = `${src} url("${webP1x}") 1x,`
    }
    if (webP2x?.trim()) {
        src = `${src} url("${webP2x}") 2x,`
    }
    if (fallback1x?.trim()) {
        src = `${src} url("${fallback1x}") 1x,`
    }
    if (fallback2x?.trim()) {
        src = `${src} url("${fallback2x}") 2x`
    }
    if (src.endsWith(',')) {
        src = src.substring(0, src.length - 1);
    }
    src = `${src})`;
    return src;
}

const CoverImage: React.FC<CoverImageProps> = ({
                                                   imageSet,
                                                   className,
                                                   alt,
                                                   title,
                                                   equalWidthAndHeight,
                                                   placement,
                                                   heightRatio,
                                                   visible = true,
                                                   useLoader = false
                                               }) => {
    const {classes, cx, theme} = useStyles();

    const [imageSurroundRef, setImageSurroundRef] = useState<HTMLDivElement | null>(null);
    const [imageSurroundStyle, setImageSurroundStyle] = useState<CSSProperties>({});

    const breakpoint = useBreakpoint(theme);

    const cssImageSet = useMemo(() => {
        const webP = imageSet.get(ImageFormat.WEBP);
        const fallback = imageSet.get(ImageFormat.FALLBACK);
        // @ts-ignore
        return convertImagesToImageSet(webP?.get(breakpoint), webP?.get(`${breakpoint}2x`), fallback?.get(breakpoint), fallback?.get(`${breakpoint}2x`));
    }, [breakpoint, imageSet]);

    useEffect(() => {
        if (imageSurroundRef) {
            if (equalWidthAndHeight) {
                if (Math.round(imageSurroundRef.offsetWidth) !== Math.round(imageSurroundStyle.height as number || 0)) {
                    setImageSurroundStyle({
                        height: imageSurroundRef.offsetWidth
                    });
                }
            } else if (heightRatio) {
                if (Math.round(imageSurroundRef.offsetWidth * heightRatio) !== Math.round(imageSurroundStyle.height as number || 0)) {
                    setImageSurroundStyle({
                        height: imageSurroundRef.offsetWidth * heightRatio
                    });
                }
            }
        }
    }, [imageSurroundRef, equalWidthAndHeight, heightRatio, imageSurroundStyle.height, breakpoint]);

    if (Boolean(imageSet.size)) {

        if (isVideo(imageSet)) {
            return (<CoverVideo imageSet={imageSet} alt={alt} title={title} equalWidthAndHeight={equalWidthAndHeight}
                                className={className} visible={visible} heightRatio={heightRatio} placement={placement}
                                useLoader={useLoader}/>);
        }

        return (
            <div className={cx(classes.root, className)} ref={(ref) => {
                if (!imageSurroundRef && ref) {
                    setImageSurroundRef(ref);
                }
            }} style={{
                ...imageSurroundStyle,
                backgroundImage: cssImageSet,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}/>
        );
    }
    return null;
}

export default CoverImage;
