import React from 'react';
import {IconProps} from '../types/types';

const CloseIcon: React.FC<IconProps> = ({className, onClick}) => {
    return (
        <svg className={className} onClick={onClick} viewBox={'0 0 28 28'}
             xmlns={'http://www.w3.org/2000/svg'} xmlnsXlink={'http://www.w3.org/1999/xlink'}>
            <g stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
                <g transform={'translate(-1392, -21)'}>
                    <g transform={'translate(1048, 0)'}>
                        <g transform={'translate(344, 21)'} fill={'currentColor'}>
                            <path
                                d={`M23.7462471,3.5 L24.5,4.55181933 L14.91,14 L24.5,23.4481807 L23.7462471,24.5 
                                    L14,14.897 L4.25375291,24.5 L3.5,23.4481807 L13.089,14 L3.5,4.55181933 
                                    L4.25375291,3.5 L14,13.103 L23.7462471,3.5 Z`}
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default CloseIcon;