import {useEffect, useMemo, useState} from 'react';
import {useMediaQuery} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {ImageSet} from '../../types/types';
import {getImageSet} from '../../helper/helper';
import headerMobileWebPXS from '../../images/dest/blog/categories/header_mobile-xs@1x.webp';
import headerMobileWebPXS2x from '../../images/dest/blog/categories/header_mobile-xs@2x.webp';
import headerMobileWebPSM from '../../images/dest/blog/categories/header_mobile-sm@1x.webp';
import headerMobileWebPSM2x from '../../images/dest/blog/categories/header_mobile-sm@2x.webp';
import headerMobileWebPMD from '../../images/dest/blog/categories/header_mobile-md@1x.webp';
import headerMobileWebPMD2x from '../../images/dest/blog/categories/header_mobile-md@2x.webp';
import headerMobileWebPLG from '../../images/dest/blog/categories/header_mobile-lg@1x.webp';
import headerMobileWebPLG2x from '../../images/dest/blog/categories/header_mobile-lg@2x.webp';
import headerMobileJPGXS from '../../images/dest/blog/categories/header_mobile-xs@1x.png';
import headerMobileJPGXS2x from '../../images/dest/blog/categories/header_mobile-xs@2x.png';
import headerMobileJPGSM from '../../images/dest/blog/categories/header_mobile-sm@1x.png';
import headerMobileJPGSM2x from '../../images/dest/blog/categories/header_mobile-sm@2x.png';
import headerMobileJPGMD from '../../images/dest/blog/categories/header_mobile-md@1x.png';
import headerMobileJPGMD2x from '../../images/dest/blog/categories/header_mobile-md@2x.png';
import headerMobileJPGLG from '../../images/dest/blog/categories/header_mobile-lg@1x.png';
import headerMobileJPGLG2x from '../../images/dest/blog/categories/header_mobile-lg@2x.png';
import headerWebPXS from '../../images/dest/blog/categories/header-xs@1x.webp';
import headerWebPXS2x from '../../images/dest/blog/categories/header-xs@2x.webp';
import headerWebPSM from '../../images/dest/blog/categories/header-sm@1x.webp';
import headerWebPSM2x from '../../images/dest/blog/categories/header-sm@2x.webp';
import headerWebPMD from '../../images/dest/blog/categories/header-md@1x.webp';
import headerWebPMD2x from '../../images/dest/blog/categories/header-md@2x.webp';
import headerWebPLG from '../../images/dest/blog/categories/header-lg@1x.webp';
import headerWebPLG2x from '../../images/dest/blog/categories/header-lg@2x.webp';
import headerJPGXS from '../../images/dest/blog/categories/header-xs@1x.png';
import headerJPGXS2x from '../../images/dest/blog/categories/header-xs@2x.png';
import headerJPGSM from '../../images/dest/blog/categories/header-sm@1x.png';
import headerJPGSM2x from '../../images/dest/blog/categories/header-sm@2x.png';
import headerJPGMD from '../../images/dest/blog/categories/header-md@1x.png';
import headerJPGMD2x from '../../images/dest/blog/categories/header-md@2x.png';
import headerJPGLG from '../../images/dest/blog/categories/header-lg@1x.png';
import headerJPGLG2x from '../../images/dest/blog/categories/header-lg@2x.png';
import activeHomepageHeaderWebPXS from '../../images/dest/admin/activeHomepage-xs@1x.webp';
import activeHomepageHeaderWebPXS2x from '../../images/dest/admin/activeHomepage-xs@2x.webp';
import activeHomepageHeaderWebPSM from '../../images/dest/admin/activeHomepage-sm@1x.webp';
import activeHomepageHeaderWebPSM2x from '../../images/dest/admin/activeHomepage-sm@2x.webp';
import activeHomepageHeaderWebPMD from '../../images/dest/admin/activeHomepage-md@1x.webp';
import activeHomepageHeaderWebPMD2x from '../../images/dest/admin/activeHomepage-md@2x.webp';
import activeHomepageHeaderWebPLG from '../../images/dest/admin/activeHomepage-lg@1x.webp';
import activeHomepageHeaderWebPLG2x from '../../images/dest/admin/activeHomepage-lg@2x.webp';
import activeHomepageHeaderPNGXS from '../../images/dest/admin/activeHomepage-xs@1x.png';
import activeHomepageHeaderPNGXS2x from '../../images/dest/admin/activeHomepage-xs@2x.png';
import activeHomepageHeaderPNGSM from '../../images/dest/admin/activeHomepage-sm@1x.png';
import activeHomepageHeaderPNGSM2x from '../../images/dest/admin/activeHomepage-sm@2x.png';
import activeHomepageHeaderPNGMD from '../../images/dest/admin/activeHomepage-md@1x.png';
import activeHomepageHeaderPNGMD2x from '../../images/dest/admin/activeHomepage-md@2x.png';
import activeHomepageHeaderPNGLG from '../../images/dest/admin/activeHomepage-lg@1x.png';
import activeHomepageHeaderPNGLG2x from '../../images/dest/admin/activeHomepage-lg@2x.png';
import {useDispatch} from 'react-redux';
import {createAction} from '@reduxjs/toolkit';



export const useBlogHeaderImage = (): ImageSet => {
    const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    return useMemo<ImageSet>(() => {
        if (isMobile) {
            return getImageSet(headerMobileWebPXS, headerMobileWebPXS2x, headerMobileWebPSM, headerMobileWebPSM2x, headerMobileWebPMD,
                headerMobileWebPMD2x, headerMobileWebPLG, headerMobileWebPLG2x, headerMobileJPGXS, headerMobileJPGXS2x,
                headerMobileJPGSM, headerMobileJPGSM2x, headerMobileJPGMD, headerMobileJPGMD2x, headerMobileJPGLG,
                headerMobileJPGLG2x);
        }
        return getImageSet(headerWebPXS, headerWebPXS2x, headerWebPSM, headerWebPSM2x, headerWebPMD,
            headerWebPMD2x, headerWebPLG, headerWebPLG2x, headerJPGXS, headerJPGXS2x,
            headerJPGSM, headerJPGSM2x, headerJPGMD, headerJPGMD2x, headerJPGLG,
            headerJPGLG2x);
    }, [isMobile]);
}

export const useActiveHomepageHeaderImage = (): ImageSet => {
    return getImageSet(activeHomepageHeaderWebPXS, activeHomepageHeaderWebPXS2x, activeHomepageHeaderWebPSM, activeHomepageHeaderWebPSM2x, activeHomepageHeaderWebPMD,
        activeHomepageHeaderWebPMD2x, activeHomepageHeaderWebPLG, activeHomepageHeaderWebPLG2x, activeHomepageHeaderPNGXS, activeHomepageHeaderPNGXS2x,
        activeHomepageHeaderPNGSM, activeHomepageHeaderPNGSM2x, activeHomepageHeaderPNGMD, activeHomepageHeaderPNGMD2x, activeHomepageHeaderPNGLG,
        activeHomepageHeaderPNGLG2x);
}

export const useSagaSpawned = (generator: () => Generator<any, any, any>) => {
    const [spawned, setSpawned] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(createAction<(() => Generator<any, any, any>)[]>('application/spawnSaga')([generator]));
        setSpawned(true);
    }, [dispatch, generator]);

    return spawned;
}