import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState, ImageSet, languageSelector} from '../../../types/types';
import {getImageSet} from '../../../helper/helper';
import Image from '../../helper/Image';
import CloseIcon from '../../../icons/CloseIcon';
import {Link} from 'react-router-dom';
import {Routes} from '../../../routes/routes';
import {useLocalization} from '../../../localization/localization';

import headerWebPXS from '../../../images/dest/navigation/header-xs@1x.webp';
import headerWebPXS2x from '../../../images/dest/navigation/header-xs@2x.webp';
import headerWebPSM from '../../../images/dest/navigation/header-sm@1x.webp';
import headerWebPSM2x from '../../../images/dest/navigation/header-sm@2x.webp';
import headerWebPMD from '../../../images/dest/navigation/header-md@1x.webp';
import headerWebPMD2x from '../../../images/dest/navigation/header-md@2x.webp';
import headerWebPLG from '../../../images/dest/navigation/header-lg@1x.webp';
import headerWebPLG2x from '../../../images/dest/navigation/header-lg@2x.webp';
import headerPNGXS from '../../../images/dest/navigation/header-xs@1x.jpg';
import headerPNGXS2x from '../../../images/dest/navigation/header-xs@2x.jpg';
import headerPNGSM from '../../../images/dest/navigation/header-sm@1x.jpg';
import headerPNGSM2x from '../../../images/dest/navigation/header-sm@2x.jpg';
import headerPNGMD from '../../../images/dest/navigation/header-md@1x.jpg';
import headerPNGMD2x from '../../../images/dest/navigation/header-md@2x.jpg';
import headerPNGLG from '../../../images/dest/navigation/header-lg@1x.jpg';
import headerPNGLG2x from '../../../images/dest/navigation/header-lg@2x.jpg';


import collectionBrasWebPXS from '../../../images/dest/navigation/collection_bras-xs@1x.webp';
import collectionBrasWebPXS2x from '../../../images/dest/navigation/collection_bras-xs@2x.webp';
import collectionBrasWebPSM from '../../../images/dest/navigation/collection_bras-sm@1x.webp';
import collectionBrasWebPSM2x from '../../../images/dest/navigation/collection_bras-sm@2x.webp';
import collectionBrasWebPMD from '../../../images/dest/navigation/collection_bras-md@1x.webp';
import collectionBrasWebPMD2x from '../../../images/dest/navigation/collection_bras-md@2x.webp';
import collectionBrasWebPLG from '../../../images/dest/navigation/collection_bras-lg@1x.webp';
import collectionBrasWebPLG2x from '../../../images/dest/navigation/collection_bras-lg@2x.webp';
import collectionBrasPNGXS from '../../../images/dest/navigation/collection_bras-xs@1x.jpg';
import collectionBrasPNGXS2x from '../../../images/dest/navigation/collection_bras-xs@2x.jpg';
import collectionBrasPNGSM from '../../../images/dest/navigation/collection_bras-sm@1x.jpg';
import collectionBrasPNGSM2x from '../../../images/dest/navigation/collection_bras-sm@2x.jpg';
import collectionBrasPNGMD from '../../../images/dest/navigation/collection_bras-md@1x.jpg';
import collectionBrasPNGMD2x from '../../../images/dest/navigation/collection_bras-md@2x.jpg';
import collectionBrasPNGLG from '../../../images/dest/navigation/collection_bras-lg@1x.jpg';
import collectionBrasPNGLG2x from '../../../images/dest/navigation/collection_bras-lg@2x.jpg';

import collectionSlipsWebPXS from '../../../images/dest/navigation/collection_slips-xs@1x.webp';
import collectionSlipsWebPXS2x from '../../../images/dest/navigation/collection_slips-xs@2x.webp';
import collectionSlipsWebPSM from '../../../images/dest/navigation/collection_slips-sm@1x.webp';
import collectionSlipsWebPSM2x from '../../../images/dest/navigation/collection_slips-sm@2x.webp';
import collectionSlipsWebPMD from '../../../images/dest/navigation/collection_slips-md@1x.webp';
import collectionSlipsWebPMD2x from '../../../images/dest/navigation/collection_slips-md@2x.webp';
import collectionSlipsWebPLG from '../../../images/dest/navigation/collection_slips-lg@1x.webp';
import collectionSlipsWebPLG2x from '../../../images/dest/navigation/collection_slips-lg@2x.webp';
import collectionSlipsPNGXS from '../../../images/dest/navigation/collection_slips-xs@1x.jpg';
import collectionSlipsPNGXS2x from '../../../images/dest/navigation/collection_slips-xs@2x.jpg';
import collectionSlipsPNGSM from '../../../images/dest/navigation/collection_slips-sm@1x.jpg';
import collectionSlipsPNGSM2x from '../../../images/dest/navigation/collection_slips-sm@2x.jpg';
import collectionSlipsPNGMD from '../../../images/dest/navigation/collection_slips-md@1x.jpg';
import collectionSlipsPNGMD2x from '../../../images/dest/navigation/collection_slips-md@2x.jpg';
import collectionSlipsPNGLG from '../../../images/dest/navigation/collection_slips-lg@1x.jpg';
import collectionSlipsPNGLG2x from '../../../images/dest/navigation/collection_slips-lg@2x.jpg';

import collectionImageJPG from '../../../images/dest/navigation/categories/collection-lg@2x.jpg';
import collectionImageWebP from '../../../images/dest/navigation/categories/collection-lg@2x.webp';
import styleFinderImageWebP from '../../../images/dest/navigation/categories/style-finder-lg@2x.webp';
import styleFinderImageJPG from '../../../images/dest/navigation/categories/style-finder-lg@2x.jpg';
import colorWorldImageWebP from '../../../images/dest/navigation/categories/color-world-lg@2x.webp';
import colorWorldImageJPG from '../../../images/dest/navigation/categories/color-world-lg@2x.jpg';
import cartImageWebP from '../../../images/dest/navigation/categories/cart-lg@2x.webp';
import cartImageJPG from '../../../images/dest/navigation/categories/cart-lg@2x.jpg';
import checkoutImageWebP from '../../../images/dest/navigation/checkout-lg@2x.webp';
import checkoutImageJPG from '../../../images/dest/navigation/checkout-lg@2x.png';
import blogImageWebP from '../../../images/dest/navigation/categories/blog-lg@2x.webp';
import blogImageJPG from '../../../images/dest/navigation/categories/blog-lg@2x.jpg';

import {makeStyles} from 'tss-react/mui';
import {IconButton, SwipeableDrawer, Typography} from '@mui/material';
import {setShowCart, setShowMobileMenu} from '../../../reducer/application/application';
import MobileMenuCollapse from './mobile/MobileMenuCollapse';
import MobileLanguageSwitch from './mobile/MobileLanguageSwitch';
import MobileMenuItem from './mobile/MobileMenuItem';

import tiktokIcon from '../../../images/icons/tiktokMonoIcon.svg';
import twitterIcon from '../../../images/icons/twitterMonoIcon.svg';
import youtubeIcon from '../../../images/icons/youtubeMonoIcon.svg';
import pinterestIcon from '../../../images/icons/pinterestMonoIcon.svg';
import facebookIcon from '../../../images/icons/facebookMonoIcon.svg';
import instagramIcon from '../../../images/icons/instagramMonoIcon.svg';
import accountIcon from '../../../images/icons/mobileMenuAccountIcon.svg';
import contactIcon from '../../../images/icons/mobileMenuContactIcon.svg';
import silhouetteIcon from '../../../images/icons/silhouette.svg';
import locationIcon from '../../../images/icons/mobileMenuLocationIcon.svg';
import {denormalize} from '../../../helper/normalizeHelper';
import {LogoutActionAndSaga} from '../../../reducer/user/types';
import {productTypeSelector} from '../../../reducer/products/types';

const ProductTypeImageSets: Record<string, ImageSet | undefined> = {
    'bras': getImageSet(collectionBrasWebPXS, collectionBrasWebPXS2x, collectionBrasWebPSM, collectionBrasWebPSM2x, collectionBrasWebPMD,
        collectionBrasWebPMD2x, collectionBrasWebPLG, collectionBrasWebPLG2x, collectionBrasPNGXS, collectionBrasPNGXS2x, collectionBrasPNGSM,
        collectionBrasPNGSM2x, collectionBrasPNGMD, collectionBrasPNGMD2x, collectionBrasPNGLG, collectionBrasPNGLG2x),
    'panties': getImageSet(collectionSlipsWebPXS, collectionSlipsWebPXS2x, collectionSlipsWebPSM, collectionSlipsWebPSM2x, collectionSlipsWebPMD,
        collectionSlipsWebPMD2x, collectionSlipsWebPLG, collectionSlipsWebPLG2x, collectionSlipsPNGXS, collectionSlipsPNGXS2x, collectionSlipsPNGSM,
        collectionSlipsPNGSM2x, collectionSlipsPNGMD, collectionSlipsPNGMD2x, collectionSlipsPNGLG, collectionSlipsPNGLG2x)
}

const headerImageSet = getImageSet(headerWebPXS, headerWebPXS2x, headerWebPSM, headerWebPSM2x, headerWebPMD,
    headerWebPMD2x, headerWebPLG, headerWebPLG2x, headerPNGXS, headerPNGXS2x, headerPNGSM,
    headerPNGSM2x, headerPNGMD, headerPNGMD2x, headerPNGLG, headerPNGLG2x);

const collectionImageSet = getImageSet(collectionImageWebP, collectionImageWebP, collectionImageWebP, collectionImageWebP, collectionImageWebP,
    collectionImageWebP, collectionImageWebP, collectionImageWebP, collectionImageJPG, collectionImageJPG, collectionImageJPG,
    collectionImageJPG, collectionImageJPG, collectionImageJPG, collectionImageJPG, collectionImageJPG);

const styleFinderImageSet = getImageSet(styleFinderImageWebP, styleFinderImageWebP, styleFinderImageWebP, styleFinderImageWebP, styleFinderImageWebP,
    styleFinderImageWebP, styleFinderImageWebP, styleFinderImageWebP, styleFinderImageJPG, styleFinderImageJPG, styleFinderImageJPG,
    styleFinderImageJPG, styleFinderImageJPG, styleFinderImageJPG, styleFinderImageJPG, styleFinderImageJPG);

const colorWorldImageSet = getImageSet(colorWorldImageWebP, colorWorldImageWebP, colorWorldImageWebP, colorWorldImageWebP, colorWorldImageWebP,
    colorWorldImageWebP, colorWorldImageWebP, colorWorldImageWebP, colorWorldImageJPG, colorWorldImageJPG, colorWorldImageJPG,
    colorWorldImageJPG, colorWorldImageJPG, colorWorldImageJPG, colorWorldImageJPG, colorWorldImageJPG);

const blogImageSet = getImageSet(blogImageWebP, blogImageWebP, blogImageWebP, blogImageWebP, blogImageWebP,
    blogImageWebP, blogImageWebP, blogImageWebP, blogImageJPG, blogImageJPG, blogImageJPG,
    blogImageJPG, blogImageJPG, blogImageJPG, blogImageJPG, blogImageJPG);

const cartImageSet = getImageSet(cartImageWebP, cartImageWebP, cartImageWebP, cartImageWebP, cartImageWebP,
    cartImageWebP, cartImageWebP, cartImageWebP, cartImageJPG, cartImageJPG, cartImageJPG,
    cartImageJPG, cartImageJPG, cartImageJPG, cartImageJPG, cartImageJPG);

const checkoutImageSet = getImageSet(checkoutImageWebP, checkoutImageWebP, checkoutImageWebP, checkoutImageWebP, checkoutImageWebP,
    checkoutImageWebP, checkoutImageWebP, checkoutImageWebP, checkoutImageJPG, checkoutImageJPG, checkoutImageJPG,
    checkoutImageJPG, checkoutImageJPG, checkoutImageJPG, checkoutImageJPG, checkoutImageJPG);

const useStyles = makeStyles()((theme) => ({
    root: {
        boxSizing: 'border-box',
        width: '90%',
        backgroundColor: '#FCFBF8'
    },
    menu: {
        padding: theme.spacing(2),
        backgroundColor: '#F8F6F3'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2)
    },
    closeIcon: {
        color: theme.palette.secondary.main,
        width: 24,
        height: 24
    },
    headerImage: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    menuImage: {
        width: '56px',
        height: '56px'
    },
    footer: {
        backgroundColor: '#FCFBF8',
        padding: theme.spacing(2)
    },
    badge: {
        backgroundColor: '#444',
        color: '#FFF',
        fontSize: '12px',
        fontWeight: 500,
        borderRadius: '50%',
        lineHeight: '22px',
        width: '22px',
        textAlign: 'center',
        marginLeft: theme.spacing(1),
    },
    footerOptionContainer: {
        padding: theme.spacing(1, 0),
        borderBottom: 'solid 1px #979797'
    },
    footerOption: {
        ...theme.link,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#FCFBF8',
        padding: theme.spacing(1, 0),
        border: 0,
        width: '100%',
        cursor: 'pointer'
    },
    footerOptionLabel: {
        flex: 1,
        textAlign: 'left',
        marginLeft: '10px'
    },
    footerOptionItemContainer: {
        display: 'block',
        textDecoration: 'none',
        padding: theme.spacing(1, 0)
    },
    footerLink: {
        ...theme.link,
        display: 'block',
        background: 'none',
        width: '100%',
        textAlign: 'left',
        border: 'none',
        padding: theme.spacing(1, 0),
        cursor: 'pointer'
    },
    footerSocialContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
        margin: theme.spacing(1, 0, 16)
    },
    cartValue: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: 15
    },
    cartDot: {
        color: '#979797',
        fontWeight: 700
    }
}));


const MobileMenu: React.FC = () => {
    const {classes, theme} = useStyles();
    const open = useSelector((state: AppState) => state.application.showMobileMenu);
    const loggedIn = useSelector((state: AppState) => state.user.loggedIn);
    const admin = useSelector((state: AppState) => state.user.admin);
    const name = useSelector((state: AppState) => state.user.firstName);
    const productTypes = useSelector(productTypeSelector);
    const language = useSelector(languageSelector);

    const [isOpen, setIsOpen] = useState(open);

    const wasOpen = useRef(open);

    const products = useSelector((state: AppState) => state.products?.products || {});
    const cartElements = useSelector((state: AppState) => state.cart.elements);

    const elements = useMemo(() => denormalize(cartElements), [cartElements]);

    const dispatch = useDispatch();
    const messages = useLocalization();

    const productsInCart = useMemo(() => denormalize(cartElements).length, [cartElements]);

    useEffect(() => {
        if (!wasOpen.current && open) {
            window.setTimeout(() => {
                setIsOpen(true);
            }, 100);
        } else {
            setIsOpen(open);
        }
        if (open) {
            wasOpen.current = true;
        }
    }, [open]);

    if (open || wasOpen.current) {

        return (
            <SwipeableDrawer open={isOpen}
                             classes={{
                                 paper: classes.root
                             }} onOpen={() => dispatch(setShowMobileMenu(true))}
                             onClose={() => dispatch(setShowMobileMenu(false))}>
                <div className={classes.menu}>
                    <div className={classes.header}>
                        <Typography variant={'subtitle1'}
                                    style={{lineHeight: '40px'}}>{messages.menu.title}</Typography>
                        <IconButton style={{marginRight: theme.spacing(-1)}}
                                    onClick={() => dispatch(setShowMobileMenu(false))}>
                            <CloseIcon className={classes.closeIcon}/>
                        </IconButton>
                    </div>

                    <Image imageSet={headerImageSet} alt={''} title={''} imageClass={classes.headerImage}/>

                    <MobileMenuItem title={messages.menu.collection} link={Routes.Products.Overview('')}
                                    imageSet={collectionImageSet}/>
                    {productTypes.map((it) => (
                        <MobileMenuItem
                            title={it.translations.find(localization => localization.key === 'name' && localization.language === language)?.value || it.name}
                            link={Routes.Products.Overview(it.name)}
                            imageSet={ProductTypeImageSets[it.name.toLowerCase()] || collectionImageSet}/>
                    ))}
                    <MobileMenuItem title={messages.menu.findYourStyle} link={Routes.FindYourStyle.Home()}
                                    imageSet={styleFinderImageSet}/>
                    <MobileMenuItem title={messages.menu.colorYourDay} link={Routes.ColorYourDay()}
                                    imageSet={colorWorldImageSet}/>
                    <MobileMenuItem title={messages.menu.blog} link={Routes.Blog.Base('')}
                                    imageSet={blogImageSet}/>
                    <MobileMenuItem title={messages.menu.cart} link={''} imageSet={cartImageSet}
                                    appendix={productsInCart > 0 &&
                                        <span className={classes.badge}>{productsInCart}</span>}
                                    onClick={() => {
                                        dispatch(setShowCart(true));
                                    }}/>
                    {productsInCart > 0 && (
                        <MobileMenuItem title={(
                            <span>{messages.cart.checkoutMobile} <span className={classes.cartDot}>·&nbsp;</span>
                            <span className={classes.cartValue}>
                                {elements.reduce<number>((prevValue, current) => {
                                    const product = products[current.productID];
                                    let newValue = prevValue;
                                    if (product) {
                                        newValue += product.price * current.amount;
                                    }
                                    return newValue;
                                }, 0).toLocaleString()}&nbsp;€
                            </span>
                        </span>)} link={Routes.Checkout.Index('')} imageSet={checkoutImageSet}/>
                    )}
                </div>

                <div className={classes.footer}>
                    <MobileMenuCollapse title={loggedIn ? name : messages.menu.profileShort}
                                        icon={<img src={accountIcon} alt={''} title={''}/>}>
                        {!loggedIn ? (
                            <>
                                <Link
                                    className={classes.footerLink}
                                    onClick={() => dispatch(setShowMobileMenu(false))}
                                    to={Routes.User.Login}>
                                    <Typography variant={'subtitle1'}>{messages.menu.login}</Typography>
                                </Link>
                                <Link
                                    className={classes.footerLink}
                                    onClick={() => dispatch(setShowMobileMenu(false))}
                                    to={Routes.User.Register}>
                                    <Typography variant={'subtitle1'}>{messages.menu.register}</Typography>
                                </Link>
                            </>) : (
                            <>
                                <Link
                                    className={classes.footerLink}
                                    onClick={() => dispatch(setShowMobileMenu(false))}
                                    to={Routes.User.Orders}>
                                    <Typography variant={'subtitle1'}>{messages.menu.orders}</Typography>
                                </Link>
                                <Link
                                    className={classes.footerLink}
                                    onClick={() => dispatch(setShowMobileMenu(false))}
                                    to={Routes.User.UpdatePassword}>
                                    <Typography variant={'subtitle1'}>{messages.menu.updatePassword}</Typography>
                                </Link>
                                <button
                                    className={classes.footerLink}
                                    onClick={() => {
                                        dispatch(setShowMobileMenu(false));
                                        dispatch(LogoutActionAndSaga.action());
                                    }}>
                                    <Typography variant={'subtitle1'}>{messages.menu.logout}</Typography>
                                </button>
                            </>)
                        }
                    </MobileMenuCollapse>
                    {admin && (
                        <MobileMenuCollapse title={messages.menu.admin}
                                            icon={<img src={accountIcon} alt={''} title={''}/>}>
                            <Link className={classes.footerLink}
                                  onClick={() => dispatch(setShowMobileMenu(false))}
                                  to={Routes.Admin.Base(Routes.Admin.ActiveHomePage)}>
                                <Typography
                                    variant={'subtitle1'}>{messages.menu.adminItems.activeHomePage}</Typography>
                            </Link>
                            <Link
                                className={classes.footerLink}
                                onClick={() => dispatch(setShowMobileMenu(false))}
                                to={Routes.Admin.Base(Routes.Admin.Orders)}>
                                <Typography variant={'subtitle1'}>{messages.menu.adminItems.orders}</Typography>
                            </Link>
                            <Link
                                className={classes.footerLink}
                                onClick={() => dispatch(setShowMobileMenu(false))}
                                to={Routes.Admin.Base(Routes.Admin.Products)}>
                                <Typography variant={'subtitle1'}>{messages.menu.adminItems.products}</Typography>
                            </Link>
                            <Link
                                className={classes.footerLink}
                                onClick={() => dispatch(setShowMobileMenu(false))}
                                to={Routes.Admin.Base(Routes.Admin.Colors)}>
                                <Typography variant={'subtitle1'}>{messages.menu.adminItems.colors}</Typography>
                            </Link>
                            <Link
                                className={classes.footerLink}
                                onClick={() => dispatch(setShowMobileMenu(false))}
                                to={Routes.Admin.Base(Routes.Admin.Blog.Base(''))}>
                                <Typography variant={'subtitle1'}>{messages.menu.adminItems.blog}</Typography>
                            </Link>
                            <Link
                                className={classes.footerLink}
                                onClick={() => dispatch(setShowMobileMenu(false))}
                                to={Routes.Admin.Base(Routes.Admin.FindYourStyle)}>
                                <Typography variant={'subtitle1'}>{messages.menu.adminItems.styles}</Typography>
                            </Link>
                            <Link
                                className={classes.footerLink}
                                onClick={() => dispatch(setShowMobileMenu(false))}
                                to={Routes.Admin.Base(Routes.Admin.Coupons)}>
                                <Typography variant={'subtitle1'}>{messages.menu.adminItems.coupons}</Typography>
                            </Link>
                            <Link className={classes.footerLink}
                                  onClick={() => dispatch(setShowMobileMenu(false))}
                                  to={Routes.Admin.Base(Routes.Admin.NewsBar)}>
                                <Typography variant={'subtitle1'}>{messages.menu.adminItems.newsBar}</Typography>
                            </Link>
                            <Link className={classes.footerLink}
                                  onClick={() => dispatch(setShowMobileMenu(false))}
                                  to={Routes.Admin.Base(Routes.Admin.Changelog)}>
                                <Typography variant={'subtitle1'}>{messages.menu.adminItems.changelog}</Typography>
                            </Link>
                            <button
                                className={classes.footerLink}
                                onClick={() => {
                                    dispatch(setShowMobileMenu(false));
                                    dispatch(LogoutActionAndSaga.action());
                                }}>
                                <Typography variant={'subtitle1'}>{messages.menu.logout}</Typography>
                            </button>
                        </MobileMenuCollapse>
                    )}
                    <MobileMenuCollapse title={messages.menu.personalFit.title}
                                        icon={<img src={silhouetteIcon} alt={''} title={''}/>}>
                        <Link className={classes.footerLink}
                              onClick={() => dispatch(setShowMobileMenu(false))}
                              to={Routes.FindYourStyle.Home('')}>
                            <Typography variant={'subtitle1'}>{messages.menu.findYourStyle}</Typography>
                        </Link>
                        <Link className={classes.footerLink}
                              onClick={() => dispatch(setShowMobileMenu(false))}
                              to={Routes.PerfectYourSize.Home(Routes.PerfectYourSize.MeasureYourSize)}>
                            <Typography variant={'subtitle1'}>{messages.perfectYourSize.sections.measureYourSize}</Typography>
                        </Link>
                        <Link className={classes.footerLink}
                              onClick={() => dispatch(setShowMobileMenu(false))}
                              to={Routes.PerfectYourSize.Home(Routes.PerfectYourSize.VisitFittingRoom)}>
                            <Typography variant={'subtitle1'}>{messages.perfectYourSize.sections.fittingTips}</Typography>
                        </Link>
                        <Link className={classes.footerLink}
                              onClick={() => dispatch(setShowMobileMenu(false))}
                              to={Routes.PerfectYourSize.Home(Routes.PerfectYourSize.FitOptimization)}>
                            <Typography variant={'subtitle1'}>{messages.perfectYourSize.sections.optimiseFit}</Typography>
                        </Link>
                        <Link className={classes.footerLink}
                              onClick={() => dispatch(setShowMobileMenu(false))}
                              to={Routes.PerfectYourSize.Home(Routes.PerfectYourSize.GuidingTable)}>
                            <Typography variant={'subtitle1'}>{messages.perfectYourSize.sections.guidingTable}</Typography>
                        </Link>
                    </MobileMenuCollapse>

                    <MobileMenuCollapse title={messages.menu.contact.title}
                                        icon={<img src={contactIcon} alt={''} title={''}/>}>
                        <a href={`mailto:${messages.menu.contact.mail}`} className={classes.footerOptionItemContainer}>
                            <Typography variant='body1'>{messages.menu.contact.mailQuestions}</Typography>
                            <Typography variant={'subtitle1'}>{messages.menu.contact.mail}</Typography>
                        </a>

                        <a href={`tel:${messages.menu.contact.tel}`} className={classes.footerOptionItemContainer}>
                            <Typography variant='body1'>{messages.menu.contact.telQuestions}</Typography>
                            <Typography variant={'subtitle1'}>{messages.menu.contact.tel}</Typography>
                        </a>

                        <div className={classes.footerOptionItemContainer}>
                            <Typography variant='body1'>{messages.menu.contact.openingHours}</Typography>
                        </div>
                    </MobileMenuCollapse>
                    <MobileLanguageSwitch/>

                    <div className={classes.footerOptionContainer}>
                        <Link
                            className={classes.footerOption}
                            onClick={() => dispatch(setShowMobileMenu(false))}
                            to={Routes.RoyalKingdom}>
                            <img src={locationIcon} alt={''} title={''}/>
                            <Typography variant='body1'
                                        className={classes.footerOptionLabel}>{messages.menu.storeFinder}</Typography>
                        </Link>
                    </div>

                    <div className={classes.footerSocialContainer}>
                        <a href={'https://www.facebook.com/royalloungeintimates'} target={'_blank'} rel={'noreferrer'}>
                            <img src={facebookIcon} title={''} alt={''}/>
                        </a>
                        <a href={'https://www.instagram.com/royalloungeintimates'} target={'_blank'} rel={'noreferrer'}>
                            <img src={instagramIcon} title={''} alt={''}/>
                        </a>
                        <a href={'https://twitter.com/royal_lounge_eu'} target={'_blank'} rel={'noreferrer'}>
                            <img src={twitterIcon} title={''} alt={''}/>
                        </a>
                        <a href={'https://www.youtube.com/channel/UCjQWW7xlt9VPYdxI1_Hrdqg'} target={'_blank'}
                           rel={'noreferrer'}>
                            <img src={youtubeIcon} title={''} alt={''}/>
                        </a>
                        <a href={'https://www.pinterest.de/RoyalLoungeIntimates/'} target={'_blank'} rel={'noreferrer'}>
                            <img src={pinterestIcon} title={''} alt={''}/>
                        </a>
                        <a href={'https://www.tiktok.com/@royalloungeintimates'} target={'_blank'} rel={'noreferrer'}>
                            <img src={tiktokIcon} title={''} alt={''}/>
                        </a>
                    </div>

                </div>
            </SwipeableDrawer>
        );
    }
    return null;
}

export default MobileMenu;
