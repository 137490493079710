import React from 'react';
import {makeStyles} from 'tss-react/mui';
import MobileMenuCollapse from './MobileMenuCollapse';
import {useLocalization} from '../../../../localization/localization';
import {Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {AppState, languageSelector} from '../../../../types/types';
import {Language} from '../../../../reducer/application/types';
import {setLanguage, setShowMobileMenu} from '../../../../reducer/application/application';
import { useActiveLanguages } from '../../../../hooks/useActiveLanguages';
import { supportedLanguages } from '../../../sites/admin/translation/SupportedLanguages';
import Cookies from 'js-cookie';
import languageIcon from '../../../../images/icons/languageIcon.svg';


const useStyles = makeStyles()(theme => ({
    container: {
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center'
    },
    language: {
        color: theme.palette.secondary.main,
        width: 22
    },
    icon: {
        width: 32,
        marginRight: theme.spacing(1)
    },
    margin: {
        margin: theme.spacing(1, 0, 2)
    }
}));

const MobileLanguageSwitch: React.FC = () => {

    const messages = useLocalization();
    const {classes, cx} = useStyles();
    
    const dispatch = useDispatch();
    const admin = useSelector((state: AppState) => state.user.admin);

    const selectedLanguage = useSelector(languageSelector);

    const {data} = useActiveLanguages(admin, {enabled: true});

    const callback = (language: Language) => {
        dispatch(setLanguage(language));
        Cookies.set('language', language);
        dispatch(setShowMobileMenu(false));
    }

    return (
        <MobileMenuCollapse icon={<img src={languageIcon} alt={''} title={''} className={classes.language}/>} title={messages.menu.language.select}>

            {data?.map((language) => {
                const Icon = supportedLanguages.find((supportedLanguage) => supportedLanguage.language.toLowerCase() === language.language.toLowerCase())?.icon;
                return (
                    <div className={cx(classes.container, classes.margin)} onClick={() => {
                        callback(language.language as Language);
                    }}
                        key={language.language}>
                        {Icon && <Icon className={classes.icon}/>}
                        <Typography variant={'body1'} style={
                                {
                                    ...language.isActive ? {} : {color:'red'}, 
                                    ...language.language === selectedLanguage ? {fontWeight: 'bold'} : {}
                                }
                            }>
                            {`${language.name} (${language.language.toUpperCase()})`}
                        </Typography>
                    </div>
                )
            })}
        </MobileMenuCollapse>
    )
}

export default MobileLanguageSwitch;