import {useMediaQuery} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {useSelector} from 'react-redux';
import {AppState} from '../../types/types';
import React, {useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import {Routes} from '../../routes/routes';


export const useMenuColor = () => {

    const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

    const textColor = useSelector((state: AppState) => state.application.menu.color);
    const hoverColor = useSelector((state: AppState) => state.application.menu.hoverColor);
    const logoPath = useSelector((state: AppState) => state.application.menu.logoPath);
    const menuPreview = useSelector((state: AppState) => state.application.menu.preview);

    const {pathname} = useLocation();

    const onMouseEnter = useCallback((e: React.MouseEvent<HTMLElement | SVGSVGElement, MouseEvent>) => {
        // Only respect menu color on home page
        if (pathname === Routes.Home && !isMobile && hoverColor) {
            e.currentTarget.style.color = hoverColor;
            e.currentTarget.style.fill = hoverColor;
        } else {
            e.currentTarget.style.color = '';
            e.currentTarget.style.fill = '';
        }
    }, [hoverColor, isMobile, pathname]);

    const onMouseLeave = useCallback((e: React.MouseEvent<HTMLElement | SVGSVGElement, MouseEvent>) => {
        // Only respect menu color on home page
        if (pathname === Routes.Home && !isMobile && textColor) {
            e.currentTarget.style.color = textColor;
            e.currentTarget.style.fill = textColor;
        } else {
            e.currentTarget.style.color = '';
            e.currentTarget.style.fill = '';
        }
    }, [textColor, isMobile, pathname]);

    return {onMouseEnter, onMouseLeave, textColor, hoverColor, logoPath, menuPreview};
}