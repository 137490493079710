import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../../types/types';
import {useLocation} from 'react-router-dom';
import getGA, {gtag} from '../../analytics/GoogleAnalytics';
import {Routes} from '../../routes/routes';
import {hotjar} from 'react-hotjar';
import {createAction} from '@reduxjs/toolkit';
import {getBingAnalytics} from '../../analytics/MicrosoftAnalytics';
import {useSagaSpawned} from '../helper/hooks';
import TrackingSaga from '../../sagas/TrackingSaga';
import {trackPageView} from '../../tracking/actions';


const HotJarSiteId = Number(process.env.REACT_APP_HOTJAR_SITE_ID || '0');
const HotJarSnippetVersion = Number(process.env.REACT_APP_HOTJAR_SNIPPET_VERSION || '0');

const Analytics: React.FC = () => {

    const enableAnalytics = useSelector((state: AppState) => state.application.enableAnalytics);
    const checkoutCompleted = useSelector((state: AppState) => state.cart.checkout.completed);

    const {pathname} = useLocation();
    const dispatch = useDispatch();
    const isTrackingSagaSpawned = useSagaSpawned(TrackingSaga);

    useEffect(() => {
        if (enableAnalytics && isTrackingSagaSpawned) {
            getGA();
            // @ts-ignore
            gtag('consent', 'default', {
                'ad_storage': 'granted',
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
                'analytics_storage': 'granted'
            });
        }
    }, [enableAnalytics, isTrackingSagaSpawned]);

    useEffect(() => {
        if (enableAnalytics && isTrackingSagaSpawned) {
            getGA().dataLayer({
                dataLayer: {
                    event: 'page_view',
                    page_path: window.location.pathname + window.location.search,
                    page_location: window.location.href
                }
            });
            dispatch(trackPageView({page: window.location.href}));
        }
    }, [enableAnalytics, pathname, isTrackingSagaSpawned, dispatch]);

    useEffect(() => {
        if (enableAnalytics && isTrackingSagaSpawned) {
            getGA().dataLayer({
                dataLayer: {
                    event: 'page_view',
                    page_path: window.location.pathname + window.location.search,
                    page_location: window.location.href
                }
            });
        }
    }, [enableAnalytics, pathname, isTrackingSagaSpawned]);

    useEffect(() => {
        if (enableAnalytics && isTrackingSagaSpawned) {
            getBingAnalytics();
            // @ts-ignore
            window.uetq = window.uetq || [];
            // @ts-ignore
            window.uetq.push("event", "");
        }
    }, [enableAnalytics, pathname, isTrackingSagaSpawned]);

    useEffect(() => {
        if (enableAnalytics && HotJarSiteId > 0 && HotJarSnippetVersion > 0) {
            hotjar.initialize({id: HotJarSiteId, sv: HotJarSnippetVersion});
        }
    }, [enableAnalytics])

    useEffect(() => {
        if (checkoutCompleted && !pathname.startsWith(Routes.Checkout.Index())) {
            // Reset cart if checkout was completed and user is no more on checkout site
            dispatch(createAction('cart/resetCart')());
        }
    }, [pathname, checkoutCompleted, dispatch]);

    return null;
}

export default Analytics;