import {ApplicationState} from '../reducer/application/types';
import {MouseEventHandler, SVGProps} from 'react';
import {ProductState} from '../reducer/products/types';
import {AdminState} from '../reducer/admin/types';
import {UserState} from '../reducer/user/types';
import {ImageFormat} from './products/types';
import {CartState} from '../reducer/cart/types';
import {SocialState} from '../reducer/social/types';
import {AdminBlogState} from '../reducer/admin/blog/types';
import {BlogState} from '../reducer/blog/types';
import {AdminPagesState} from '../reducer/admin/pages/types';
import {PagesState} from '../reducer/pages/types';

export type ImageSize = 'xs' | 'sm' | 'md' | 'lg' | 'xs2x' | 'sm2x' | 'md2x' | 'lg2x';

export type ImageSet = Map<ImageFormat, Map<ImageSize, string | undefined>>;

export enum UnderBustValue {
    VALUE_70 = '70',
    VALUE_75 = '75',
    VALUE_80 = '80',
    VALUE_85 = '85',
    VALUE_90 = '90',
    VALUE_95 = '95'
}

export enum OverBustValue {
    VALUE_A = 'A',
    VALUE_B = 'B',
    VALUE_C = 'C',
    VALUE_D = 'D',
    VALUE_E = 'E',
    VALUE_F = 'F'
}

export interface UnderBustMeasurementInterval {
    min: number;
    max: number;
    value: UnderBustValue;
    getOverBustValue: (value: number) => OverBustValue | undefined;
}

export enum PantiesSize {
    XS = 'XS',
    S = 'S',
    M = 'M',
    L = 'L',
    XL = 'XL',
    XXL = 'XXL'
}

/*************************************************
 * Generic Prop types
 **************************************************/

export interface IconProps extends SVGProps<SVGSVGElement> {
    className?: string;
    onClick?: MouseEventHandler;
}

export interface PaymentIconProps extends IconProps {
    opacity?: number;
}

export const languageSelector = (state: AppState) => state.application.language;

export interface AppState {
    application: ApplicationState;
    products?: ProductState;
    admin?: AdminState;
    user: UserState;
    cart: CartState;
    social?: SocialState;
    blog?: BlogState;
    pages?: PagesState;
    adminBlog?: AdminBlogState;
    adminPages?: AdminPagesState;
}